{
  "common": {
    "save": "Tallenna",
    "saving": "Tallennetaan",
    "integrate": "Integroi",
    "preview": "Esikatselu",
    "back": "Takaisin",
    "alert": "Ilmoitus",
    "proceed": "Jatka",
    "cancel": "Peruuta",
    "delete": "Poista",
    "embed": "Upota",
    "link": "Linkitä",
    "iframeEmbed": "Iframe-upotus",
    "styles": "Tyylit",
    "options": "Vaihtoehdot",
    "quickAdd": "Pikalisäys",
    "customFields": "Mukautetut kentät",
    "personalInfo": "Henkilökohtaiset tiedot",
    "contactInfo": "Yhteystiedot",
    "submit": "Lähetä",
    "address": "Osoite",
    "customized": "Mukautettu",
    "layout": "Asettelu",
    "showLabel": "Näytä tunniste",
    "background": "Tausta",
    "border": "Reunaviiva",
    "borderType": "Reunaviivan tyyppi",
    "cornerRadius": "Kulman pyöristyssäde",
    "width": "Leveys",
    "padding": "Reunatila",
    "top": "Ylä",
    "bottom": "Ala",
    "left": "Vasen",
    "right": "Oikea",
    "shadow": "Varjo",
    "color": "Väri",
    "horizontal": "Vaakasuuntainen",
    "vertical": "Pystysuuntainen",
    "blur": "Epäterävyys",
    "spread": "Hajaantuvuus",
    "fieldStyle": "Kentän tyyli",
    "fontColor": "Fontin väri",
    "label": "Tunniste",
    "fontFamily": "Fonttiperhe",
    "size": "Koko",
    "weight": "Paino",
    "placeholder": "Paikkateksti",
    "customCSS": "Mukautettu CSS",
    "backgroundImage": "Taustakuva",
    "headerImage": "Pääkuva",
    "miscellaneous": "Muut",
    "agencyBranding": "Yrityksen brändi-ilme",
    "imageUploadMessage": "Lataa kuvatiedosto napsauttamalla tästä tai vetämällä kuva tähän",
    "backgroundImageTooltipMessage": "Taustakuvan on tarkoitus kattaa koko sivu ja toimia visuaalisesti miellyttävänä taustana lomakkeelle. Napsauttamalla \"Esikatselu\" voit tarkistaa, miltä taustakuva näyttää.",
    "headerImageTooltipMessage": "Pääkuva on suunniteltu mukautumaan lomakkeen leveyteen ja pysymään lomakkeen yläreunassa. Napsauttamalla \"Esikatselu\" voit tarkistaa, miltä pääkuva näyttää.",
    "onSubmit": "Lähettämisen yhteydessä",
    "facebookPixelID": "Facebook Pixel ‑tunnus",
    "facebookPixelIDMessage": "Ohita tämä kenttä, jos aiot käyttää tätä lomaketta/kyselyä suppilossa",
    "facebookPixelEvents": "Facebook Pixel ‑tapahtumat",
    "onpageView": "Sivun katselun yhteydessä",
    "stickyContact": "Tallennettu kontakti",
    "stickyContactMessage": "Tallennettu kontakti täyttää aiemmin lisätyt kontaktin tiedot automaattisesti, minkä ansiosta et joudu lisäämään niitä kahdesti.",
    "fieldDescription": "Kentän kuvaus",
    "fieldTitle": "Kentän otsikko",
    "queryKey": "Kyselyavain",
    "querykeyTooltipMessage": "Kyselyavain, jota voidaan käyttää URL-parametrina tämän kentän täyttämisessä",
    "required": "Pakollinen",
    "hidden": "Piilotettu",
    "hiddenValue": "Piilotettu arvo",
    "enableCountryPicker": "Ota maavalitsin käyttöön",
    "altText": "Alt-teksti",
    "alignment": "Tasaus",
    "imageWidth": "Kuvan leveys",
    "imageHeight": "Kuvan korkeus",
    "logic": "Logiikka",
    "pleaseInput": "Lisää",
    "editHTML": "Muokkaa HTML:ää",
    "textStyle": "Tekstin tyyli",
    "subText": "Alateksti",
    "text": "Teksti",
    "buttonStyle": "Painikkeen tyyli",
    "fullWidth": "Täysi leveys",
    "htmlMessage": "Lisää mukautettu HTML-koodi tähän",
    "htmlEditorHeader": "MUKAUTETTU JAVASCRIPT/HTML",
    "htmlEditorBodyMessage": "Lisää oma koodi tähän",
    "yesSave": "Kyllä, tallenna",
    "cancelKeep": "Peruuta, säilytä",
    "done": "Valmis",
    "addElement": "Lisää elementti",
    "addUrlLike": "Lisää hyväksyttäviä URL-osoitteita, esim.",
    "doNotAddRelativePath": "Älä lisää suhteellisia polkuja, esim.",
    "or": "tai",
    "addCustomFields": "Lisää mukautettuja kenttiä",
    "customFieldSearch": "Hae nimellä",
    "enterSourceValue": "Anna lähdearvo",
    "shortLabel": "Lyhyt tunniste",
    "customCSSTooltip": "Mukautettu CSS ohittaa lomakkeen tyylivalinnat ja teemat, ja sillä voi olla vaikutusta teeman tyyliin.",
    "fontFamilyTooltip": "Editointityökalu tukee kaikentyyppisiä fontteja, jos et näe etsimääsi luettelossa. Hae sitä pudotusvalikosta",
    "useGDPRCompliantFont": "Käytä GDPR-yhteensopivaa fonttia",
    "enableTimezone": "Ota aikavyöhyke käyttöön",
    "inputStyle": "Syötekentän tyyli",
    "conditionalLogicAlertOnSubmit": "Ehdollinen logiikka ohittaa täällä olevan viestin/uudelleenohjauksen",
    "conditionalLogicAlertOnShowHide": "Ehdollinen logiikka ohittaa täällä olevat piilotetut asetukset",
    "placeholderFacebookPixelIdInput": "Lisää Facebook Pixel ‑tunnus",
    "doNotShowMessage": "Älä näytä enää",
    "themes": {
      "themes": "Teemat",
      "alertThemeMessage": "Teeman vaihtaminen johtaa kaikkien nykyisten tyylimuutosten menettämiseen.",
      "useTheme": "Käytä teemaa",
      "removeTheme": "Poista teema"
    },
    "box": "Laatikko",
    "line": "Rivi",
    "message": "Viesti",
    "openUrl": "Avaa URL-osoite",
    "borderTypeList": {
      "dotted": "Pisteviiva",
      "dashed": "Katkonainen",
      "solid": "Yhtenäinen",
      "none": "Ei mitään"
    },
    "button": "Painike",
    "emailNotification": "Sähköposti-ilmoitus",
    "emailNotificationSubheader": "Saat sähköpostiviestin, kun joku täyttää lomakkeesi",
    "subject": "Aihe",
    "emailTo": "Sähköposti (vastaanottaja)",
    "senderName": "Lähettäjän nimi",
    "senderNameWarning": "(Alitilinimesi) lisätään, jos kohta on jätetty tyhjäksi",
    "emailError": "Vähintään yksi sähköpostiosoite vaaditaan",
    "emailTooltip": "Ensimmäinen sähköpostiosoite lisätään Vastaanottaja-kenttään. Kaikki muut sähköpostiosoitteet lisätään CC-kenttään.",
    "clear": "Tyhjennä",
    "showNotificationsSettings": "Näytä ilmoitusasetukset",
    "emailServicePaid": "Tämä ominaisuus on maksullinen palvelu, ja siitä veloitetaan käytön mukaan.",
    "integration": "Integraatio",
    "payment": {
      "payments": "Maksut",
      "connected": "Yhdistetty",
      "connect": "Yhdistä",
      "liveModeOn": "Live-tila",
      "status": "Tila",
      "test": "Testaa",
      "live": "Julkaistu",
      "paymentFields": "Maksukentät",
      "suggestAnAmount": "Ehdota summaa",
      "selectCurrency": "Valitse valuutta",
      "tagActiveBGColor": "Aktiivisen tunnisteen väri",
      "includeOtherAmount": "Sisällytä muu summa",
      "footerInfo": "Alatunnisteen tiedot",
      "paymentType": "Maksutyyppi",
      "sellProducts": "Myy tuotteita",
      "customAmount": "Muu summa",
      "collectPayment": "Peri maksu",
      "generalSettings": "Yleiset kentät",
      "product": {
        "addProduct": "Lisää tuote",
        "productSettings": "Tuotteen asetukset",
        "selectProduct": "Valitse tuote",
        "editThisProduct": "Jos haluat muokata tätä tuotetta",
        "clickHere": "Napsauta tästä",
        "show": "Näytä",
        "description": "Kuvaus",
        "image": "Kuva",
        "layoutSettings": "Asettelun asetukset",
        "singleColumn": "Yksi sarake",
        "twoColumn": "Kaksi saraketta",
        "threeColumn": "Kolme saraketta",
        "maxProductError": "Yhdellä lomakkeella saa olla enintään 20 tuotetta",
        "productSelectPlaceholder": "Valitse tuote",
        "orderConfirmation": "Tilausvahvistus",
        "orderConfirmationWarning": "Tilauksen vahvistussivu tulee näkyviin, kun maksu on onnistunut",
        "quantity": "Määrä",
        "createProduct": "Jos haluat luoda tuotteen"
      },
      "coupons": {
        "coupons": "Kupongit",
        "enableCoupon": "Ota kuponki käyttöön",
        "couponAlignment": "Kupongin tasaus",
        "left": "Vasen",
        "right": "Oikea",
        "fullWidth": "Täysi leveys",
        "tooltipMessage": "Valitse kupongin syötekohdan sijoitus alla olevista tyypeistä"
      }
    },
    "addressSettings": {
      "autoComplete": "Täydennä osoite automaattisesti",
      "addressfields": "Osoitekentät",
      "placeholderSearch": "Hae osoitetta",
      "autoCompleteTooltip": "Tämän ominaisuuden avulla osoite voidaan hakea ja täyttää automaattisesti. Ominaisuus on maksullinen."
    },
    "autoResponder": "Automaattinen vastaaja",
    "autoResponderSubheader": "Lähetä automaattinen sähköpostiviesti lomakkeen lähettäneelle henkilölle (sähköpostiviesti sisältää kopion lomakkeeseen lisätyistä tiedoista)",
    "replyToEmail": "Vastausosoite",
    "open": "Avoin",
    "won": "Toteutunut",
    "lost": "Menetetty",
    "abandon": "Hylätty",
    "selectAPipeline": "Valitse prosessi",
    "stage": "Vaihe",
    "status": "Tila",
    "opportunityValue": "Mahdollisuuden arvo",
    "createNewPipeline": "Luo uusi prosessi",
    "editOpportunitySettings": "Muokkaa mahdollisuusasetuksia",
    "contact": "Kontakti",
    "opportunity": "Mahdollisuus",
    "add": "Lisää",
    "more": "Lisää",
    "opportunityHeading": "Miltä osin haluat päivittää mahdollisuuden?",
    "date": {
      "dateSettings": "Päivämääräasetukset",
      "dateFormat": "Päivämäärän muoto",
      "dateSeparator": "Päivämääräerotin",
      "dateFormatTooltipMessage": "Valitse päivämäärän muoto. D tarkoittaa päivää, M kuukautta ja Y vuotta",
      "dateSeparatorTooltipMessage": "Valitse erotin, joka erottaa päivän, kuukauden ja vuoden toisistaan Päivämäärän muoto ‑kohdassa valitussa muodossa.",
      "disablePicker": "Poista valitsin käytöstä"
    },
    "restoreVersion": "Palauta versio",
    "versionHistory": "Versiohistoria",
    "errorMessage": "Virhe, yritä uudelleen",
    "version": "Versio:",
    "currentVersion": "Nykyinen versio",
    "messageStyling": "Viestin muotoilu",
    "validateEmailHeader": "Tarkista sähköpostiosoite",
    "validateEmailDescription": "Painike tulee näkyviin, kun käyttäjä lisää sähköpostiosoitteensa. Ominaisuus auttaa saamaan laadukkaita liidejä.",
    "validatePhoneHeader": "Tarkista puhelinnumero",
    "validatePhoneDescription": "Painike tulee näkyviin, kun käyttäjä lisää puhelinnumeronsa Ominaisuus auttaa saamaan laadukkaita liidejä.",
    "validatePhoneWarning": "Jos haluat ottaa tekstiviestitse lähetettävän vahvistuskoodin käyttöön, varmista, että puhelinnumerosi on määritetty sijaintiasetuksissa.",
    "style": {
      "formLayoutType": "Lomakkeen tyyppi",
      "oneColumn": "Yksi sarake",
      "twoColumn": "Kaksi saraketta",
      "singleLine": "Yksi rivi",
      "fieldWidth": "Kentän leveys",
      "labelAlignment": {
        "labelAlignment": "Tunnisteen tasaus",
        "labelWidth": "Tunnisteen leveys"
      },
      "fieldSpacing": "Kenttien välistys",
      "color": "väri",
      "fontColor": "Fontin väri",
      "inputBackground": "Syötekohdan tausta",
      "layout": "Asettelu",
      "colorsAndBackground": "Värit ja tausta",
      "advanceSettings": "Lisäasetukset",
      "inputField": "Syötekenttä",
      "borderWidth": "Reunaviivan leveys",
      "borderColor": "Reunaviivan väri",
      "borderStyle": "Reunaviivan tyyli",
      "fontSize": "Fonttikoko",
      "fontWeight": "Fontin paksuus",
      "labelColor": "Tunnisteen väri",
      "placeholderColor": "Paikkatekstin väri",
      "shortLabelColor": "Lyhyen tunnisteen väri"
    },
    "replyToTooltip": "Mukauta oletusarvoista vastaussähköpostiosoitetta käyttämällä kohtaa {'{'}{'{'}contact.email{'}'}{'}'}, jotta lomakkeen lähettävälle kontaktille voidaan vastata.",
    "content": "Sisältö",
    "calculations": "Laskelmat",
    "uploadOptions": "Latausvaihtoehdot",
    "opportunitySettingsWarning": "Määritä mahdollisuusasetukset napsauttamalla Muokkaa mahdollisuusasetuksia ‑painiketta.",
    "question": "Kysymys",
    "createContactOnPartialSubmission": "Luo kontakti tietojen osittaisen lähettämisen yhteydessä",
    "howItWorks": "Miten se toimii?",
    "createContactOnPartialSubmissionDescription": "Viimeistele dia ja napsauta \"Seuraava\". Uusi kontakti luodaan ja tallennetaan automaattisesti.",
    "swapWarningMessageSurvey": "Vaihtoehtojen muokkaaminen tai vaihtaminen vaikuttaa tähän mukautettuun kenttään kaikkialla, missä sitä on käytetty. Se voi myös nollata ehdollisen logiikan. ",
    "swapWarningMessageForm": "Vaihtoehtojen muokkaaminen tai vaihtaminen vaikuttaa tähän mukautettuun kenttään kaikkialla, missä sitä on käytetty. ",
    "optionLabel": "Vaihtoehdon tunniste",
    "calculationValue": "Laskelma-arvo",
    "addOption": "Lisää vaihtoehto",
    "enableCalculations": "Ota laskelmat käyttöön",
    "productSelectionSubtext": "Salli yhden tai useamman tuotteen valinta",
    "multi": "Multi",
    "single": "Sinkku",
    "productSelectionHeading": "Tuotteen valinta"
  },
  "survey": {
    "typeOfBuilder": "Kysely",
    "survyeStyle": "Kyselyn tyyli",
    "surveyName": "Kyselyn nimi",
    "surveyElement": "Kyselyelementti",
    "addSurveyElement": "Lisää kyselyelementti",
    "unsavedAlertMessage": "Kyselyssä on tallentamattomia muutoksia. Haluatko varmasti poistua ja hylätä kaikki tallentamattomat muutokset?",
    "thirdPartyScriptAlertMessage": "HTML-lohkon sisäiset kolmansien osapuolten komentosarjat voivat mahdollistaa käteviä toiminnallisuuksia, mutta niihin liittyy myös tietosuojaa, turvallisuutta, suorituskykyä ja sivun toimintaa koskevia riskejä. Siksi suosittelemme, että tarkistat tämän komentosarjan sisällön ennen kuin tallennat sen kyselyyn.",
    "integrateHeader": "Integroi kysely",
    "integrateSubHeader": "Voit integroida verkkosivustollesi mukautetun kyselytutkimuksen seuraavien vaihtoehtojen avulla.",
    "integrateCopyLinkMessage": "Kopioi alla oleva linkki ja jaa se helposti missä vain.",
    "onSurveySubmission": "Kyselyn lähettämisen yhteydessä",
    "surveySettings": "Kyselyn asetukset",
    "oneQuestionAtTime": "Yksi kysymys kerrallaan",
    "backButton": "Takaisin-painike",
    "backButtonTooltipMessage": "Takaisin-painike auttaa käyttäjää siirtymään edelliseen diaan",
    "disableAutoNavigation": "Poista automaattinen navigointi käytöstä",
    "disableAutoNavigationTooltipMessage": "Jos asetus on käytössä, kysely ei siirry automaattisesti seuraavaan diaan",
    "progressBar": "Edistymispalkki",
    "progressBarTooltipMessage": "Jos poistat tämän asetuksen käytöstä, edistymispalkki ei näy kyselyssä",
    "disableAnimation": "Poista animaatio käytöstä",
    "disableAnimationTooltipMessage": "Tämän ottaminen käyttöön poistaa dian vaihtumisanimaation käytöstä",
    "scrollToTop": "Vieritä ylös",
    "scrollToTopTooltipMessage": "Jos tämä asetus on käytössä, kysely vieritetään seuraavan dian alkuun",
    "footerHTML": "Alatunnisteen HTML",
    "footerHTMLMessage": "Voit kirjoittaa mukautettua CSS-koodia (käyttämällä style-tagia) ja HTML-koodia samaan kenttään",
    "editFooter": "Muokkaa alatunnistetta",
    "disqualifyImmediately": "Hylkää välittömästi",
    "disqualifyAfterSubmit": "Hylkää lähettämisen jälkeen",
    "slideConflictAlertMessage": "Tässä diassa on useita kysymyksiä, joille on määritetty logiikka. Tämä voi johtaa ei-toivottuun toimintaan",
    "addSlide": "Lisää dia",
    "settings": "Asetukset",
    "delete": "Poista",
    "slideDeleteAlertMessage": "Haluatko varmasti poistaa tämän dian?",
    "slideDeleteBetweenAlertMessage": "Tämä järjestää muut diat uudelleen. Haluatko varmasti poistaa tämän dian?",
    "slideName": "Dian nimi",
    "slidePosition": "Dian sijainti",
    "jumpTo": "Siirry kohtaan",
    "enableTimezoneSurvey": "Tämän vaihtoehdon käyttöönotto tallentaa kyselyyn vastanneen henkilön aikavyöhykettä koskevat tiedot.",
    "footer": {
      "footer": "Alatunniste",
      "enableNewFooter": "Ota uusi alatunniste käyttöön",
      "stickToSurvey": " Pysy kiinni kyselyssä",
      "backgroundFill": "Taustan täyttö",
      "footerHeight": "Alatunnisteen korkeus",
      "enableProgressBar": "Ota edistymispalkki käyttöön",
      "buttons": "Painikkeet",
      "buttonType": "Painikkeen tyyppi",
      "fillNext": "Täytä (seuraava)",
      "fillPrevious": "Täytä (edellinen)",
      "fillSubmit": "Täytä (Lähetä)",
      "textColor": "Painikkeen teksti",
      "position": "Sijainti",
      "previousText": "Edellinen (teksti)",
      "nextText": "Seuraava (teksti)",
      "submitText": "Lähetä (teksti)",
      "fillComplete": "Täyttöväri – valmis",
      "fillInactive": "Täyttöväri – ei-aktiivinen",
      "footerType": "Alatunnisteen tyyppi",
      "stickToCard": "Pysy kiinni kortissa",
      "stickToPage": "Pysy kiinni sivulla"
    },
    "surveyNotfication": "Kyselyilmoitus",
    "style": {
      "surveyPadding": "Kyselyn reunatila"
    },
    "autoResponderSubheader": "Lähetä automaattinen sähköpostiviesti kyselyyn vastanneelle henkilölle (sähköpostiviesti sisältää kopion kyselyyn lisätyistä vastauksista)"
  },
  "form": {
    "typeOfBuilder": "Lomake",
    "formStyle": "Lomakkeen tyyli",
    "formElement": "Lomake-elementti",
    "addFormElement": "Lisää lomake-elementti",
    "addConditionalLogic": "Lisää ehdollinen logiikka",
    "inlineForm": "Inline-lomake",
    "formName": "Lomakkeen nimi",
    "fullScreenMode": "Koko näytön tila",
    "fullScreenModetooltip": "Kun vaihtoehto on otettu käyttöön, lomake mukautuu koko säiliön leveyteen ja ulottuu upotusalueen reunaan huomioiden samalla lomakkeen leveyden.",
    "enableTimezoneForm": "Tämän vaihtoehdon käyttöönotto tallentaa lomakkeen lähettäneen henkilön aikavyöhykettä koskevat tiedot.",
    "formSettings": "Lomakeasetukset",
    "unsavedAlertMessage": "Lomakkeessa on tallentamattomia muutoksia. Haluatko varmasti poistua ja hylätä kaikki tallentamattomat muutokset?",
    "thirdPartyScriptAlertMessage": "HTML-lohkon sisäiset kolmansien osapuolten komentosarjat voivat mahdollistaa käteviä toiminnallisuuksia, mutta niihin liittyy myös tietosuojaa, turvallisuutta, suorituskykyä ja sivun toimintaa koskevia riskejä. Siksi suosittelemme, että tarkistat tämän komentosarjan sisällön ennen kuin tallennat sen lomakkeeseen.",
    "onFormSubmission": "Lomakkeen lähettämisen yhteydessä",
    "integrateForm": "Integroi lomake",
    "embedlayoutType": "Upota asettelutyyppi",
    "embedlayoutTypeSubMessage": "Valitse suppiloon tai ulkoiselle verkkosivustolle upotettava asettelu",
    "triggerType": "Käynnistystyyppi",
    "showOnScrolling": "Näytä sivua vieritettäessä",
    "showAfter": "Näytä seuraavan jälkeen:",
    "seconds": "sekuntia",
    "alwaysShow": "Näytä aina",
    "activationOptions": "Aktivointivaihtoehdot",
    "activateOn": "Aina päällä",
    "visit": "vierailu",
    "alwaysActivated": "Aina aktiivisena",
    "deactivationOptions": "Aktivoinnin poistamisvaihtoehdot",
    "deactivateAfterShowing": "Poista aktivointi näyttämisen jälkeen",
    "times": "kertaa",
    "deactiveOnceLeadCollected": "Poista aktivointi, kun liidi on kerätty",
    "neverDeactivate": "Älä poista aktivointia koskaan",
    "copyEmbedCode": "Kopioi upotuskoodi",
    "copyFormLink": "Kopioi lomakkeen linkki",
    "openFormLink": "Avaa lomakkeen linkki",
    "inline": "Inline",
    "inlineDescription": "Inline-lomake näkyy natiivielementtinä osana sivun muuta sisältöä. Se ei avaudu minkään päälle, eikä sitä voi sulkea tai pienentää.",
    "inlineHoverDescription": "Inline-lomake näkyy natiivielementtinä osana sivun muuta sisältöä. Se ei avaudu minkään päälle, eikä sitä voi sulkea tai pienentää.",
    "popup": "Ponnahdusikkuna",
    "popupDescription": "Lomake näkyy sivun sisällön päälle ilmestyvässä lightbox-ponnahdusikkunassa. Sitä ei voi pienentää, mutta sen voi sulkea.",
    "popupHoverDescription": "Lomake näkyy sivun sisällön päälle ilmestyvässä lightbox-ponnahdusikkunassa. Sitä ei voi pienentää, mutta sen voi sulkea.",
    "politeSlideIn": "Liukuikkuna",
    "politeSlideInDescription": "Liukuikkuna liukuu esiin näytön alareunasta ja katoaa suljettaessa",
    "politeSlideInHoverDescription": "Liukuu esiin näytön alareunasta ja katoaa suljettaessa",
    "stickySidebar": "Tahmea sivupalkki",
    "stickySidebarDescription": "Tahmea sivupalkki ilmestyy näytön sivureunaan, ja sen voi pienentää ikkunan sivuun tarttuvaksi välilehdeksi.",
    "stickySidebarHoverDescription": "Ilmestyy näytön sivureunaan; voidaan pienentää ikkunan sivuun tarttuvaksi välilehdeksi.",
    "allowMinimize": "Salli pienentäminen",
    "conditonalLogic": {
      "conditions": "Ehdot",
      "noExistingCondition": "Ehtoja ei ole vielä luotu",
      "freeRoamMessage": "Säännöt eivät rajoita tekemisiäsi – ainakaan vielä",
      "addNewCondition": "Lisää uusi ehto",
      "redirectToUrl": "Uudelleenohjaa URL-osoitteeseen",
      "displayCustomMessage": "Näytä mukautettu viesti",
      "disqualifyLead": "Hylkää liidi",
      "showHideFields": "Näytä/piilota kentät",
      "openBelowUrl": "Avaa alla oleva URL-osoite",
      "showCustomMessage": "Näytä mukautettu viesti",
      "alertConditionDelete": "Haluatko varmasti poistaa tämän ehdon?",
      "deleteCondition": "Poista ehto",
      "conditionExecutedMessage": "Ehdot toteutetaan järjestyksessä ylhäältä alaspäin.",
      "allOperationOptions": {
        "isEqualTo": "On yhtä kuin",
        "isNotEqualTo": "Ei ole yhtä kuin",
        "contains": "Sisältää seuraavan:",
        "startsWith": "Alkaa seuraavalla:",
        "endsWith": "Päättyy seuraavaan:",
        "isEmpty": "On tyhjä",
        "isFilled": "On täytetty",
        "before": "Ennen seuraavaa:",
        "after": "Seuraavan jälkeen:",
        "greaterThan": "Suurempi kuin",
        "lessThan": "Pienempi kuin"
      },
      "logic": {
        "if": "Jos",
        "then": "Sitten",
        "and": "Ja",
        "or": "Tai"
      },
      "phoneAlertMessage": "Jos haluat hakea maakoodin sisältäviä puhelinnumeroita, sinun on lisättävä hakukyselyyn erikseen +-merkki.",
      "deleteAlertMessage": "Haluatko varmasti poistaa tämän kentän? Myös tähän kenttään liittyvä ehto poistetaan.",
      "deleteField": "Poista kenttä"
    },
    "minimizedTitlePlaceholder": "Pienennetty otsikko",
    "dragYourElementFromLeft": "Vedä elementtejä tähän vasemmalta",
    "formNotification": "Lomakeilmoitus",
    "style": {
      "formPadding": "Lomakkeen reunatila"
    }
  },
  "quiz": {
    "quizElement": "Tietovisaelementti",
    "quizNotification": "Tietovisailmoitus",
    "viewAndCustomise": "Näytä ja mukauta",
    "categoryAndScore": "Kategoria ja pisteet",
    "provideFeedback": "Anna palautetta",
    "addQuizElement": "Lisää tietovisaelementti",
    "typeOfBuilder": "Tietovisa",
    "catgory": {
      "manageCategories": "Hallitse kategorioita"
    },
    "quizSettings": "Tietovisan asetukset",
    "onQuizSubmission": "Tietovisan lähettämisen yhteydessä",
    "settings": "Asetukset",
    "deletePage": "Poista sivu",
    "quizResult": {
      "resultTemplate": "Tulosmalli",
      "quizResultAlertMessage": "Valittu tulossivu näytetään, kun tietovisaan on vastattu"
    },
    "autoResponderSubheader": "Lähetä automaattinen sähköpostiviesti tietovisaan vastanneelle henkilölle (sähköpostiviesti sisältää kopion tietovisaan annetuista vastauksista)",
    "integrateHeader": "Integroi tietovisa",
    "integrateSubHeader": "Integroi mukautettu tietovisa verkkosivustolle seuraavien vaihtoehtojen avulla",
    "integrateCopyLinkMessage": "Kopioi alla oleva linkki ja jaa se helposti missä vain.",
    "pageName": "Sivun nimi",
    "pagePosition": "Sivun sijoituspaikka"
  }
}