{
  "common": {
    "save": "Sauvegarder",
    "saving": "Enregistrement",
    "integrate": "Intégrer",
    "preview": "Prévisualiser",
    "back": "Retour",
    "alert": "Alerte",
    "proceed": "Poursuivre",
    "cancel": "Annuler",
    "delete": "Supprimer",
    "embed": "Intégrer",
    "link": "Lien",
    "iframeEmbed": "Intégration Iframe",
    "styles": "Styles",
    "options": "Options",
    "quickAdd": "Ajout rapide",
    "customFields": "Champs personnalisés",
    "personalInfo": "Informations personnelles",
    "contactInfo": "Informations du contact",
    "submit": "Envoyer",
    "address": "Adresse",
    "customized": "Personnalisé",
    "layout": "Disposition",
    "showLabel": "Afficher l’étiquette",
    "background": "Arrière-plan",
    "border": "Bordure",
    "borderType": "Type de bordure",
    "cornerRadius": "Courbure d’angle",
    "width": "Largeur",
    "padding": "Marge interne",
    "top": "Haut",
    "bottom": "Bas",
    "left": "Gauche",
    "right": "Droite",
    "shadow": "Ombre",
    "color": "Couleur",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Flou",
    "spread": "Diffuser",
    "fieldStyle": "Style de champ",
    "fontColor": "Couleur de la police",
    "label": "Intitulé",
    "fontFamily": "Famille de polices",
    "size": "Taille",
    "weight": "Épaisseur",
    "placeholder": "Paramètre fictif",
    "customCSS": "CSS personnalisé",
    "backgroundImage": "Image d’arrière-plan",
    "headerImage": "Image d’en-tête",
    "miscellaneous": "Divers",
    "agencyBranding": "Personnalisation de marque de l’agence",
    "imageUploadMessage": "Cliquez ou faites glisser un fichier image dans cette zone pour le télécharger",
    "backgroundImageTooltipMessage": "L’image d’arrière-plan est destinée à couvrir toute la page ; elle fournit une toile de fond visuellement attrayante pour le formulaire. Pour afficher l’image d’arrière-plan, cliquez sur l’option Aperçu.",
    "headerImageTooltipMessage": "L’image d’en-tête est conçue pour couvrir toute la largeur du formulaire et reste positionnée en haut du formulaire. Pour afficher l’image d’en-tête, cliquez sur l’option Aperçu.",
    "onSubmit": "Sur Envoyer",
    "facebookPixelID": "ID du pixel Facebook",
    "facebookPixelIDMessage": "Ignorez ce champ si vous prévoyez d’utiliser ce formulaire/ce sondage dans un entonnoir",
    "facebookPixelEvents": "Événements du pixel Facebook",
    "onpageView": "Sur la vue de la page",
    "stickyContact": "Contact épinglé",
    "stickyContactMessage": "Le contact épinglé remplira automatiquement les informations de contact précédemment saisies afin que vous n’ayez pas à les saisir deux fois.",
    "fieldDescription": "Description du champ",
    "fieldTitle": "Titre du champ",
    "queryKey": "Clé de la requête",
    "querykeyTooltipMessage": "La clé de la requête qui peut être utilisée comme paramètre d’URL pour remplir ce champ.",
    "required": "Obligatoire",
    "hidden": "Masqué",
    "hiddenValue": "Valeur masquée",
    "enableCountryPicker": "Activer le sélecteur de pays",
    "altText": "Texte de remplacement",
    "alignment": "Alignement",
    "imageWidth": "Largeur de l’image",
    "imageHeight": "Hauteur de l’image",
    "logic": "Logique",
    "pleaseInput": "Veuillez saisir",
    "editHTML": "Modifier le HTML",
    "textStyle": "Style du texte",
    "subText": "Sous-titre",
    "text": "Texte",
    "buttonStyle": "Style du bouton",
    "fullWidth": "Pleine largeur",
    "htmlMessage": "Le HTML personnalisé va ici",
    "htmlEditorHeader": "JAVASCRIPT/HTML PERSONNALISÉ",
    "htmlEditorBodyMessage": "Ajoutez votre code ici",
    "yesSave": "Oui, sauvegarder",
    "cancelKeep": "Annuler, Garder",
    "done": "Terminé",
    "addElement": "Ajouter un élément",
    "addUrlLike": "Ajoutez des urls valides comme",
    "doNotAddRelativePath": "N’ajoutez pas de chemins relatifs comme",
    "or": "ou",
    "addCustomFields": "Ajouter des champs personnalisés",
    "customFieldSearch": "Rechercher par nom",
    "enterSourceValue": "Saisissez la valeur source",
    "shortLabel": "Courte description",
    "customCSSTooltip": "Le CSS personnalisé a la priorité sur le style et les thèmes des formulaires et peut avoir un impact sur le style du thème.",
    "fontFamilyTooltip": "Le générateur prend en charge tous les types de polices (même si vous ne les voyez pas dans la liste). Veuillez rechercher votre police dans le menu déroulant",
    "useGDPRCompliantFont": "Utilisez une police conforme au RGPD",
    "enableTimezone": "Activer le fuseau horaire",
    "inputStyle": "Style de saisie",
    "conditionalLogicAlertOnSubmit": "La logique conditionnelle aura ici la priorité sur le message/la redirection",
    "conditionalLogicAlertOnShowHide": "La logique conditionnelle aura ici la priorité sur les paramètres cachés",
    "placeholderFacebookPixelIdInput": "Entrez l’ID du pixel Facebook",
    "doNotShowMessage": "Ne plus afficher",
    "themes": {
      "themes": "Thèmes",
      "alertThemeMessage": "Le changement de thème entraînera la perte de toutes les modifications de style actuelles.",
      "useTheme": "Utiliser le thème",
      "removeTheme": "Supprimer le thème"
    },
    "box": "Cadre",
    "line": "Ligne",
    "message": "Message",
    "openUrl": "Ouvrir l’URL",
    "borderTypeList": {
      "dotted": "En pointillés",
      "dashed": "En traits discontinus",
      "solid": "Uni",
      "none": "Aucun"
    },
    "button": "Bouton",
    "emailNotification": "Notification par e-mail",
    "emailNotificationSubheader": "Recevez un e-mail lorsque quelqu’un remplit votre formulaire",
    "subject": "Objet",
    "emailTo": "E-mail (À)",
    "senderName": "Nom de l’expéditeur",
    "senderNameWarning": "Votre (nom de sous-compte) sera ajouté si le champ est laissé vide",
    "emailError": "Au moins une adresse e-mail est requise",
    "emailTooltip": "La première adresse e-mail est désignée pour le champ « À ». Toutes les adresses e-mail ultérieures seront incluses dans le champ « Cc ».",
    "clear": "Effacer",
    "showNotificationsSettings": "Afficher les paramètres de notification",
    "emailServicePaid": "Cette fonctionnalité est un service payant et entraînera des frais en fonction de votre utilisation.",
    "integration": "Intégration",
    "payment": {
      "payments": "Paiements",
      "connected": "Connecté",
      "connect": "Se connecter",
      "liveModeOn": "Mode en direct",
      "status": "Statut",
      "test": "Test",
      "live": "En direct",
      "paymentFields": "Champs de paiement",
      "suggestAnAmount": "Suggérer un montant",
      "selectCurrency": "Sélectionnez la devise",
      "tagActiveBGColor": "Couleur de la balise active",
      "includeOtherAmount": "Inclure un autre montant",
      "footerInfo": "Informations sur le pied de page",
      "paymentType": "Type de paiement",
      "sellProducts": "Vendre des produits",
      "customAmount": "Montant personnalisé",
      "collectPayment": "Collecter le paiement",
      "generalSettings": "Champs généraux",
      "product": {
        "addProduct": "Ajouter un produit",
        "productSettings": "Paramètres du produit",
        "selectProduct": "Sélectionnez un produit",
        "editThisProduct": "Pour modifier ce produit",
        "clickHere": "Cliquez ici",
        "show": "Afficher",
        "description": "Description",
        "image": "Image",
        "layoutSettings": "Paramètres de mise en page",
        "singleColumn": "Colonne unique",
        "twoColumn": "Deux colonnes",
        "threeColumn": "Trois colonnes",
        "maxProductError": "Un maximum de 20 produits est autorisé dans un formulaire",
        "productSelectPlaceholder": "Veuillez sélectionner un produit",
        "orderConfirmation": "Confirmation de commande",
        "orderConfirmationWarning": "Une page de confirmation de commande s’affichera une fois le paiement effectué",
        "quantity": "Quantité",
        "createProduct": "Pour créer un produit"
      },
      "coupons": {
        "coupons": "Coupons",
        "enableCoupon": "Activer le coupon",
        "couponAlignment": "Alignement des coupons",
        "left": "Gauche",
        "right": "Droite",
        "fullWidth": "Pleine largeur",
        "tooltipMessage": "Définissez la position de saisie du coupon parmi les types ci-dessous"
      }
    },
    "addressSettings": {
      "autoComplete": "Utiliser la saisie semi-automatique pour l’adresse",
      "addressfields": "Champs de l’adresse",
      "placeholderSearch": "Rechercher une adresse",
      "autoCompleteTooltip": "Permet de rechercher et de remplir automatiquement l’adresse. Cette fonctionnalité est payante."
    },
    "autoResponder": "Répondeur automatique",
    "autoResponderSubheader": "Envoi d’un e-mail automatisé à la personne qui soumet le formulaire (l’e-mail contient une copie des informations qu’elle a saisies dans le formulaire)",
    "replyToEmail": "E-mail de réponse",
    "open": "Ouverte",
    "won": "Remportée",
    "lost": "Perdue",
    "abandon": "Abandonner",
    "selectAPipeline": "Sélectionnez un pipeline",
    "stage": "Étape",
    "status": "Statut",
    "opportunityValue": "Valeur de l’opportunité",
    "createNewPipeline": "Créer un nouveau pipeline",
    "editOpportunitySettings": "Modifiez les paramètres des opportunités",
    "contact": "Contact",
    "opportunity": "Opportunité",
    "add": "Ajouter",
    "more": "Plus",
    "opportunityHeading": "Où souhaitez-vous que votre opportunité soit mise à jour ?",
    "date": {
      "dateSettings": "Paramètres de date",
      "dateFormat": "Format de date",
      "dateSeparator": "Séparateur de date",
      "dateFormatTooltipMessage": "Sélectionnez un format de date. J pour jour, M pour mois et A pour année",
      "dateSeparatorTooltipMessage": "Sélectionnez un séparateur qui séparera le jour, le mois et l’année dans le format sélectionné dans Format de date",
      "disablePicker": "Désactiver le sélecteur"
    },
    "restoreVersion": "Restaurer la version",
    "versionHistory": "Historique des versions",
    "errorMessage": "Une erreur s’est produite, veuillez réessayer.",
    "version": "Version :",
    "currentVersion": "Version actuelle",
    "messageStyling": "Style des messages",
    "validateEmailHeader": "Valider l’e-mail",
    "validateEmailDescription": "Le bouton deviendra visible une fois que l’utilisateur aura saisi son adresse e-mail. Cette fonctionnalité est conçue pour vous aider à générer des prospects de qualité.",
    "validatePhoneHeader": "Valider le numéro de téléphone",
    "validatePhoneDescription": "Le bouton deviendra visible une fois que l’utilisateur aura saisi son numéro de téléphone. Cette fonctionnalité est conçue pour vous aider à générer des prospects de qualité.",
    "validatePhoneWarning": "Pour activer la vérification OTP par SMS, assurez-vous que votre numéro de téléphone est configuré dans vos paramètres d’emplacement.",
    "style": {
      "formLayoutType": "Type de formulaire",
      "oneColumn": "Une colonne",
      "twoColumn": "Deux colonnes",
      "singleLine": "Ligne unique",
      "fieldWidth": "Largeur du champ",
      "labelAlignment": {
        "labelAlignment": "Alignement des étiquettes",
        "labelWidth": "Largeur de l’étiquette"
      },
      "fieldSpacing": "Espacement des champs",
      "color": "couleur",
      "fontColor": "Couleur de police",
      "inputBackground": "Arrière-plan de la saisie",
      "layout": "Disposition",
      "colorsAndBackground": "Couleurs et arrière-plan",
      "advanceSettings": "Paramètres avancés",
      "inputField": "Champ de saisie",
      "borderWidth": "Épaisseur de la bordure",
      "borderColor": "Couleur de la bordure",
      "borderStyle": "Style de bordure",
      "fontSize": "Taille de la police",
      "fontWeight": "Graisse de la police",
      "labelColor": "Couleur de l’étiquette",
      "placeholderColor": "Couleur du paramètre fictif",
      "shortLabelColor": "Couleur de l’étiquette courte"
    },
    "replyToTooltip": "Personnalisez l’adresse e-mail de réponse par défaut en utilisant {'{'}{'{'}contact.email{'}'}{'}'} pour permettre de répondre au contact qui envoie le formulaire.",
    "content": "Contenu",
    "calculations": "Calculs",
    "uploadOptions": "Options de chargement",
    "opportunitySettingsWarning": "Veuillez configurer les paramètres de l’opportunité en cliquant sur le bouton Modifier les paramètres de l’opportunité.",
    "question": "Question",
    "createContactOnPartialSubmission": "Créer un contact lors d’un envoi partiel",
    "howItWorks": "Comment cela fonctionne-t-il ?",
    "createContactOnPartialSubmissionDescription": "Terminez la diapositive et cliquez sur « Suivant ». Un nouveau contact sera automatiquement créé et stocké.",
    "swapWarningMessageSurvey": "La modification ou l’échange d’options aura un impact sur ce champ personnalisé à tous les endroits où il est utilisé. Il peut également réinitialiser la logique conditionnelle. ",
    "swapWarningMessageForm": "La modification ou l’échange d’options aura un impact sur ce champ personnalisé à tous les endroits où il est utilisé. ",
    "optionLabel": "Intitulé de l’option",
    "calculationValue": "Valeur de calcul",
    "addOption": "Ajouter une option",
    "enableCalculations": "Activer les calculs",
    "productSelectionSubtext": "Autoriser la sélection d'un ou de plusieurs produits",
    "multi": "Multi",
    "single": "Célibataire",
    "productSelectionHeading": "Sélection de produits"
  },
  "survey": {
    "typeOfBuilder": "Sondage",
    "survyeStyle": "Style du sondage",
    "surveyName": "Nom du sondage",
    "surveyElement": "Élément du sondage",
    "addSurveyElement": "Ajouter un élément de sondage",
    "unsavedAlertMessage": "Le sondage comporte des éléments non enregistrés. Voulez-vous vraiment quitter et supprimer tous les éléments non enregistrés ?",
    "thirdPartyScriptAlertMessage": "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités intéressantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Nous vous recommandons donc de vérifier le contenu de ce script avant de l’enregistrer dans le sondage.",
    "integrateHeader": "Intégrer le sondage",
    "integrateSubHeader": "Utilisez les options suivantes pour intégrer votre sondage personnalisé à votre site web",
    "integrateCopyLinkMessage": "Copiez le lien ci-dessous et partagez-le facilement où vous le souhaitez.",
    "onSurveySubmission": "À propos de l’envoi des sondages",
    "surveySettings": "Paramètres du sondage",
    "oneQuestionAtTime": "Une question à la fois",
    "backButton": "Bouton Retour",
    "backButtonTooltipMessage": "Le bouton Retour permet à l’utilisateur de revenir à la diapositive précédente",
    "disableAutoNavigation": "Désactiver la navigation automatique",
    "disableAutoNavigationTooltipMessage": "Si cette option est activée, le sondage ne passera pas automatiquement à la diapositive suivante",
    "progressBar": "Barre de progression",
    "progressBarTooltipMessage": "La désactivation de cette option supprimera la barre de progression de l’enquête.",
    "disableAnimation": "Désactiver l’animation",
    "disableAnimationTooltipMessage": "L’activation de cette option désactivera l’animation de transition des diapositives",
    "scrollToTop": "Faire défiler vers le haut",
    "scrollToTopTooltipMessage": "Si cette option est activée, le sondage défilera vers le haut sur la diapositive suivante",
    "footerHTML": "Pied de page HTML",
    "footerHTMLMessage": "Vous pouvez écrire du CSS personnalisé (à l’aide d’une balise de style) et du HTML dans le même cadre",
    "editFooter": "Modifier le pied de page",
    "disqualifyImmediately": "Disqualifier immédiatement",
    "disqualifyAfterSubmit": "Disqualifier après envoi",
    "slideConflictAlertMessage": "Cette diapositive contient plusieurs questions qui ont une logique définie. Cela peut provoquer un comportement inattendu",
    "addSlide": "Ajouter une diapositive",
    "settings": "Paramètres",
    "delete": "Supprimer",
    "slideDeleteAlertMessage": "Voulez-vous vraiment supprimer cette diapositive ?",
    "slideDeleteBetweenAlertMessage": "Cela aura pour effet de réorganiser les autres diapositives. Voulez-vous vraiment supprimer cette diapositive ?",
    "slideName": "Nom de la diapositive",
    "slidePosition": "Position de la diapositive",
    "jumpTo": "Aller à",
    "enableTimezoneSurvey": "L’activation de cette option permettra de saisir les informations de fuseau horaire de la personne qui envoie le sondage",
    "footer": {
      "footer": "Pied de page",
      "enableNewFooter": "Activer le nouveau pied de page",
      "stickToSurvey": " Attacher au sondage",
      "backgroundFill": "Remplissage de l’arrière-plan",
      "footerHeight": "Hauteur du pied de page",
      "enableProgressBar": "Activer la barre de progression",
      "buttons": "Boutons",
      "buttonType": "Type de bouton",
      "fillNext": "Remplir (suivant)",
      "fillPrevious": "Remplir (précédent)",
      "fillSubmit": "Remplir (Envoyer)",
      "textColor": "Texte du bouton",
      "position": "Position",
      "previousText": "Précédent (texte)",
      "nextText": "Suivant (texte)",
      "submitText": "Envoyer (texte)",
      "fillComplete": "Remplissage terminé",
      "fillInactive": "Remplissage inactif",
      "footerType": "Type de pied de page",
      "stickToCard": "Attacher à la carte",
      "stickToPage": "Attacher à la page"
    },
    "surveyNotfication": "Notification du sondage",
    "style": {
      "surveyPadding": "Marge interne du sondage"
    },
    "autoResponderSubheader": "Envoi d’un e-mail automatisé à la personne qui soumet le sondage (l’e-mail contient une copie des informations qu’elle a saisies dans le sondage)"
  },
  "form": {
    "typeOfBuilder": "Formulaire",
    "formStyle": "Style du formulaire",
    "formElement": "Élément de formulaire",
    "addFormElement": "Ajouter un élément de formulaire",
    "addConditionalLogic": "Ajouter une logique conditionnelle",
    "inlineForm": "Formulaire intégré",
    "formName": "Nom du formulaire",
    "fullScreenMode": "Mode plein écran",
    "fullScreenModetooltip": "En l’activant, le formulaire s’étendra sur toute la largeur de son conteneur, jusqu’au bord de la zone d’intégration tout en respectant la largeur du formulaire.",
    "enableTimezoneForm": "L’activation de cette option permettra de saisir les informations sur le fuseau horaire de la personne qui envoie le formulaire",
    "formSettings": "Paramètres du formulaire",
    "unsavedAlertMessage": "Le formulaire contient des éléments non enregistrés. Voulez-vous vraiment quitter et supprimer tous les éléments non enregistrés ?",
    "thirdPartyScriptAlertMessage": "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités intéressantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Nous vous recommandons donc de vérifier le contenu de ce script avant de l’enregistrer dans le formulaire.",
    "onFormSubmission": "Envoi sur formulaire",
    "integrateForm": "Intégrer le formulaire",
    "embedlayoutType": "Type de disposition d’intégration",
    "embedlayoutTypeSubMessage": "Sélectionnez une disposition à intégrer dans un entonnoir ou un site web externe",
    "triggerType": "Type de déclenchement",
    "showOnScrolling": "Afficher lors du défilement",
    "showAfter": "Afficher après",
    "seconds": "secondes",
    "alwaysShow": "Toujours afficher",
    "activationOptions": "Options d’activation",
    "activateOn": "Activer le",
    "visit": "visiter",
    "alwaysActivated": "Toujours activé",
    "deactivationOptions": "Options de désactivation",
    "deactivateAfterShowing": "Désactiver après affichage",
    "times": "fois",
    "deactiveOnceLeadCollected": "Désactiver une fois le prospect collecté",
    "neverDeactivate": "Ne jamais désactiver",
    "copyEmbedCode": "Copier le code d’intégration",
    "copyFormLink": "Copier le lien du formulaire",
    "openFormLink": "Ouvrir le lien du formulaire",
    "inline": "Intégré",
    "inlineDescription": "Un formulaire intégré apparaît avec le contenu de la page en tant qu’élément natif. Il ne se superpose pas et ne peut pas être fermé ou réduit.",
    "inlineHoverDescription": "Un formulaire intégré apparaît avec le contenu de la page en tant qu’élément natif. Il ne se superpose pas et ne peut pas être fermé ou réduit.",
    "popup": "Fenêtre pop-up",
    "popupDescription": "Une fenêtre pop-up affiche le formulaire sous la forme d’une superposition à l’intérieur d’une boîte à lumière. Elle ne peut pas être réduite, mais elle peut être fermée.",
    "popupHoverDescription": "Une fenêtre pop-up affiche le formulaire sous la forme d’une superposition à l’intérieur d’une boîte à lumière. Elle ne peut pas être réduite, mais elle peut être fermée.",
    "politeSlideIn": "Slide-in non invasif",
    "politeSlideInDescription": "Un slide-in non invasif apparaît en bas de l’écran et disparaît une fois fermé",
    "politeSlideInHoverDescription": "Apparaît en bas de l’écran et disparaît une fois fermé",
    "stickySidebar": "Barre latérale épinglée",
    "stickySidebarDescription": "Une barre latérale épinglée apparaîtra sur le côté de l’écran et peut être réduite à un onglet qui s’attache sur le côté de la fenêtre",
    "stickySidebarHoverDescription": "apparaîtra sur le côté de l’écran et peut être réduite à un onglet qui s’attache sur le côté de la fenêtre",
    "allowMinimize": "Autoriser à réduire",
    "conditonalLogic": {
      "conditions": "Conditions",
      "noExistingCondition": "Il n’y a encore aucune condition existante",
      "freeRoamMessage": "Vous êtes libre de vous déplacer sans vous soucier des règles, du moins pour l’instant",
      "addNewCondition": "Ajouter une nouvelle condition",
      "redirectToUrl": "URL de redirection",
      "displayCustomMessage": "Afficher un message personnalisé",
      "disqualifyLead": "Disqualifier le prospect",
      "showHideFields": "Afficher/Masquer les champs",
      "openBelowUrl": "Ouvrir l’URL ci-dessous",
      "showCustomMessage": "Afficher un message personnalisé",
      "alertConditionDelete": "Voulez-vous vraiment supprimer cette condition ?",
      "deleteCondition": "Supprimer la condition",
      "conditionExecutedMessage": "Les conditions seront exécutées en suivant l’ordre descendant.",
      "allOperationOptions": {
        "isEqualTo": "Est égal à",
        "isNotEqualTo": "N’est pas égal à",
        "contains": "Contient",
        "startsWith": "Commence par",
        "endsWith": "Se termine par",
        "isEmpty": "Est vide",
        "isFilled": "Est rempli",
        "before": "Avant",
        "after": "Après",
        "greaterThan": "Supérieur à",
        "lessThan": "Moins que"
      },
      "logic": {
        "if": "Si",
        "then": "Puis",
        "and": "Et",
        "or": "Ou"
      },
      "phoneAlertMessage": "Pour rechercher des numéros de téléphone avec un indicatif de pays, vous devez inclure explicitement le symbole « + » dans votre requête de recherche.",
      "deleteAlertMessage": "Voulez-vous vraiment supprimer ce champ ? La condition associée à ce champ sera également supprimée.",
      "deleteField": "Supprimer le champ"
    },
    "minimizedTitlePlaceholder": "Titre réduit",
    "dragYourElementFromLeft": "Faites glisser vos éléments ici à partir de la gauche",
    "formNotification": "Notification du formulaire",
    "style": {
      "formPadding": "Marge intérieure du formulaire"
    }
  },
  "quiz": {
    "quizElement": "Élément de quiz",
    "quizNotification": "Notification de quiz",
    "viewAndCustomise": "Afficher et personnaliser",
    "categoryAndScore": "Catégorie et score",
    "provideFeedback": "Fournir des commentaires",
    "addQuizElement": "Ajouter un élément de quiz",
    "typeOfBuilder": "Quiz",
    "catgory": {
      "manageCategories": "Gérer les catégories"
    },
    "quizSettings": "Paramètres du quiz",
    "onQuizSubmission": "Envoi dans le quiz",
    "settings": "Paramètres",
    "deletePage": "Supprimer la page",
    "quizResult": {
      "resultTemplate": "Modèle de résultat",
      "quizResultAlertMessage": "La page de résultats choisie s’affichera une fois le quiz terminé"
    },
    "autoResponderSubheader": "Envoi d’un e-mail automatisé à la personne qui soumet le formulaire (l’e-mail contient une copie des informations qu’elle a saisies dans le formulaire)",
    "integrateHeader": "Intégrer le quiz",
    "integrateSubHeader": "Utilise les options suivantes pour intégrer votre quiz personnalisé dans votre site web",
    "integrateCopyLinkMessage": "Copiez le lien ci-dessous et partagez-le facilement où vous le souhaitez.",
    "pageName": "Nom de la page",
    "pagePosition": "Position de la page"
  }
}