import { getAllBunnyFonts, getAllGoogleFonts } from "@/service/FontServices";
import {
  CONSTANTS,
  DEFAULT_ACTIVE_TAG_BG_COLOR,
  DEFAULT_LABEL_COLOR,
  DEFAULT_TEXT_COLOR,
  DUPLICATE_ALLOWED_FIELDS,
  FOOTER_STYLE_QUIZ,
  MAX_HISTORY_LENGTH,
  PRODUCT_TYPE,
} from "@/util/constants";
import {
  defaultAddressSettings,
  defaultBlueColor,
  defaultButtonStyle,
  defaultGreenColor,
  defaultPadding,
  defaultPaymentSettings,
  defaultResultTemplate,
  defaultShadow,
  defaultShortLabel,
  defaultTextStyle,
  getBorder,
  getTextBgColor,
} from "@/util/default";
import {
  GroupField,
  addressFields,
  applyStyles,
  fieldFullWidth,
  generateFooterStyle,
  getConstructedQueryKey,
  standardFields,
} from "@/util/methods";

import { FormServices } from "@/service/FormServices";
import { LocationService } from "@/service/LocationServices";
import { OpportunityServices } from "@/service/OpportunityServices";
import { PaymentServices } from "@/service/PaymentServices";
import { SurveyServices } from "@/service/SurveyServices";
import { cloneDeep } from "lodash";
import { defineStore } from "pinia";
import Postmate from "postmate";

const textElement = standardFields.customized.group[0];
const privacyElement = {
  ...textElement,
  fontSize: 14,
  padding: { ...textElement.padding, top: 15 },
  label:
    '<p style="text-align: center"><a target="_blank" rel="noopener noreferrer nofollow" href="https://www.example.com">Privacy Policy</a> | <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.example.com">Terms of Service</a></p>',
};
const termsAndConditionstPhoneLabel =
  "I Consent to Receive SMS Notifications, Alerts & Occasional Marketing Communication from company. Message frequency varies. Message & data rates may apply. Text HELP to (XXX) XXX-XXXX for assistance. You can reply STOP to unsubscribe at any time.";

export const useAppStore = defineStore("app", {
  state: (): AppStore => ({
    history: [],
    historyIndex: 0,
    showVersions: false,
    resultTemplate: defaultResultTemplate,
    form: undefined,
    product: "form",
    userId: "",
    parentFolderId: "",
    parentFolderName: "",
    company: { id: "", name: "", logoURL: "", domain: "" },
    email: "",
    apiKey: "",
    tokenId: "",
    locationId: "",
    formId: "",
    surveyId: "",
    anyUnsavedChanges: false,
    enableSaveButton: true,
    showOpportunitiesModal: false,
    builder: {},
    slides: [],
    currentActiveIndex: [undefined, undefined],
    currentSlideIndex: undefined,
    appHandshake: undefined,
    requestRetryCount: 0,
    processing: true,
    elements: [],
    customFields: [],
    customFieldFolders: [],
    pipelines: [],
    formAction: {
      actionType: "2",
      redirectUrl: "",
      thankyouText: "Thank you for taking the time to complete this form.",
    },
    submitMessageStyle: {
      bgColor: "FFFFFF",
      color: "000000",
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: 500,
    },
    facebook: {
      fbPixelId: "",
      formSubmissionEvent: "SubmitApplication",
      pageViewEvent: "pageView",
    },
    formStyle: {
      acBranding: false,
      background: "FFFFFF",
      padding: {
        top: 0,
        bottom: 0,
        left: 20,
        right: 20,
      },
      shadow: {
        horizontal: 0,
        vertical: 0,
        blur: 0,
        spread: 0,
        color: "FFFFFF",
      },
      border: {
        border: 1,
        color: "CDE0EC",
        radius: 4,
        style: "dashed",
      },
      width: 548,
      height: 90,
      customStyle: "",
      fieldSpacing: 16,
    },
    fieldStyle: {
      bgColor: "e9ecef",
      fontColor: "000000",
      activeTagBgColor: DEFAULT_ACTIVE_TAG_BG_COLOR,
      labelColor: "000000",
      labelFontFamily: "Roboto",
      labelFontSize: "14",
      labelFontWeight: "400",
      placeholderColor: "AFB8BC",
      placeholderFontFamily: "Roboto",
      placeholderFontSize: "14",
      placeholderFontWeight: "400",
      border: {
        border: 0,
        radius: 5,
        color: "FFFFFF",
        type: "none",
      },
      padding: {
        top: 15,
        bottom: 15,
        left: 20,
        right: 20,
      },
      shadow: {
        horizontal: 0,
        vertical: 0,
        blur: 0,
        spread: 0,
        color: "FFFFFF",
      },
      shortLabel: defaultShortLabel,
      labelAlignment: "top",
      labelWidth: 200,
    },
    footerStyle: {
      theme: "steps",
      backgroundFill: "006EEEFF",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      height: 50,
      enableProgressBar: false,
      stickyFooter: true,
      progressBarStyle: {
        textColor: "B2CCFF",
        borderColor: "84ADFF",
        borderWidth: 1,
        borderRadius: 12,
        completeFillColor: "84ADFF",
        inactiveFillColor: "FFFFFF00",
      },
      buttonStyle: {
        buttonType: "textAndArrow",
        position: "leftAndRight",
        fontColor: "FFFFFF",
        nextButtonBgColor: "00000012",
        backButtonBgColor: "FFFFFF00",
        submitButtonBgColor: "00000012",
        prevBtnText: "PREV",
        nextBtnText: "NEXT",
        submitBtnText: "SUBMIT",
      },
      computedStyles: null,
    },
    emailNotificationsConfig: null,
    autoResponderConfig: null,
    opportunitySettings: null,
    builderConfig: {
      disableAutoNavigation: false,
      formLabelVisible: true,
      isAnimationDisabled: false,
      isBackButtonEnable: false,
      isProgressBarEnabled: true,
      isSurveyScrollEnabled: false,
      stickyContact: false,
      surveyLogicLinkById: true,
      inlineForm: false,
      newFooter: true,
      enablePartialContactCreation: false,
      emailNotifications: false,
      autoResponder: false,
      isGDPRCompliant: false,
      enableTimezone: true,
      conditionalLogic: null,
      fullScreenMode: false,
      inputStyleType: "box",
      currentThemeId: undefined,
      layout: CONSTANTS.ONE_COLUMN,
    },
    payment: { ...defaultPaymentSettings },
    addressSettings: {
      ...defaultAddressSettings,
    },
    isAnyElementActive: false,
    currentCustomSidebarPreview: undefined,
    sidebarOverlaySettings: undefined,
    activeElement: undefined,
    duplicateAllowed: ["html", "header", "text", "image"],
    previewDomain: "",
    standardFields: [],
    integrate: {
      formLayoutSettings: {},
      baseUrl: "",
      isWhiteLabelled: false,
    },
    tempSlides: [],
    lastStatusOfRightDrawer: false,
    fontCollection: [],
    googleFonts: [],
    bunnyFonts: [],
    themes: [],
    defaultThemeId: undefined,
    version: 3,
    paymentIntegrations: undefined,
    currencyList: [],
    currencyOptions: [],
    autoSaveFormOrSurvey: 1,
    isPaymentEnabled: false,
    productList: [],
    addedProductList: [],
    totalProduct: 0,
    getProductListAPIProcessed: false,
    isOpportunitiesEnabled: false,
    locationCountry: null,
    isAdvanceSettingEnable: false,
    quizId: "",
    quizSettings: {
      resultTemplateId: "0",
    },
    logic: {},
    category: [{ label: "Overall Only", value: "overAllScore", id: "overAllScore" }],
  }),
  actions: {
    addToHistory() {
      const currentState = this.extractSelectiveFields(this.$state);
      if (this.history.length >= MAX_HISTORY_LENGTH) {
        this.history.shift();
        this.historyIndex--;
      }
      if (this.historyIndex < this.history.length - 1) {
        this.history.splice(this.historyIndex + 1);
        this.historyIndex = this.history.length - 1;
      }
      this.history.push(cloneDeep(currentState));
      this.historyIndex++;
    },
    undo() {
      this.historyIndex--;
      const previousState = this.extractSelectiveFields(this.history[this.historyIndex]);
      this.$patch({ ...previousState, history: this.history, historyIndex: this.historyIndex });
      this.anyUnsavedChanges = true;
    },
    redo() {
      this.historyIndex++;
      const nextState = this.extractSelectiveFields(this.history[this.historyIndex]);
      this.$patch({ ...nextState, history: this.history, historyIndex: this.historyIndex });
      this.anyUnsavedChanges = true;
    },
    extractSelectiveFields(state) {
      const {
        builder: { versionHistory, ...restBuilder },
        slides,
        elements,
        formAction,
        submitMessageStyle,
        facebook,
        formStyle,
        fieldStyle,
        footerStyle,
        builderConfig,
        integrate,
      } = state;

      return cloneDeep({
        builder: restBuilder,
        slides,
        elements,
        formAction,
        submitMessageStyle,
        facebook,
        formStyle,
        fieldStyle,
        footerStyle: { ...footerStyle, computedStyles: generateFooterStyle(this) },
        builderConfig,
        integrate,
      });
    },
    updateRequestRetryCount(count: number) {
      // eslint-disable-next-line no-console
      this.requestRetryCount = count;
    },
    setSelectedElement(selectedElement: any, index) {
      this.elements[index] = selectedElement;
    },
    initialize(payload: AppStore) {
      this.userId = payload.userId;
      this.company = payload.company;
      this.email = payload.email;
      this.apiKey = payload.apiKey;
      this.tokenId = payload.tokenId;
      this.locationId = payload.locationId;
      this.product = payload.formId
        ? PRODUCT_TYPE.FORM
        : payload.surveyId
        ? PRODUCT_TYPE.SURVEY
        : PRODUCT_TYPE.QUIZ;
      this.formId = payload.formId;
      this.surveyId = payload.surveyId;
      this.quizId = payload.quizId;
      this.isPaymentEnabled = payload.isPaymentEnabled;
      this.isOpportunitiesEnabled = payload.isOpportunitiesEnabled;
      this.locationCountry = payload.locationCountry;
    },
    refreshToken(newToken: string) {
      this.tokenId = newToken;
      this.getFormOrSurvey();
    },
    setAppHandshake(payload: any) {
      this.appHandshake = payload;
    },
    async handleDropAndMove(item, newIndex, oldIndex?) {
      //Only if elements are added or dragged sometimes user might drag the sample div
      if (item) {
        const elementDrop = {
          ...cloneDeep(item),
          hiddenFieldQueryKey: item.id ? item.hiddenFieldQueryKey : item.tag,
          active: false,
        };
        delete elementDrop.icon;
        let isElementAllowed = false;
        // Add
        if (oldIndex === undefined) {
          const isGroupAdreessChild =
            this.elements.find((element) => element.tag === "group_address") &&
            this.addressSettings.children.find((element) => element.tag === elementDrop.tag);
          const alreadyPresentElement =
            this.elements.find((element) => {
              return elementDrop?.id
                ? element?.id === elementDrop?.id
                : element.tag === elementDrop.tag;
            }) || isGroupAdreessChild;
          if (elementDrop.id && alreadyPresentElement) {
            return;
          }
          if (alreadyPresentElement) {
            isElementAllowed = elementDrop?.custom
              ? true
              : this.duplicateAllowed.includes(alreadyPresentElement.tag);
          } else {
            isElementAllowed = true;
          }
          if (elementDrop?.custom) {
            const customElementCount = this.elements.filter((element) => element?.custom)?.length;
            if (!customElementCount && !this.parentFolderId) {
              this.parentFolderName = `Form | ${this.builder.name}`;
              const response = await LocationService.createCustomField(this.locationId, {
                name: this.parentFolderName,
                documentType: "folder",
                model: "contact",
              });
              this.parentFolderId = response.data?.customFieldFolder?.id;
            }
            const existingElementsCount = [...this.customFields, ...this.elements].filter(
              (element) => element.dataType === elementDrop.dataType
            )?.length;
            elementDrop.label = `${elementDrop.label} ${existingElementsCount + 1}${Math.random()
              .toString(36)
              .substr(2, 3)}`;
            elementDrop.hiddenFieldQueryKey = getConstructedQueryKey(elementDrop.label);
            elementDrop.parentId = this.parentFolderId;
          }
        } else {
          isElementAllowed = true;
        }
        if (isElementAllowed) {
          this.anyUnsavedChanges = true;
          // Add
          if (oldIndex === undefined) {
            // if group_address is present then add the address Fields  to groupAddress
            if (
              this.elements.find((element) => element.tag === "group_address") &&
              addressFields.find((element) => element.tag === elementDrop.tag)
            ) {
              this.addressSettings.children.push(elementDrop);
            } else {
              this.elements.splice(newIndex, 0, elementDrop);
            }
            const termsAndConditionsQuery = (element) => element.tag === "terms_and_conditions";
            const privacyElementQuery = (element) => element.label === privacyElement.label;
            if (elementDrop.tag === "phone" && !this.elements.find(termsAndConditionsQuery)) {
              this.elements.push({
                ...standardFields.customized.group.find(termsAndConditionsQuery),
                placeholder: termsAndConditionstPhoneLabel,
                preview: termsAndConditionstPhoneLabel,
              });
              if (!this.elements.find(privacyElementQuery)) {
                this.elements.push(privacyElement);
              }
            }
            if (elementDrop.tag === "group_address") {
              this.addressSettings = defaultAddressSettings;
              this.addressSettings.children = addressFields.map((childElement) => {
                const currentChildElementIndex = this.elements.findIndex(
                  (element) => element.tag === childElement.tag
                );
                const currentChildElement =
                  currentChildElementIndex === -1 ? {} : this.elements[currentChildElementIndex];
                // delete currentChildElement from elements
                if (currentChildElementIndex !== -1) {
                  this.elements.splice(currentChildElementIndex, 1);
                }
                return {
                  ...childElement,
                  ...currentChildElement,
                };
              });
            }
            if (elementDrop.tag === "payment") {
              this.formAction.actionType = "3";
              this.payment = {
                ...defaultPaymentSettings,
                paymentType: elementDrop.subType === "product" ? "product" : "donation",
              };
            }
            if (
              elementDrop.tag === "button" &&
              this.builderConfig.layout === CONSTANTS.SINGLE_LINE
            ) {
              elementDrop.fullwidth = false;
            }
            if (
              elementDrop.model === "opportunity" &&
              this.opportunitySettings?.payload?.customFields?.length
            ) {
              this.opportunitySettings.payload.customFields.push({
                id: elementDrop.Id,
                key: elementDrop.fieldKey.replace("opportunity.", ""),
              });
            }
            if (this.elements?.filter((element) => element.model === "opportunity")?.length === 1) {
              this.showOpportunitiesModal = true;
            }
            const validFieldsForWidth = this.elements.filter(
              (field) => !fieldFullWidth(field.type, true)
            ).length;

            if (!fieldFullWidth(this.elements[newIndex].type, true)) {
              this.elements[newIndex].fieldWidthPercentage = this.mapFieldWidthPercentage(
                item,
                validFieldsForWidth
              );
            }
            if (this.builderConfig.layout === CONSTANTS.SINGLE_LINE) {
              this.elements.forEach((field) => {
                if (!fieldFullWidth(field.type, true)) {
                  field.fieldWidthPercentage = Math.max(
                    parseFloat((100 / validFieldsForWidth).toFixed(2)),
                    20
                  );
                }
              });
            }
          } else {
            // Move
            const oldElement = this.elements[oldIndex];
            const newElement = this.elements[newIndex];
            // if dragged outside of number element dont do anything
            if (newIndex <= this.elements.length - 1) {
              this.elements.splice(newIndex, 1, oldElement);
              this.elements.splice(oldIndex, 1, newElement);
            }
          }
          if (elementDrop.tag === "html") {
            this.isBuilderContainerScript = true;
          }
          this.addToHistory();
        }
      }
    },
    setCustomFields(customFields: any) {
      customFields.sort(
        (date1, date2) =>
          new Date(date1.dateAdded).setHours(0, 0, 0, 0) -
          new Date(date2.dateAdded).setHours(0, 0, 0, 0)
      );
      const mappedCustomFields = customFields.filter((field) => {
        if (!field.standard) {
          field.Id = field._id || field.Id;
          field.tag = field._id || field.Id;
          field.type = field.dataType.toLowerCase();
          field.label = field.name;
          field.edit = false;
          field.active = false;
          field.required = false;
          if (field.dataType === "PHONE") {
            field.enableCountryPicker = false;
          }
          field.hiddenFieldQueryKey = getConstructedQueryKey(field.name);
          delete field._id;
          field.edit = false;
          if (field.dataType === "FILE_UPLOAD") {
            field.multiple_files_allowed = field.isMultiFileAllowed;
            field.multi_file_limit = field.maxFileLimit;
            field.isMultipleFile = field.isMultiFileAllowed;
            field.maxFileSize = field.maxFileLimit;
          }
          if (field.dataType === "DATE") {
            field.format = "YYYY-MM-DD";
            field.separator = "-";
          }
          if (field?.fieldKey.includes("score_")) {
            field.dataType = "SCORE";
          }
          return field;
        }
      });
      this.customFields = mappedCustomFields;
      const addCustomFieldLabels = (elements, customFields) => {
        return elements?.map((element) => {
          const matchingCustomField = customFields?.find(
            (customField) => customField?.Id === element?.id
          );
          if (matchingCustomField) {
            element.customFieldLabel = matchingCustomField?.label;
          }
          return element;
        });
      };
      if (this.formId) {
        this.elements = addCustomFieldLabels(this.elements, this.customFields);
      } else if (this.surveyId) {
        this.slides = this.slides?.map((slide) => {
          slide.slideData = addCustomFieldLabels(slide?.slideData, this.customFields);
          return slide;
        });
      }
    },
    setCustomFieldFolders(customFieldFolders: any) {
      this.customFieldFolders = customFieldFolders.sort(
        (date1, date2) =>
          new Date(date1.dateAdded).setHours(0, 0, 0, 0) -
          new Date(date2.dateAdded).setHours(0, 0, 0, 0)
      );
    },
    async getCustomFields() {
      try {
        const response = await LocationService.getCustomFields(
          this.locationId,
          "",
          0,
          5000,
          "field",
          "",
          true
        );
        const customFields = response.data?.customFields || [];
        this.setCustomFields(customFields);
      } catch (err) {
        console.error(err, "ERRRR");
      }
    },
    async updateResultTemplate(resultTemplate) {
      this.anyUnsavedChanges = true;
      this.resultTemplate = resultTemplate;
    },
    async getCustomFieldFolders() {
      try {
        const response = await LocationService.getCustomFields(
          this.locationId,
          "",
          0,
          5000,
          "folder",
          "",
          true
        );
        const customFieldFolders = response.data?.customFieldFolders || [];
        this.setCustomFieldFolders(customFieldFolders);
      } catch (err) {
        console.error(err, "ERRRR");
      }
    },
    async getPipelines() {
      try {
        const response = await OpportunityServices.getPipelines(this.locationId);
        this.pipelines = response.data?.pipelines || [];
      } catch (err) {
        console.error(err, "ERRRR");
      }
    },
    openCustomFieldsModel() {
      if (this.appHandshake) {
        this.appHandshake.then((parent) => parent.emit("openCustomFieldsModal"));
      }
    },
    openQuizResultBuilderModal() {
      if (this.appHandshake) {
        this.appHandshake.then((parent) =>
          parent.emit(
            "openQuizResultBuilderModal",
            JSON.parse(
              JSON.stringify({
                ...this.resultTemplate,
                categories: this.category
                  .filter((c) => c.value !== "overAllScore")
                  .map((c) => c.label),
              })
            )
          )
        );
      }
    },
    getPreviewUrl() {
      if (this.appHandshake) {
        this.appHandshake.then((parent) => parent.emit("getPreviewUrl"));
      }
    },
    setPreviewUrl(preview) {
      this.previewDomain = preview;
    },
    setLayout(layout) {
      if ([CONSTANTS.ONE_COLUMN, CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(layout)) {
        this.builderConfig.layout = this.builder.formData.form.layout;
      } else if (layout) {
        this.builderConfig.layout = CONSTANTS.TWO_COLUMN;
      } else {
        this.builderConfig.layout = CONSTANTS.ONE_COLUMN;
      }
    },
    // eslint-disable-next-line
    async getFormOrSurvey() {
      try {
        let data: any;
        if (this.product === PRODUCT_TYPE.FORM) {
          const formData = await FormServices.getFormById(this.formId);
          data = formData.data;
          const fields = data.form?.formData?.form?.fields;
          if (fields?.length > 0) {
            const isGroupAddressPresent = fields.some((field) => field.tag === "group_address");
            if (isGroupAddressPresent) {
              data.form.formData.form.fields = fields.filter((field) => {
                return !["address", "city", "state", "postal_code", "country"].includes(field.tag);
              });
            }
          }
        } else {
          const surveyData = await SurveyServices.getSurveyById(this.surveyId);
          data = surveyData.data;

          data.survey?.formData?.slides?.forEach((slide) => {
            const isGroupAddressPresent = slide.slideData.some(
              (field) => field.tag === "group_address"
            );
            if (isGroupAddressPresent) {
              slide.slideData = slide.slideData.filter((field) => {
                return !["address", "city", "state", "postal_code", "country"].includes(field.tag);
              });
            }
          });
        }
        this.form = data;
        this.builder = this.formId ? data?.form : data?.survey;
        this.setLayout(this.builder.formData?.form?.layout);
        //Coping the saved elements in store
        const fields = this.builder.formData?.form?.fields || [];
        //Just for Older forms to work in new builder as older form have different way of storing button
        const validFieldsForWidth = fields.filter(
          (field) => !fieldFullWidth(field.type, true)
        ).length;
        if (fields.length > 0) {
          this.elements = fields.map((dbElement) => {
            if (dbElement.id) {
              dbElement.hiddenFieldQueryKey = dbElement.hiddenFieldQueryKey
                ? dbElement.hiddenFieldQueryKey
                : getConstructedQueryKey(dbElement.name);
            } else {
              dbElement.hiddenFieldQueryKey = dbElement.hiddenFieldQueryKey
                ? dbElement.hiddenFieldQueryKey
                : dbElement.tag;
              dbElement.standard = true;
            }
            if (dbElement.tag === "button") {
              //Adding older form details in new forms way
              dbElement.bgColor = dbElement.bgColor
                ? dbElement.bgColor
                : this.builder.formData?.form?.button.background;
              dbElement.borderColor = dbElement.borderColor
                ? dbElement.borderColor
                : this.builder.formData?.form?.button.color;
              dbElement.radius = dbElement.radius
                ? dbElement.radius
                : this.builder.formData?.form?.button?.border?.radius;
              dbElement.padding = dbElement.padding
                ? dbElement.padding
                : {
                    top: this.builder.formData?.form?.button?.border?.border?.padding,
                    bottom: this.builder.formData?.form?.button?.border?.border?.padding,
                    left: this.builder.formData?.form?.button?.border?.border?.padding,
                    right: this.builder.formData?.form?.button?.border?.border?.padding,
                  };
              dbElement.shadow = dbElement.shadow
                ? dbElement.shadow
                : {
                    horizontal: 0,
                    vertical: 0,
                    blur: 0,
                    spread: 0,
                    color: "FFFFFF",
                  };
              // Collecting fonts
              if (
                !this.fontCollection.includes(dbElement.fontFamily || defaultButtonStyle.fontFamily)
              ) {
                this.fontCollection.push(dbElement.fontFamily || defaultButtonStyle.fontFamily);
              }
              if (
                !this.fontCollection.includes(
                  dbElement.subTextFontFamily || defaultButtonStyle.subTextFontFamily
                )
              ) {
                this.fontCollection.push(
                  dbElement.subTextFontFamily || defaultButtonStyle.subTextFontFamily
                );
              }
            }
            //Older Forms with header element. It will add missing keys and will evetually sync every form as it saves
            if (dbElement.tag === "header") {
              dbElement.fontFamily = dbElement.fontFamily || defaultTextStyle.fontFamily;
              dbElement.color = dbElement.color || defaultTextStyle.color;
              dbElement.fontSize =
                dbElement.fontSize || parseFloat(dbElement.size) || defaultTextStyle.fontSize;
              dbElement.weight = dbElement.weight || defaultTextStyle.weight;
              dbElement.bgColor = getTextBgColor(
                dbElement.bgColor,
                dbElement.color,
                this.formStyle.background
              );
              dbElement.border = getBorder(dbElement?.border);
              dbElement.shadow = dbElement.shadow || defaultShadow;
              dbElement.padding = dbElement.padding || defaultPadding;
              // Collecting fonts
              if (!this.fontCollection.includes(dbElement.fontFamily)) {
                this.fontCollection.push(dbElement.fontFamily);
              }
            }
            if (!dbElement.fieldWidthPercentage && !fieldFullWidth(dbElement.type, true)) {
              dbElement.fieldWidthPercentage = this.mapFieldWidthPercentage(
                dbElement,
                validFieldsForWidth
              );
            }
            return dbElement;
          });
        }
        if (this.formId && this.builder.version >= 4 && fields.length === 0) {
          this.defaultFormField();
        }
        this.slides = this.builder?.formData?.slides || [];

        if (this.surveyId && !this.builder.formData.surveyLogicLinkById) {
          if (!this.builder.formData.form.formAction.fieldSettingEnable) {
            this.slides.forEach((element, index) => {
              if (!element.id) {
                const id = new Date().getTime() + "-" + index;
                element.id = id;
              }
            });
            this.slides.forEach((element: any, index: number) => {
              if ("logic" in element) {
                for (const logicKey in element.logic) {
                  for (const optionsKey in element.logic[logicKey]) {
                    const optStore = element.logic[logicKey][optionsKey];
                    if (optStore) {
                      const optIdStore = this.slides[optStore.index];
                      if (optIdStore) {
                        element.logic[logicKey][optionsKey].id = optIdStore.id;
                      }
                    }
                  }
                }
              }
            });
          } else {
            const id = new Date().getTime() + "-0";
            const surveySlides = this.slides[0];
            surveySlides.id = id;
            if ("logic" in surveySlides) {
              for (const logicKey in surveySlides.logic) {
                for (const optionsKey in surveySlides.logic[logicKey]) {
                  const optStore = surveySlides.logic[logicKey][optionsKey];
                  if (optStore) {
                    const optIdStore = surveySlides.slideData[optStore.index];
                    if (optIdStore) {
                      // Assign Right ID of the slide
                      surveySlides.logic[logicKey][optionsKey].id =
                        "id" in optIdStore ? optIdStore.id : optIdStore.tag;
                    }
                  }
                }
              }
            }
            this.slides[0] = surveySlides;
          }
        }
        //Coping Database Style into store style
        if (this.builder.formData?.form?.style) {
          this.formStyle.fieldSpacing = this.builder.formData.form.style.fieldSpacing || 16;
          this.formStyle.border = this.builder.formData.form.style.border || this.formStyle.border;
          this.formStyle.padding =
            this.builder.formData.form.style.padding || this.formStyle.padding;
          this.formStyle.shadow = this.builder.formData.form.style.shadow || this.formStyle.shadow;
          this.formStyle.acBranding =
            this.builder.formData.form.style.acBranding || this.formStyle.acBranding;
          this.formStyle.background =
            this.builder.formData.form.style.background || this.formStyle.background;
          this.formStyle.width = this.builder.formData.form.width || this.formStyle.width;
          this.formStyle.height = this.builder.formData.form.height || this.formStyle.height;
          this.formStyle.customStyle =
            this.builder.formData.form.customStyle || this.formStyle.customStyle;
          this.formStyle.bgImage = this.builder.formData.form.style.bgImage || "";
        }
        // Coping Database details into store
        if (this.builder.formData?.form) {
          this.fieldStyle = this.builder.formData.form.fieldStyle || this.fieldStyle;
          if (!this.fieldStyle.activeTagBgColor) {
            this.fieldStyle.activeTagBgColor = DEFAULT_ACTIVE_TAG_BG_COLOR;
          }
          this.fieldStyle.shortLabel =
            this.builder.formData.form.fieldStyle?.shortLabel || defaultShortLabel;
          this.fieldStyle.labelAlignment =
            this.builder.formData.form.fieldStyle?.labelAlignment || "top";
          this.fieldStyle.labelWidth = this.builder.formData.form.fieldStyle?.labelWidth || 200;
          this.formAction = {
            actionType: !isNaN(this.builder.formData.form.formAction?.actionType)
              ? this.builder.formData.form.formAction?.actionType || "2"
              : "2",
            redirectUrl: this.builder.formData.form.formAction?.redirectUrl || "",
            thankyouText:
              this.builder.formData.form.formAction?.thankyouText ||
              `Thank you for taking the time to complete this ${
                this.surveyId ? "survey" : "form"
              }.`,
            headerImageSrc: this.builder.formData.form.formAction?.headerImageSrc || "",
          };
          //Common Builder Config
          this.builderConfig.formLabelVisible = this.builder.formData.form.formLabelVisible ?? true;
          this.builderConfig.stickyContact = this.builder.formData.form.stickyContact ?? false;
          this.builderConfig.isGDPRCompliant = this.builder.formData.form.isGDPRCompliant || false;
          this.builderConfig.enableTimezone = this.builder.formData.form.enableTimezone ?? true;
          this.builderConfig.conditionalLogic = this.builder.formData.form.conditionalLogic || null;
          this.builderConfig.fullScreenMode =
            this.builder.formData.form.fullScreenMode || this.builder.verison >= 2;
          this.builderConfig.inputStyleType = this.builder.formData.form.inputStyleType || "box";
          this.builderConfig.currentThemeId = this.builder.formData.form.currentThemeId;
          this.builderConfig.emailNotifications = this.builder.formData.emailNotifications ?? false;
          this.builderConfig.autoResponder = this.builder.formData.autoResponder ?? false;
          this.emailNotificationsConfig =
            this.builder.formData.form.emailNotificationsConfig || this.emailNotificationsConfig;
          this.opportunitySettings =
            this.builder.formData.form.opportunitySettings || this.opportunitySettings;
          this.payment = this.builder.formData.form.payment;
          this.addressSettings = this.builder.formData.form.address || this.addressSettings;
          if (this.payment) {
            this.payment.selectionType = this?.payment?.selectionType || "checkbox";
          }
          this.autoResponderConfig =
            this.builder.formData.form.autoResponderConfig || this.autoResponderConfig;
          this.parentFolderId = this.builder.formData.parentFolderId || "";
          this.parentFolderName = this.builder.formData.parentFolderName || "";
          // Only When Survey
          if (this.surveyId) {
            this.footerStyle = this.builder.formData.form.footerStyle || this.footerStyle;
            this.version = this.builder.version;
            this.formAction.disqualifiedText =
              this.builder.formData.form.formAction?.disqualifiedText ||
              "Thank you for taking the time to complete this survey.";
            this.formAction.disqualifiedType =
              this.builder.formData.form?.formAction?.disqualifiedType || 2;
            this.formAction.disqualifiedUrl =
              this.builder.formData.form?.formAction?.disqualifiedUrl || "";
            this.formAction.endsurveyText =
              this.builder.formData.form?.formAction?.endsurveyText ||
              "Thank you for taking the time to complete this survey.";
            this.formAction.endsurveyType =
              this.builder.formData.form?.formAction?.endsurveyType || 2;
            this.formAction.endsurveyUrl =
              this.builder.formData.form?.formAction?.endsurveyUrl || "";
            this.formAction.fieldPerPage =
              this.builder.formData.form?.formAction?.fieldPerPage || 1;
            this.formAction.fieldSettingEnable =
              this.builder.formData.form?.formAction?.fieldSettingEnable ?? false;
            this.formAction.footerHtml = this.builder.formData.form?.formAction?.footerHtml || "";
            this.formAction.headerFullWidthEnable =
              this.builder.formData.form?.formAction?.headerFullWidthEnable ?? false;

            //Survey Builder Config
            this.builderConfig.disableAutoNavigation =
              this.builder.formData.form.disableAutoNavigation ?? false;
            this.builderConfig.isBackButtonEnable =
              this.builder.formData.form.isBackButtonEnable ?? false;
            this.builderConfig.isProgressBarEnabled =
              this.builder.formData.form.isProgressBarEnabled ?? true;
            this.builderConfig.isAnimationDisabled =
              this.builder.formData.form.isAnimationDisabled ?? false;
            this.builderConfig.isSurveyScrollEnabled =
              this.builder.formData.form.isSurveyScrollEnabled ?? false;
            this.builderConfig.surveyLogicLinkById =
              this.builder.formData.surveyLogicLinkById ?? false;
            this.builderConfig.newFooter =
              this.builder.version >= 2 ? true : this.builder.formData.newFooter ?? false;
            this.builderConfig.enablePartialContactCreation =
              this.builder.formData.enablePartialContactCreation ?? false;

            this.slides.forEach((slide) => {
              slide.slideData.forEach((element) => {
                if (element.tag === "header") {
                  element.fontFamily =
                    element.fontFamily || element.font || defaultTextStyle.fontFamily;
                  element.color = element.color || defaultTextStyle.color;
                  element.fontSize = element.fontSize || element.size || defaultTextStyle.fontSize;
                  element.weight = element.weight || defaultTextStyle.weight;
                  element.bgColor = getTextBgColor(
                    element.bgColor,
                    element.color,
                    this.formStyle.background
                  );
                  element.border = getBorder(element?.border);
                  element.shadow = element.shadow || defaultShadow;
                  element.padding = element.padding || defaultPadding;
                  // Collecting fonts
                  if (!this.fontCollection.includes(element.fontFamily)) {
                    this.fontCollection.push(element.fontFamily);
                  }
                }
              });
            });
          }

          this.submitMessageStyle =
            this.builder.formData.form.submitMessageStyle || this.submitMessageStyle;
          this.facebook.fbPixelId = this.builder.formData.form.fbPixelId || this.facebook.fbPixelId;
          this.facebook.pageViewEvent =
            this.builder.formData.form.pageViewEvent || this.facebook.pageViewEvent;
          this.facebook.formSubmissionEvent =
            this.builder.formData.form.formSubmissionEvent || this.facebook.formSubmissionEvent;
          // Collecting fonts
          if (!this.fontCollection.includes(this.fieldStyle.labelFontFamily)) {
            this.fontCollection.push(this.fieldStyle.labelFontFamily);
          }
          if (!this.fontCollection.includes(this.fieldStyle.placeholderFontFamily)) {
            this.fontCollection.push(this.fieldStyle.placeholderFontFamily);
          }
          if (!this.fontCollection.includes(this.fieldStyle.shortLabel?.fontFamily)) {
            this.fontCollection.push(this.fieldStyle.shortLabel?.fontFamily);
          }
        } else {
          this.builderConfig.fullScreenMode = this.builder.version >= 2;
        }
        return data;
      } catch (err) {
        console.error(err);
        // sendNotification({ title: "Error", description: err.message, type: "error" });
      }
    },
    async getQuiz() {
      const response = await FormServices.getFormById(this.quizId);
      const quizData = response.data;
      this.form = quizData;
      this.builder = quizData?.form;
      this.setLayout(this.builder.formData?.form?.layout);

      this.slides = this.builder?.formData?.slides || [];
      this.logic = this.builder?.formData?.logic || {};
      this.category = this.builder?.formData?.category ?? this.category;
      this.resultTemplate = this.builder?.formData?.resultTemplate || defaultResultTemplate;
      this.parentFolderId = this.builder.formData.parentFolderId || "";
      this.parentFolderName = this.builder.formData.parentFolderName || "";
      //Coping Database Style into store style
      if (this.builder.formData?.form?.style) {
        this.formStyle.fieldSpacing = this.builder.formData.form.style.fieldSpacing || 16;
        this.formStyle.border = this.builder.formData.form.style.border || this.formStyle.border;
        this.formStyle.padding = this.builder.formData.form.style.padding || this.formStyle.padding;
        this.formStyle.shadow = this.builder.formData.form.style.shadow || this.formStyle.shadow;
        this.formStyle.acBranding =
          this.builder.formData.form.style.acBranding || this.formStyle.acBranding;
        this.formStyle.background =
          this.builder.formData.form.style.background || this.formStyle.background;
        this.formStyle.width = this.builder.formData.form.width || this.formStyle.width;
        this.formStyle.height = this.builder.formData.form.height || this.formStyle.height;
        this.formStyle.customStyle =
          this.builder.formData.form.customStyle || this.formStyle.customStyle;
        this.formStyle.bgImage = this.builder.formData.form.style.bgImage || "";
      }
      // Coping Database details into store
      if (this.builder.formData?.form) {
        this.fieldStyle = this.builder.formData.form.fieldStyle || this.fieldStyle;
        if (!this.fieldStyle.activeTagBgColor) {
          this.fieldStyle.activeTagBgColor = DEFAULT_ACTIVE_TAG_BG_COLOR;
        }
        this.fieldStyle.shortLabel =
          this.builder.formData.form.fieldStyle?.shortLabel || defaultShortLabel;
        this.formAction = {
          actionType: this.getActionType(),
          redirectUrl: this.builder.formData.form.formAction?.redirectUrl || "",
          thankyouText:
            this.builder.formData.form.formAction?.thankyouText ||
            `Thank you for taking the time to complete this ${this.quizIdId ? "quiz" : "form"}.`,
          headerImageSrc: this.builder.formData.form.formAction?.headerImageSrc || "",
        };
        //Common Builder Config
        this.builderConfig.formLabelVisible = this.builder.formData.form.formLabelVisible ?? true;
        this.builderConfig.stickyContact = this.builder.formData.form.stickyContact ?? false;
        this.builderConfig.isGDPRCompliant = this.builder.formData.form.isGDPRCompliant || false;
        this.builderConfig.enableTimezone = this.builder.formData.form.enableTimezone ?? true;
        this.builderConfig.conditionalLogic = this.builder.formData.form.conditionalLogic || null;
        this.builderConfig.fullScreenMode = this.builder.formData.form.fullScreenMode || false;
        this.builderConfig.inputStyleType = this.builder.formData.form.inputStyleType || "box";
        this.builderConfig.currentThemeId = this.builder.formData.form.currentThemeId;
        this.builderConfig.emailNotifications = this.builder.formData.emailNotifications ?? false;
        this.builderConfig.autoResponder = this.builder.formData.autoResponder ?? false;
        this.emailNotificationsConfig =
          this.builder.formData.form.emailNotificationsConfig || this.emailNotificationsConfig;
        this.opportunitySettings =
          this.builder.formData.form.opportunitySettings || this.opportunitySettings;
        this.payment = this.builder.formData.form.payment;
        this.autoResponderConfig =
          this.builder.formData.form.autoResponderConfig || this.autoResponderConfig;
        // Only When QUIZ
        console.log(this.quizId, "QUIZ id");
        if (this.quizId) {
          this.footerStyle = this.builder.formData.form.footerStyle || FOOTER_STYLE_QUIZ;
          console.log(this.footerStyle, "Change footer style");
          this.version = this.builder.version;
          this.formAction.disqualifiedText =
            this.builder.formData.form.formAction?.disqualifiedText ||
            "Thank you for taking the time to complete this survey.";
          this.formAction.disqualifiedType =
            this.builder.formData.form?.formAction?.disqualifiedType || 2;
          this.formAction.disqualifiedUrl =
            this.builder.formData.form?.formAction?.disqualifiedUrl || "";
          this.formAction.endsurveyText =
            this.builder.formData.form?.formAction?.endsurveyText ||
            "Thank you for taking the time to complete this survey.";
          this.formAction.endsurveyType =
            this.builder.formData.form?.formAction?.endsurveyType || 2;
          this.formAction.endsurveyUrl = this.builder.formData.form?.formAction?.endsurveyUrl || "";
          this.formAction.fieldPerPage = this.builder.formData.form?.formAction?.fieldPerPage || 1;
          this.formAction.fieldSettingEnable =
            this.builder.formData.form?.formAction?.fieldSettingEnable ?? false;
          this.formAction.footerHtml = this.builder.formData.form?.formAction?.footerHtml || "";
          this.formAction.headerFullWidthEnable =
            this.builder.formData.form?.formAction?.headerFullWidthEnable ?? false;

          //Survey Builder Config
          this.builderConfig.disableAutoNavigation =
            this.builder.formData.form.disableAutoNavigation ?? false;
          this.builderConfig.isBackButtonEnable =
            this.builder.formData.form.isBackButtonEnable ?? false;
          this.builderConfig.isProgressBarEnabled =
            this.builder.formData.form.isProgressBarEnabled ?? true;
          this.builderConfig.isAnimationDisabled =
            this.builder.formData.form.isAnimationDisabled ?? false;
          this.builderConfig.isSurveyScrollEnabled =
            this.builder.formData.form.isSurveyScrollEnabled ?? false;
          this.builderConfig.surveyLogicLinkById =
            this.builder.formData.surveyLogicLinkById ?? false;
          this.builderConfig.newFooter =
            this.builder.version >= 2 ? true : this.builder.formData.newFooter ?? false;
        }

        this.submitMessageStyle =
          this.builder.formData.form.submitMessageStyle || this.submitMessageStyle;
        this.facebook.fbPixelId = this.builder.formData.form.fbPixelId || this.facebook.fbPixelId;
        this.facebook.pageViewEvent =
          this.builder.formData.form.pageViewEvent || this.facebook.pageViewEvent;
        this.facebook.formSubmissionEvent =
          this.builder.formData.form.formSubmissionEvent || this.facebook.formSubmissionEvent;
        // Collecting fonts
        if (!this.fontCollection.includes(this.fieldStyle.labelFontFamily)) {
          this.fontCollection.push(this.fieldStyle.labelFontFamily);
        }
        if (!this.fontCollection.includes(this.fieldStyle.placeholderFontFamily)) {
          this.fontCollection.push(this.fieldStyle.placeholderFontFamily);
        }
        if (!this.fontCollection.includes(this.fieldStyle.shortLabel?.fontFamily)) {
          this.fontCollection.push(this.fieldStyle.shortLabel?.fontFamily);
        }
      }
    },
    getIntegrateInfo() {
      if (this.appHandshake) {
        this.appHandshake.then((parent) => parent.emit("integrateInfo"));
      }
    },
    setIntegrateInfo(integrate) {
      this.integrate.baseUrl = integrate.whilteLabelDomain;
      this.integrate.formLayoutSettings = JSON.parse(integrate.formLayoutSettings);
      this.integrate.isWhiteLabelled = integrate.isWhiteLabelled;
    },
    copyToClipBoard(textToCopy) {
      if (this.appHandshake) {
        this.appHandshake.then((parent) => parent.emit("copyText", textToCopy));
      }
    },
    saveToLocalStorage(key, data) {
      if (this.appHandshake) {
        this.appHandshake.then((parent) => parent.emit("saveLocalStorage", { key, data }));
      }
    },
    handleDropAndMoveSurvey(item, slideIndex, newIndex, oldIndex?) {
      let isAlreadyPresent = false;
      if (oldIndex === undefined) {
        this.slides.forEach((slide) => {
          slide.sildeData.forEach((element) => {
            if (element.tag === item.tag && !this.duplicateAllowed.includes(item.tag)) {
              isAlreadyPresent = true;
            }
          });
        });
      }
      if (!isAlreadyPresent) {
        if (oldIndex === undefined) {
          this.slides[slideIndex].sildeData.splice(newIndex, 0, item);
        }
      }
    },
    async getAllFonts() {
      this.googleFonts = await getAllGoogleFonts();
      this.bunnyFonts = await getAllBunnyFonts();
    },
    async getThemeList() {
      if (this.formId || this.quizId) {
        const { data } = await FormServices.getThemes();
        this.themes = data.themes;
      } else {
        const { data } = await SurveyServices.getThemes();
        this.themes = data.themes;
      }

      this.defaultThemeId = this.themes.filter((theme) => theme.default)[0]?._id;
      this.history.push(this.extractSelectiveFields(this.$state));
      //Apply default theme only if version is 3 and greater and there is no theme yet applied
      if (this.builder.version >= 3 && !this.builderConfig.currentThemeId) {
        this.builderConfig.currentThemeId = this.defaultThemeId;
        await this.setThemesBasedOnBuilder();
        this.history.pop();
        this.history.push(this.extractSelectiveFields(this.$state));
      }
      const fields = this.builder.formData?.form?.fields || [];
      // After theme applied check if its default then auto save
      // if (this.formId && this.builder.version >= 4 && fields.length === 0) {
      //   this.autoSaveFormOrSurvey += 1;
      // }

      this.processing = false;
    },
    async setThemesBasedOnBuilder() {
      if (this.formId || this.quizId) {
        const { data: themeStyle } = await FormServices.getThemeStyles(this.defaultThemeId);
        this.setThemeStyleInBuilder(themeStyle);
      } else {
        const { data: themeStyle } = await SurveyServices.getThemeStyles(this.defaultThemeId);
        this.setThemeStyleInBuilder(themeStyle);
      }
    },
    setThemeStyleInBuilder(themeStyle) {
      if (!themeStyle.fieldStyle.activeTagBgColor) {
        themeStyle.fieldStyle.activeTagBgColor = this.fieldStyle.activeTagBgColor;
      }
      this.fieldStyle = themeStyle.fieldStyle;
      if (!themeStyle.fieldStyle.shortLabel) {
        this.fieldStyle.shortLabel = defaultShortLabel;
      }
      if (!themeStyle.fieldStyle.labelWidth) {
        this.fieldStyle.labelWidth = 200;
      }
      if (!themeStyle.fieldStyle.labelAlignment) {
        this.fieldStyle.labelAlignment = "top";
      }
      this.setLayout(themeStyle.layout);
      this.builderConfig.inputStyleType = themeStyle.inputStyleType;
      this.formStyle.acBranding = themeStyle.style.acBranding;
      this.formStyle.background = themeStyle.style.background;
      this.formStyle.bgImage = themeStyle.style.bgImage;
      this.formStyle.border = themeStyle.style.border;
      this.formStyle.padding = themeStyle.style.padding;
      this.formStyle.shadow = themeStyle.style.shadow;
      this.formStyle.width = themeStyle.width;
      this.submitMessageStyle = themeStyle.submitMessageStyle;
      this.formAction.headerImageSrc = themeStyle.headerImageSrc;
      this.builderConfig.newFooter = this.quizId ? true : themeStyle.newFooter;
      this.builderConfig.formLabelVisible = themeStyle.formLabelVisible;
      if (themeStyle.footerStyle) {
        this.footerStyle = themeStyle.footerStyle;
      }
      if (this.quizId) {
        this.footerStyle = FOOTER_STYLE_QUIZ;
      }
      this.setThemeFont(themeStyle);
      this.setThemeStyleToFields(themeStyle);
    },
    setThemeFont(theme) {
      if (
        theme.fieldStyle.labelFontFamily &&
        !this.fontCollection.includes(theme.fieldStyle.labelFontFamily)
      ) {
        this.fontCollection.push(theme.fieldStyle.labelFontFamily);
      }
      if (
        theme.fieldStyle.shortLabel?.fontFamily &&
        !this.fontCollection.includes(theme.fieldStyle.shortLabel?.fontFamily)
      ) {
        this.fontCollection.push(theme.fieldStyle.shortLabel?.fontFamily);
      }
      if (
        theme.fieldStyle.placeholderFontFamily &&
        !this.fontCollection.includes(theme.fieldStyle.placeholderFontFamily)
      ) {
        this.fontCollection.push(theme.fieldStyle.placeholderFontFamily);
      }
      if (
        theme.submitMessageStyle.fontFamily &&
        !this.fontCollection.includes(theme.submitMessageStyle.fontFamily)
      ) {
        this.fontCollection.push(theme.submitMessageStyle.fontFamily);
      }
    },
    setThemeStyleToFields(theme) {
      if (this.formId) {
        const validFieldsForWidth = this.elements.filter(
          (field) => !fieldFullWidth(field.type, true)
        ).length;
        this.elements.forEach((field) => {
          if (!fieldFullWidth(field.type, true)) {
            field.fieldWidthPercentage = this.getDefaultFieldWidthPercentage(
              field,
              validFieldsForWidth
            );
          }
          if (field.tag === "button" && theme.button) {
            if (field.fontFamily && !this.fontCollection.includes(field.fontFamily)) {
              this.fontCollection.push(field.fontFamily);
            }
            if (field.subTextFontFamily && !this.fontCollection.includes(field.subTextFontFamily)) {
              this.fontCollection.push(field.subTextFontFamily);
            }
            theme.button.bgColor =
              theme.button.bgColor === defaultGreenColor ? defaultBlueColor : theme.button.bgColor;
            Object.assign(field, theme.button);
          }
          if (field.tag === "terms_and_conditions" && theme.termsAndCondition) {
            Object.assign(field, theme.termsAndCondition);
            //Add textColor and linkColor for existing element in forms. To maintain consistency between preview and builder
            field.linkColor = theme.termsAndCondition.linkColor
              ? theme.termsAndCondition.linkColor
              : DEFAULT_LABEL_COLOR;
            field.textColor = theme.termsAndCondition.textColor
              ? theme.termsAndCondition.textColor
              : DEFAULT_TEXT_COLOR;
            field.preview = applyStyles(field.placeholder, field.linkColor, field.textColor);
          }
        });
      } else {
        this.slides.forEach((slide) => {
          slide.slideData.forEach((field) => {
            if (field.tag === "terms_and_conditions" && theme.termsAndCondition) {
              Object.assign(field, theme.termsAndCondition);
              //Add textColor and linkColor for existing element in forms. To maintain consistency between preview and builder
              field.linkColor = theme.termsAndCondition.linkColor
                ? theme.termsAndCondition.linkColor
                : DEFAULT_LABEL_COLOR;
              field.textColor = theme.termsAndCondition.textColor
                ? theme.termsAndCondition.textColor
                : DEFAULT_TEXT_COLOR;
              field.preview = applyStyles(field.placeholder, field.linkColor, field.textColor);
            }
          });
        });
      }
    },
    async getPaymentIntegrationDetails() {
      try {
        const paymentIntegrations = await PaymentServices.integrations(this.locationId);
        if (paymentIntegrations.data) {
          this.paymentIntegrations = paymentIntegrations.data;
        }
      } catch (err) {
        console.error(err);
        this.paymentIntegrations = undefined;
      }

      try {
        const currency = await PaymentServices.getCurrency();
        if (currency.data) {
          const currencyObj = currency.data.currency;
          this.currencyList = currencyObj;
          this.currencyOptions = Object.keys(currencyObj).map((symbol) => {
            return {
              label: symbol,
              value: symbol,
            };
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    defaultFormField() {
      const firstName = { ...standardFields.personal.group[1] };
      firstName.hiddenFieldQueryKey = firstName.tag;
      delete firstName.icon;

      const lastName = { ...standardFields.personal.group[2] };
      lastName.hiddenFieldQueryKey = lastName.tag;
      delete lastName.icon;

      const email = { ...standardFields.personal.group[4] };
      email.hiddenFieldQueryKey = email.tag;
      delete email.icon;

      const phone = { ...standardFields.personal.group[5] };
      phone.hiddenFieldQueryKey = phone.tag;
      delete phone.icon;

      const termsAndCondition = { ...standardFields.customized.group[4] };
      termsAndCondition.hiddenFieldQueryKey = termsAndCondition.tag;
      termsAndCondition.placeholder = termsAndConditionstPhoneLabel;
      termsAndCondition.preview = termsAndConditionstPhoneLabel;
      delete termsAndCondition.icon;

      const submit = { ...standardFields.submit.group[0] };
      submit.hiddenFieldQueryKey = submit.tag;
      delete submit.icon;

      this.elements.push(firstName);
      this.elements.push(lastName);
      this.elements.push(email);
      this.elements.push(phone);
      this.elements.push(termsAndCondition);
      this.elements.push(submit);
      this.elements.push(privacyElement);
      this.anyUnsavedChanges = true;
    },
    async getProductStoredInForms() {
      try {
        this.getProductListAPIProcessed = false;
        const storedProduct = [];
        this.payment?.products?.forEach((product) => {
          if (!Object.prototype.hasOwnProperty.call(product, "showQuantity")) {
            product.showQuantity = true;
          }
          storedProduct.push(product.productId);
        });
        const response = await PaymentServices.getProductList(
          this.locationId,
          "",
          0,
          50,
          storedProduct
        );
        this.getProductListAPIProcessed = true;
        //Map existing product with response details
        this.payment?.products?.forEach((product: Product) => {
          response.data.products.forEach((el) => {
            if (product.productId === el._id) {
              const obj = {
                productId: el._id,
                amount: el.prices[0].amount,
                currency: el.prices[0].currency,
                showDescription: product.showDescription,
                showImage: product.showImage,
                showQuantity: product.showQuantity,
                ...el,
              };
              this.addedProductList.push(obj);
            }
          });
        });
      } catch (error) {
        this.getProductListAPIProcessed = true;
        console.error(error);
      }
    },
    async getProductList() {
      try {
        const response = await PaymentServices.getProductList(this.locationId, "", 0, 10);
        this.totalProduct = response.data.total[0].total;

        //Making product list
        response.data.products.forEach((el) => {
          const obj = {
            productId: el._id,
            amount: el.prices[0].amount,
            currency: el.prices[0].currency,
            showDescription: true,
            showImage: true,
            showQuantity: true,
            ...el,
          };
          this.productList.push({ label: el.name, value: obj });
        });
      } catch (error) {}
    },
    mapFieldWidthPercentage(field, validFieldsForWidth) {
      if (field?.fieldWidthPercentage) {
        return field?.fieldWidthPercentage;
      } else if (
        this.builderConfig.layout === CONSTANTS.TWO_COLUMN &&
        !fieldFullWidth(field?.type)
      ) {
        return 50;
      } else if (this.builderConfig.layout === CONSTANTS.SINGLE_LINE) {
        return Math.max(parseFloat((100 / validFieldsForWidth).toFixed(2)), 20);
      }
      return 100;
    },
    getDefaultFieldWidthPercentage(field, validFieldsForWidth) {
      if (this.builderConfig.layout === CONSTANTS.TWO_COLUMN && !fieldFullWidth(field?.type)) {
        return 50;
      } else if (this.builderConfig.layout === CONSTANTS.SINGLE_LINE) {
        return Math.max(parseFloat((100 / validFieldsForWidth).toFixed(2)), 20);
      }
      return 100;
    },
    getActionType() {
      if (this.quizId) {
        return this.builder.formData.form.formAction?.actionType
          ? this.builder.formData.form.formAction?.actionType
          : "4";
      }
      //For forms and survey
      return !isNaN(this.builder.formData.form.formAction?.actionType)
        ? this.builder.formData.form.formAction?.actionType || "2"
        : "2";
    },
  },
  getters: {
    getFormInformation(state) {
      return state.builder;
    },
    getFormAction(state) {
      return state.formAction;
    },
    getFormSubmitMessageStyle(state) {
      return state.submitMessageStyle;
    },
    getFacebookInformation(state) {
      return state.facebook;
    },
    getCurrentElement(state) {
      return (index) =>
        state.elements.find((element, i) => {
          return i === index;
        });
    },
    getFieldStyle(state) {
      return state.fieldStyle;
    },
    getCustomFieldsFromStore(state) {
      return state.customFields;
    },
    getCustomFieldFoldersFromStore(state) {
      return state.customFieldFolders;
    },
    getPipelinesFromStore(state) {
      return state.pipelines;
    },
    getSlideDropDownMenu(state) {
      return (currentIndex) => {
        const slideDropDown: any = [{ value: "", label: "Next Slide (Default)" }];
        const total = state.slides.length;
        state.slides.forEach((slide, index) => {
          if (index !== currentIndex) {
            slideDropDown.push({
              value: slide.id,
              label: `${slide.slideName} (${index + 1}/${total})`,
            });
          }
        });
        return slideDropDown;
      };
    },
    getQuizQuestionDropdown(state) {
      return (currentElementUuid) => {
        const questions = [{ value: "", label: "Next Question" }];
        state.slides.forEach((slide, index) => {
          slide.slideData.forEach((question) => {
            if (
              !DUPLICATE_ALLOWED_FIELDS.includes(question.tag) &&
              currentElementUuid !== question.uuid
            ) {
              questions.push({ value: question.uuid, label: question.label });
            }
          });
        });
        return questions;
      };
    },
    getSlideIndexAndElementIndex(state) {
      return (selectedElement) => {
        const slideIndexAndElementIndex = { slideIndex: null, elementIndex: null };
        if (selectedElement.slideId !== undefined) {
          state.slides.forEach((slide, slideIndex) => {
            if (slide.id === selectedElement.slideId) {
              slideIndexAndElementIndex.slideIndex = slideIndex;
              slideIndexAndElementIndex.elementIndex = slide.slideData.findIndex(
                (element) => element.uuid === selectedElement.elementId
              );
            }
          });
        } else {
          slideIndexAndElementIndex.slideIndex = state.slides.findIndex(
            (slide) => slide.id === selectedElement.slideId
          );
        }
        return slideIndexAndElementIndex;
      };
    },
  },
});

export interface AppStore {
  history: Array<any>;
  historyIndex: Number;
  showVersions: Boolean;
  form: Object | undefined;
  product: string;
  userId: string;
  parentFolderId: string;
  parentFolderName: string;
  company: Company;
  email: string;
  apiKey: string;
  tokenId: string;
  locationId: string;
  formId: string;
  surveyId: string;
  quizId: string;
  slides: Array<any>;
  anyUnsavedChanges: boolean;
  enableSaveButton: boolean;
  showOpportunitiesModal: boolean;
  appHandshake: Postmate.Model | undefined;
  requestRetryCount: number;
  processing: boolean;
  builder: Builder | any;
  elements: Array<any>;
  customFields: Array<any>;
  customFieldFolders: Array<any>;
  pipelines: Array<any>;
  formAction: FormAction;
  builderConfig: BuilderConfig;
  submitMessageStyle: SubmitMessageStyle;
  currentActiveIndex: Array<any>;
  currentSlideIndex: Number | undefined;
  facebook: Facebook;
  formStyle: FormStyle | any;
  fieldStyle: FieldStyle | any;
  footerStyle: FooterStyle | any;
  payment: Payment;
  emailNotificationsConfig: EmailNotificationsConfig | any;
  autoResponderConfig: AutoResponderConfig | any;
  opportunitySettings: any;
  activeElement: Object | undefined;
  currentCustomSidebarPreview: Number | undefined;
  sidebarOverlaySettings: SidebarOverlaySettings | undefined;
  isAnyElementActive: Boolean;
  duplicateAllowed: Array<any>;
  previewDomain: string;
  standardFields: Array<any>;
  integrate: Integrate;
  tempSlides: Array<any>;
  lastStatusOfRightDrawer: Boolean;
  fontCollection: Array<any>;
  googleFonts: Array<any>;
  bunnyFonts: Array<any>;
  themes: Array<any>;
  defaultThemeId: string | undefined;
  version: Number;
  paymentIntegrations: Object;
  currencyList: Array<any>;
  currencyOptions: Array<any>;
  autoSaveFormOrSurvey: Number;
  isPaymentEnabled: Boolean;
  productList: any;
  addedProductList: Array<any>;
  totalProduct: number;
  getProductListAPIProcessed: boolean;
  isOpportunitiesEnabled: Boolean;
  locationCountry: String;
  isAdvanceSettingEnable: Boolean;
  quizSettings: QuizSettings;
  logic: any;
  category: Array<{ label: string; value: string; id: string }>;
  resultTemplate: any;
  addressSettings: AddressSettings;
}

interface AddressSettings {
  autoCompleteEnabled: boolean;
  required: boolean;
  children: GroupField[];
}

interface SidebarOverlaySettings {
  index?: number | undefined;
  slideIndex?: number | undefined;
  formIndex?: number | undefined;
  elementId?: string;
  slideId?: string;
}
interface BuilderConfig {
  disableAutoNavigation: boolean;
  formLabelVisible: boolean;
  isAnimationDisabled: boolean;
  isBackButtonEnable: boolean;
  isProgressBarEnabled: boolean;
  isSurveyScrollEnabled: boolean;
  stickyContact: boolean;
  surveyLogicLinkById: true;
  newFooter: boolean;
  enablePartialContactCreation: boolean;
  emailNotifications: boolean;
  autoResponder: boolean;
  inlineForm: boolean;
  isGDPRCompliant: boolean;
  enableTimezone: boolean;
  conditionalLogic: Step[];
  fullScreenMode: boolean;
  inputStyleType: string;
  currentThemeId: string | undefined;
  layout: number;
}

interface Condition {
  selectedField: string;
  selectedOperation: string;
  inputValue: string;
}

interface Outcome {
  type: string;
  value: string;
  disqualifyAction?: string;
  hideType?: string;
}

interface Step {
  conditions: Condition[];
  outcome: Outcome;
  conditionalOperation: string;
}

interface Integrate {
  formLayoutSettings: Object;
  baseUrl: String;
  isWhiteLabelled: boolean;
}
interface Company {
  id: string;
  name: string;
  logoURL: string;
  domain: string;
}
interface Border {
  border: number;
  radius: number;
  color: string;
  style: string;
}
interface Padding {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

interface Shadow {
  horizontal: number;
  vertical: number;
  blur: number;
  spread: number;
  color: string;
}
interface FormStyle {
  fieldSpacing: Number;
  acBranding?: boolean;
  background?: string;
  bgImage?: String;
  border: Border;
  padding: Padding;
  shadow: Shadow;
  width?: number;
  customStye: String;
}

interface FieldStyle {
  activeTagBgColor: string;
  labelColor: string;
  labelFontFamily: string;
  labelFontSize: number;
  labelFontWeight: number;
  textColor: string;
  textFontFamily: string;
  textFontSize: number;
  textFontWeight: number;
  shortLabel: ShortLabel;
  labelAlignment: "left" | "right" | "top";
  labelWidth: number;
}

interface ShortLabel {
  color: String;
  fontFamily: String;
  fontSize: Number;
  fontWeight: Number;
}
interface EmailNotificationsConfig {
  subject: string;
  emailTo: string[];
  senderName: string;
}

interface AutoResponderConfig {
  subject: string;
  replyTo: string[];
  senderName: string;
}

interface FooterStyle {
  theme: string;
  backgroundFill: string;
  fontFamily: string;
  fontSize: number;
  fontWeight: number;
  height: number;
  enableProgressBar: boolean;
  stickyFooter: boolean;
  progressBarStyle: {
    textColor: string;
    borderColor: string;
    borderWidth: number;
    borderRadius: number;
    completeFillColor: string;
    inactiveFillColor: string;
  };
  buttonStyle: {
    buttonType: string;
    position: string;
    fontColor: string;
    nextButtonBgColor: string;
    backButtonBgColor: string;
    submitButtonBgColor: string;
    prevBtnText: string;
    nextBtnText: string;
    submitBtnText: string;
  };
  computedStyles: Object;
}

interface FormAction {
  actionType: string;
  redirectUrl: string;
  thankyouText: string;
  disqualifiedText?: string;
  disqualifiedType?: number;
  disqualifiedUrl?: string;
  endsurveyText?: string;
  endsurveyType?: number;
  endsurveyUrl?: string;
  fieldPerPage?: number;
  fieldSettingEnable?: boolean;
  footerHtml?: string;
  headerFullWidthEnable?: boolean;
  headerImageSrc?: string;
}

interface SubmitMessageStyle {
  bgColor: string;
  color: string;
  fontFamily: string;
  fontSize: number;
  fontWeight: number;
}

interface Facebook {
  fbPixelId: string;
  formSubmissionEvent: string;
  pageViewEvent: string;
}

interface Builder {
  dateAdded: string;
  dateUpdated: string;
  deleted: boolean;
  formData: object;
  locationId: string;
  name: string;
  updatedBy: string;
  _id: string;
}

interface Payment {
  liveModeOn: boolean;
  suggestionOn: boolean;
  paymentType: string;
  products: Array<Product>;
  layout: number;
  showInfoAboutPayment: boolean;
  suggestedAmount: Array<Number>;
  amountPlaceHolder: string;
  enableOtherAmount: boolean;
  required: boolean;
  currency: Currency;
  tagActiveBGColor: string;
  footer: {
    message: string;
    hidden: boolean;
  };
  coupon: Coupon;
  selectionType: string;
}

interface Currency {
  code: string;
  symbol: string;
}

interface Coupon {
  enableCoupon: boolean;
  couponAlignment: string;
}

export interface Product {
  productId: string;
  showDescription: boolean;
  showImage: boolean;
  showQuantity: boolean;
  details?: any;
}

interface QuizSettings {
  resultTemplateId: string;
}
