<template>
  <div class="my-4 flex w-full items-center justify-between">
    <div class="my-auto text-sm font-normal text-gray-700">
      {{ t("common.createContactOnPartialSubmission") }}
    </div>
    <UISwitch
      v-model:value="store.builderConfig.enablePartialContactCreation"
      :disabled="isPaymentElementIsAdded"
      size="small"
      @update:value="addToHistory"
    />
  </div>
  <UIAlert id="info-message">
    <template #title>{{ t("common.howItWorks") }} &#129300;</template>
    <template #content>{{ t("common.createContactOnPartialSubmissionDescription") }}</template>
  </UIAlert>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UISwitch, UIAlert } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useAppStore();

const addToHistory = () => {
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const isPaymentElementIsAdded = computed(() => {
  let paymentExists = false;
  store.slides.forEach((slide) =>
    slide.slideData.forEach((field) => {
      if (field.type === "payment") {
        return (paymentExists = true);
      }
    })
  );
  return paymentExists;
});
</script>
