<script setup lang="ts">
import { FormServices } from "@/service/FormServices";
import { SurveyServices } from "@/service/SurveyServices";
import { useAppStore } from "@/store/app";
import { UIButton } from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import AlertModal from "./AlertModal.vue";
const { t } = useI18n();

const store = useAppStore();

const props = defineProps({
  isSurvey: {
    type: Boolean,
    default: false,
  },
});
const openAlertModal = ref(false);
const isThemeAlertCheckboxChecked = ref(false);
const currentThemeId = ref(store.builderConfig.currentThemeId);
const processingThemeId = ref(store.builderConfig.currentThemeId);
const removeTheme = async () => {
  await setTheme(store.defaultThemeId);
};

const setTheme = async (themeId) => {
  const themeChangeEnabled = JSON.parse(localStorage.getItem("theme_change_enabled"));
  openAlertModal.value = !themeChangeEnabled;
  processingThemeId.value = themeId;
  if (themeChangeEnabled) {
    await applyThemes(themeId);
    currentThemeId.value = themeId;
  }
};

const applyThemes = async (themeId) => {
  if (!props.isSurvey) {
    const { data: themeStyle } = await FormServices.getThemeStyles(themeId);
    store.setThemeStyleInBuilder(themeStyle);
  } else {
    const { data: themeStyle } = await SurveyServices.getThemeStyles(themeId);
    store.setThemeStyleInBuilder(themeStyle);
  }
  currentThemeId.value = themeId;
  store.builderConfig.currentThemeId = themeId;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const popModalChange = () => {
  openAlertModal.value = false;
};

const feedback = async (modalName: string, result: boolean) => {
  if (modalName === "THEMES" && result) {
    localStorage.setItem("theme_change_enabled", isThemeAlertCheckboxChecked.value.toString());
    await applyThemes(processingThemeId.value);
  }
  openAlertModal.value = false;
};
const updateCheckBox = (isChecked: boolean) => {
  isThemeAlertCheckboxChecked.value = isChecked;
};

watch(
  () => store.builderConfig.currentThemeId,
  (newVal) => {
    currentThemeId.value = newVal;
  }
);
</script>

<template>
  <section class="themes-container">
    <section id="themes" class="grid grid-cols-2 gap-x-2 gap-y-2 p-2">
      <div
        v-for="theme in store.themes"
        :key="theme._id"
        :class="theme._id === currentThemeId ? 'active' : 'inactive'"
        class="flex items-center justify-center rounded"
      >
        <div
          class="theme-wrapper group p-px"
          :class="theme._id === currentThemeId ? 'currentThemeCardOverlay' : ''"
        >
          <div
            class="theme flex h-[115px] w-[162px] items-center justify-center rounded-t-md align-middle"
            :style="{ backgroundImage: 'url(' + theme.thumbnail + ')' }"
          >
            <div>
              <UIButton
                v-if="theme._id !== currentThemeId"
                id="button-hover"
                class="hidden h-6 text-[10px] transition-opacity duration-150 group-hover:block"
                type="primary"
                size="small"
                @click="setTheme(theme._id)"
                >{{ t("common.themes.useTheme") }}</UIButton
              >
              <UIButton
                v-if="theme._id === currentThemeId && !theme.default"
                id="button-hover"
                class="hidden h-6 text-[10px] transition-opacity duration-150 group-hover:block"
                type="error"
                size="small"
                @click="removeTheme()"
                >{{ t("common.themes.removeTheme") }}</UIButton
              >
            </div>
          </div>
          <div class="flex h-8 items-center justify-center">
            <p class="text-center text-xs">{{ theme.name }}</p>
          </div>
        </div>
      </div>
    </section>
  </section>
  <AlertModal
    v-if="openAlertModal"
    :title="t('common.alert')"
    :description="t('common.themes.alertThemeMessage')"
    modal-name="THEMES"
    :show-modal-popup="openAlertModal"
    :positive-text="t('common.proceed')"
    :negative-text="t('common.cancel')"
    header-type="warning"
    footer-type="primary"
    :is-checkbox-enabled="true"
    :hide-modal="isThemeAlertCheckboxChecked"
    @pop:change="popModalChange"
    @pop:positive="feedback"
    @pop:negative="feedback"
    @pop:check="updateCheckBox"
  ></AlertModal>
</template>

<style>
.themes-container {
  height: 79vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.themes-container::-webkit-scrollbar {
  display: none;
}

.theme {
  background-size: cover;
}

.theme-card {
  margin-bottom: 20px;
  border: 1px solid grey;
}

.currentThemeCardLayout {
  border: 1px solid #09f !important;
}

.currentThemeCardOverlay {
  background: rgba(0, 158, 244, 0.15);
}

.active {
  outline: 1px solid #ff9900;
}

.inactive {
  border: 1px solid #dee2e6;
}
</style>
