{
  "common": {
    "save": "Spara",
    "saving": "Sparar",
    "integrate": "Integrera",
    "preview": "Förhandsgranska",
    "back": "Tillbaka",
    "alert": "Avisering",
    "proceed": "Fortsätt",
    "cancel": "Avbryt",
    "delete": "Radera",
    "embed": "Inbäddad",
    "link": "Länk",
    "iframeEmbed": "Iframe-inbäddning",
    "styles": "Stilar",
    "options": "Alternativ",
    "quickAdd": "Snabbtillägg",
    "customFields": "Anpassade fält",
    "personalInfo": "Personuppgifter",
    "contactInfo": "Kontaktuppgifter",
    "submit": "Skicka",
    "address": "Adress",
    "customized": "Anpassad",
    "layout": "Layout",
    "showLabel": "Visa etikett",
    "background": "Bakgrund",
    "border": "Kantlinje",
    "borderType": "Kantlinjetyp",
    "cornerRadius": "Hörnradie",
    "width": "Bredd",
    "padding": "Utfyllnad",
    "top": "Toppen",
    "bottom": "Botten",
    "left": "Vänster",
    "right": "Höger",
    "shadow": "Skugga",
    "color": "Färg",
    "horizontal": "Horisontell",
    "vertical": "Vertikal",
    "blur": "Oskärpa",
    "spread": "Storlek",
    "fieldStyle": "Fältstil",
    "fontColor": "Typsnittsfärg",
    "label": "Etikett",
    "fontFamily": "Typsnittsfamilj",
    "size": "Storlek",
    "weight": "Tjocklek",
    "placeholder": "Platshållare",
    "customCSS": "Anpassad CSS",
    "backgroundImage": "Bakgrundsbild",
    "headerImage": "Sidhuvudbild",
    "miscellaneous": "Övrigt",
    "agencyBranding": "Byråns varumärke",
    "imageUploadMessage": "Klicka på eller dra en bild till det här området för att ladda upp",
    "backgroundImageTooltipMessage": "Bakgrundsbilden är avsedd att täcka hela sidan och ger en visuellt tilltalande bakgrund för formuläret. För att se bakgrundsbilden klickar du på på alternativet Förhandsgranska.",
    "headerImageTooltipMessage": "Sidhuvudbilden är utformad för att täcka hela formulärets bredd och placeras högst upp i formuläret. För att se sidhuvudbilden klickar du alternativet Förhandsgranska.",
    "onSubmit": "Vid inlämning",
    "facebookPixelID": "Facebook Pixel-ID",
    "facebookPixelIDMessage": "Ignorera det här fältet om du planerar att använda formuläret/undersökningen i en tratt",
    "facebookPixelEvents": "Facebook Pixel-händelser",
    "onpageView": "Vid sidvisning",
    "stickyContact": "Fäst kontakt",
    "stickyContactMessage": "Fäst kontakt fyller automatiskt i kontaktinformation som tidigare angivits så att du inte behöver ange den två gånger.",
    "fieldDescription": "Fältbeskrivning",
    "fieldTitle": "Fälttitel",
    "queryKey": "Frågenyckel",
    "querykeyTooltipMessage": "Frågenyckeln som kan användas som URL-parameter för att fylla i det här fältet",
    "required": "Krävs",
    "hidden": "Dold",
    "hiddenValue": "Dolt värde",
    "enableCountryPicker": "Aktivera landsväljare",
    "altText": "Alt-text",
    "alignment": "Orientering",
    "imageWidth": "Bildbredd",
    "imageHeight": "Bildhöjd",
    "logic": "Logik",
    "pleaseInput": "Ange",
    "editHTML": "Redigera HTML",
    "textStyle": "Textformat",
    "subText": "Undertext",
    "text": "Text",
    "buttonStyle": "Knappstil",
    "fullWidth": "Full bredd",
    "htmlMessage": "Anpassad HTML anges här",
    "htmlEditorHeader": "ANPASSAD JAVASCRIPT / HTML",
    "htmlEditorBodyMessage": "Lägg till din kod här",
    "yesSave": "Ja, spara",
    "cancelKeep": "Avbryt, behåll",
    "done": "Klar",
    "addElement": "Lägg till element",
    "addUrlLike": "Lägg till giltiga URL:er som",
    "doNotAddRelativePath": "Lägg inte till relativa sökvägar som",
    "or": "eller",
    "addCustomFields": "Lägg till anpassade fält",
    "customFieldSearch": "Sök efter namn",
    "enterSourceValue": "Ange källvärde",
    "shortLabel": "Kort etikett",
    "customCSSTooltip": "Anpassad CSS har företräde framför formulärformatering och teman och kan ha en inverkan på temats stil.",
    "fontFamilyTooltip": "Byggverktyget stöder alla typer av typsnitt om du inte kan se det i listan. Sök i rullgardinsmenyn",
    "useGDPRCompliantFont": "Använd GDPR-kompatibelt typsnitt",
    "enableTimezone": "Aktivera tidszon",
    "inputStyle": "Inmatningsstil",
    "conditionalLogicAlertOnSubmit": "Villkorlig logik kommer att ha företräde framför meddelandet/omdirigeringen här",
    "conditionalLogicAlertOnShowHide": "Villkorlig logik kommer att ha företräde framför de dolda inställningarna här",
    "placeholderFacebookPixelIdInput": "Ange Facebook Pixel-ID",
    "doNotShowMessage": "Visa inte igen",
    "themes": {
      "themes": "Teman",
      "alertThemeMessage": "Om du byter tema kommer du att förlora alla ändringar av den aktuella stilen.",
      "useTheme": "Använd tema",
      "removeTheme": "Ta bort tema"
    },
    "box": "Ruta",
    "line": "Rad",
    "message": "Meddelande",
    "openUrl": "Öppna URL",
    "borderTypeList": {
      "dotted": "Prickad",
      "dashed": "Streckad",
      "solid": "Helfärgad",
      "none": "Ingen"
    },
    "button": "Knapp",
    "emailNotification": "E-postavisering",
    "emailNotificationSubheader": "Få ett e-postmeddelande när någon fyller i ditt formulär",
    "subject": "Ämne",
    "emailTo": "E-post (till)",
    "senderName": "Avsändarens namn",
    "senderNameWarning": "Ditt (underkontonamn) läggs till om det lämnas tomt",
    "emailError": "Minst en e-postadress krävs",
    "emailTooltip": "Den första e-postadressen är avsedd för fältet ”Till”. Eventuella efterföljande e-postadresser kommer att inkluderas i fältet ”CC”.",
    "clear": "Rensa",
    "showNotificationsSettings": "Visa aviseringsinställningar",
    "emailServicePaid": "Den här funktionen är en betald tjänst och kommer att medföra avgifter baserat på din användning.",
    "integration": "Integration",
    "payment": {
      "payments": "Betalningar",
      "connected": "Ansluten",
      "connect": "Anslut",
      "liveModeOn": "Live-läge",
      "status": "Status",
      "test": "Test",
      "live": "Live",
      "paymentFields": "Betalningsfält",
      "suggestAnAmount": "Föreslå ett belopp",
      "selectCurrency": "Välj valuta",
      "tagActiveBGColor": "Färg på aktiv tagg",
      "includeOtherAmount": "Inkludera annat belopp",
      "footerInfo": "Sidfotsinfo",
      "paymentType": "Betalningstyp",
      "sellProducts": "Sälj produkter",
      "customAmount": "Anpassat belopp",
      "collectPayment": "Ta ut betalning",
      "generalSettings": "Allmänna fält",
      "product": {
        "addProduct": "Lägg till produkt",
        "productSettings": "Produktinställningar",
        "selectProduct": "Välj produkt",
        "editThisProduct": "För att redigera produkten",
        "clickHere": "Klicka här",
        "show": "Visa",
        "description": "Beskrivning",
        "image": "Bild",
        "layoutSettings": "Layoutinställningar",
        "singleColumn": "En kolumn",
        "twoColumn": "Två kolumner",
        "threeColumn": "Tre kolumner",
        "maxProductError": "Max 20 produkter är tillåtna i ett formulär",
        "productSelectPlaceholder": "Välj en produkt",
        "orderConfirmation": "Beställningsbekräftelse",
        "orderConfirmationWarning": "En beställningsbekräftelsesida kommer att visas när betalningen har genomförts",
        "quantity": "Antal",
        "createProduct": "Så här skapar du en produkt"
      },
      "coupons": {
        "coupons": "Kuponger",
        "enableCoupon": "Aktivera kupong",
        "couponAlignment": "Kupongjustering",
        "left": "Vänster",
        "right": "Höger",
        "fullWidth": "Full bredd",
        "tooltipMessage": "Ställ in positionen för kuponginmatning från typerna nedan"
      }
    },
    "addressSettings": {
      "autoComplete": "Adress fylls i automatiskt",
      "addressfields": "Adressfält",
      "placeholderSearch": "Sök efter en adress",
      "autoCompleteTooltip": "Gör det möjligt söka efter och automatiskt fylla i adress. Funktionen är avgiftsbelagd."
    },
    "autoResponder": "Autosvar",
    "autoResponderSubheader": "Skicka ett automatiskt e-postmeddelande till personen som skickar in formuläret (e-postmeddelandet innehåller en kopia av den information som de angav i formuläret)",
    "replyToEmail": "Svarsmejl",
    "open": "Öppen",
    "won": "Vunnen",
    "lost": "Förlorad",
    "abandon": "Överge",
    "selectAPipeline": "Välj en pipeline",
    "stage": "Stadium",
    "status": "Status",
    "opportunityValue": "Möjlighetens värde",
    "createNewPipeline": "Skapa ny pipeline",
    "editOpportunitySettings": "Redigera möjlighetsinställningar",
    "contact": "Kontakt",
    "opportunity": "Möjlighet",
    "add": "Lägg till",
    "more": "Mer",
    "opportunityHeading": "Var vill du att din möjlighet ska uppdateras?",
    "date": {
      "dateSettings": "Datuminställningar",
      "dateFormat": "Datumformat",
      "dateSeparator": "Datumavdelare",
      "dateFormatTooltipMessage": "Välj ett datumformat. D står för dag, M för månader och Y för år",
      "dateSeparatorTooltipMessage": "Välj en avdelare som separerar dag, månad och år i det format som valts i Datumformat",
      "disablePicker": "Inaktivera väljare"
    },
    "restoreVersion": "Återställ version",
    "versionHistory": "Versionshistorik",
    "errorMessage": "Fel, försök igen",
    "version": "Version:",
    "currentVersion": "Nuvarande version",
    "messageStyling": "Meddelandeformat",
    "validateEmailHeader": "Validera e-post",
    "validateEmailDescription": "Knappen blir synlig när användaren anger sin e-postadress. Den här funktionen är utformad för att hjälpa dig att generera potentiella kunder av hög kvalitet.",
    "validatePhoneHeader": "Validera telefon",
    "validatePhoneDescription": "Knappen blir synlig när användaren anger sitt telefonnummer. Den här funktionen är utformad för att hjälpa dig att generera potentiella kunder av hög kvalitet.",
    "validatePhoneWarning": "För att aktivera SMS OTP-verifiering behöver du se till att ditt telefonnummer är inställt i platsinställningarna",
    "style": {
      "formLayoutType": "Formulärtyp",
      "oneColumn": "En kolumn",
      "twoColumn": "Två kolumner",
      "singleLine": "En rad",
      "fieldWidth": "Fältbredd",
      "labelAlignment": {
        "labelAlignment": "Etikettjustering",
        "labelWidth": "Etikettbredd"
      },
      "fieldSpacing": "Fältavstånd",
      "color": "färg",
      "fontColor": "Typsnittsfärg",
      "inputBackground": "Inmatningsbakgrund",
      "layout": "Layout",
      "colorsAndBackground": "Färger och bakgrund",
      "advanceSettings": "Avancerade inställningar",
      "inputField": "Inmatningsfält",
      "borderWidth": "Kantlinjebredd",
      "borderColor": "Kantlinjefärg",
      "borderStyle": "Kantlinjestil",
      "fontSize": "Teckenstorlek",
      "fontWeight": "Typsnittstjocklek",
      "labelColor": "Etikettfärg",
      "placeholderColor": "Platshållarfärg",
      "shortLabelColor": "Färg på kort etikett"
    },
    "replyToTooltip": "Anpassa standardsvarsmejlen genom att använda {'{'}{'{'}contact.email{'}'}{'}'} för att aktivera svar till kontakten som skickar formuläret.",
    "content": "Innehåll",
    "calculations": "Beräkningar",
    "uploadOptions": "Uppladdningsalternativ",
    "opportunitySettingsWarning": "Ange möjlighetsinställningar genom att klicka på knappen Redigera möjlighetsinställningar.",
    "question": "Fråga",
    "createContactOnPartialSubmission": "Skapa kontakt vid partiell inlämning",
    "howItWorks": "Hur fungerar det?",
    "createContactOnPartialSubmissionDescription": "Slutför bilden och klicka på ”Nästa”. En ny kontakt skapas och lagras automatiskt.",
    "swapWarningMessageSurvey": "Om alternativ redigeras eller byts ut påverkas det anpassade fältet på alla platser där fältet används. Det kan också återställa villkorlig logik. ",
    "swapWarningMessageForm": "Om alternativ redigeras eller byts ut påverkas det anpassade fältet på alla platser där fältet används. ",
    "optionLabel": "Alternativetikett",
    "calculationValue": "Beräkningsvärde",
    "addOption": "Lägg till alternativ",
    "enableCalculations": "Aktivera beräkningar",
    "productSelectionSubtext": "Tillåt ett eller flera produktval",
    "multi": "Mång",
    "single": "Enda",
    "productSelectionHeading": "Produktval"
  },
  "survey": {
    "typeOfBuilder": "Undersökning",
    "survyeStyle": "Undersökningsstil",
    "surveyName": "Undersökningsnamn",
    "surveyElement": "Undersökningselement",
    "addSurveyElement": "Lägg till undersökningselement",
    "unsavedAlertMessage": "Undersökningen har arbete som inte har sparats. Är du säker på att du vill lämna och kasta allt osparat arbete?",
    "thirdPartyScriptAlertMessage": "Tredjepartsskript inuti HTML-blocket kan ge effektiva funktioner men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. Därför rekommenderar vi att du granskar innehållet i det här skriptet innan du sparar det i undersökningen.",
    "integrateHeader": "Integrera undersökning",
    "integrateSubHeader": "Använd följande alternativ för att integrera din anpassade undersökning på en webbplats",
    "integrateCopyLinkMessage": "Kopiera länken nedan och dela den enkelt var du vill.",
    "onSurveySubmission": "Vid undersökningsinlämning",
    "surveySettings": "Undersökningsinställningar",
    "oneQuestionAtTime": "En fråga i taget",
    "backButton": "Tillbaka-knapp",
    "backButtonTooltipMessage": "Tillbaka-knappen hjälper användaren att gå till föregående bild",
    "disableAutoNavigation": "Inaktivera automatisk navigering",
    "disableAutoNavigationTooltipMessage": "Om den är aktiverad går undersökningen inte automatiskt vidare till nästa bild",
    "progressBar": "Förloppsfält",
    "progressBarTooltipMessage": "Om du stänger av detta tas förloppsfältet bort från undersökningen",
    "disableAnimation": "Inaktivera animation",
    "disableAnimationTooltipMessage": "Om du aktiverar det här inaktiveras animationen för bildövergångar",
    "scrollToTop": "Skrolla till toppen",
    "scrollToTopTooltipMessage": "Om det är aktiverat skrollas undersökningen till toppen på nästa bild",
    "footerHTML": "HTML för sidfot",
    "footerHTMLMessage": "Du kan skriva anpassad CSS (med hjälp av stiltaggar) och HTML i samma ruta",
    "editFooter": "Redigera sidfot",
    "disqualifyImmediately": "Diskvalificera omedelbart",
    "disqualifyAfterSubmit": "Diskvalificera efter inlämning",
    "slideConflictAlertMessage": "Den här bilden innehåller flera frågor med inställd logik. Detta kan orsaka oavsiktligt beteende",
    "addSlide": "Lägg till bild",
    "settings": "Inställningar",
    "delete": "Radera",
    "slideDeleteAlertMessage": "Är du säker på att du vill ta bort den här bilden?",
    "slideDeleteBetweenAlertMessage": "Detta kommer att omorganisera de andra bilderna. Är du säker på att du vill ta bort den här bilden?",
    "slideName": "Bildens namn",
    "slidePosition": "Bildens placering",
    "jumpTo": "Hoppa till",
    "enableTimezoneSurvey": "Om du aktiverar det här alternativet registreras information om tidszon för den person som skickar in undersökningen",
    "footer": {
      "footer": "Sidfot",
      "enableNewFooter": "Aktivera ny sidfot",
      "stickToSurvey": " Fäst till undersökning",
      "backgroundFill": "Bakgrundsfyllnad",
      "footerHeight": "Sidfotshöjd",
      "enableProgressBar": "Aktivera förloppsindikator",
      "buttons": "Knappar",
      "buttonType": "Knapptyp",
      "fillNext": "Fyll (nästa)",
      "fillPrevious": "Fyll (föregående)",
      "fillSubmit": "Fyll i (skicka)",
      "textColor": "Knapptext",
      "position": "Position",
      "previousText": "Föregående (text)",
      "nextText": "Nästa (text)",
      "submitText": "Skicka (text)",
      "fillComplete": "Fyllning vid komplett",
      "fillInactive": "Fyllning vid inaktiv",
      "footerType": "Sidfotstyp",
      "stickToCard": "Håll dig till kortet",
      "stickToPage": "Håll dig till sidan"
    },
    "surveyNotfication": "Undersökningsavisering",
    "style": {
      "surveyPadding": "Undersökningsutfyllnad"
    },
    "autoResponderSubheader": "Skicka ett automatiskt e-postmeddelande till personen som skickar in undersökningen (e-postmeddelandet innehåller en kopia av den information som de angav i undersökningen)"
  },
  "form": {
    "typeOfBuilder": "Formulär",
    "formStyle": "Formulärstil",
    "formElement": "Formulärelement",
    "addFormElement": "Lägg till formulärelement",
    "addConditionalLogic": "Lägg till villkorlig logik",
    "inlineForm": "Infogat formulär",
    "formName": "Formulärnamn",
    "fullScreenMode": "Fullskärmsläge",
    "fullScreenModetooltip": "Om du aktiverar det kommer formuläret att expanderas över hela behållarens bredd och sträcka sig till kanten av inbäddningsområdet samtidigt som det följer formulärets bredd.",
    "enableTimezoneForm": "Om du aktiverar det här alternativet registreras information om tidszon för den person som skickar in formuläret",
    "formSettings": "Formulärinställningar",
    "unsavedAlertMessage": "Formuläret har arbete som inte har sparats. Är du säker på att du vill lämna och kasta allt osparat arbete?",
    "thirdPartyScriptAlertMessage": "Tredjepartsskript inuti HTML-blocket kan ge effektiva funktioner men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. Därför rekommenderar vi att du granskar innehållet i det här skriptet innan du sparar det i formuläret.",
    "onFormSubmission": "Vid formulärinlämning",
    "integrateForm": "Integrera formulär",
    "embedlayoutType": "Typ av inbäddningslayout",
    "embedlayoutTypeSubMessage": "Välj en layout för att bädda in i en tratt eller extern webbplats",
    "triggerType": "Utlösartyp",
    "showOnScrolling": "Visa vid skrollning",
    "showAfter": "Visa efter",
    "seconds": "sekunder",
    "alwaysShow": "Visa alltid",
    "activationOptions": "Aktiveringsalternativ",
    "activateOn": "Aktivera vid",
    "visit": "besök",
    "alwaysActivated": "Alltid aktiverad",
    "deactivationOptions": "Inaktiveringsalternativ",
    "deactivateAfterShowing": "Inaktivera efter visning",
    "times": "gånger",
    "deactiveOnceLeadCollected": "Inaktivera när potentiell kund har registrerats",
    "neverDeactivate": "Inaktivera aldrig",
    "copyEmbedCode": "Kopiera inbäddningskod",
    "copyFormLink": "Kopiera formulärlänk",
    "openFormLink": "Öppna formulärlänk",
    "inline": "Infogat",
    "inlineDescription": "Ett infogat formulär visas tillsammans med innehållet på sidan som ett inbyggt element. Den ligger inte ovanpå sidan och kan inte stängas eller minimeras.",
    "inlineHoverDescription": "Ett infogat formulär visas tillsammans med innehållet på sidan som ett inbyggt element. Den ligger inte ovanpå sidan och kan inte stängas eller minimeras.",
    "popup": "Popup",
    "popupDescription": "Ett popup-fönster visar formulär som ett överlägg inuti ett fönster. Det kan inte minimeras utan måste stängas.",
    "popupHoverDescription": "Ett popup-fönster visar formulär som ett överlägg inuti ett fönster. Det kan inte minimeras utan måste stängas.",
    "politeSlideIn": "Artigt insegel",
    "politeSlideInDescription": "Ett artigt insegel visas längst ned på skärmen och försvinner när det stängs",
    "politeSlideInHoverDescription": "Visas på skärmens undersida och försvinner när den stängs",
    "stickySidebar": "Fäst sidofält",
    "stickySidebarDescription": "Ett fäst sidofält visas på sidan av skärmen och kan minimeras till en flik som fästs vid sidan av fönstret",
    "stickySidebarHoverDescription": "Visas på sidan av skärmen och kan minimeras till en flik som fästs vid sidan av fönstret",
    "allowMinimize": "Tillåt minimering",
    "conditonalLogic": {
      "conditions": "Villkor",
      "noExistingCondition": "Inga befintliga villkor än",
      "freeRoamMessage": "Du kan ströva fritt utan reglerna – för tillfället i alla fall",
      "addNewCondition": "Lägg till nytt villkor",
      "redirectToUrl": "Omdirigera till URL",
      "displayCustomMessage": "Visa anpassat meddelande",
      "disqualifyLead": "Diskvalificera potentiell kund",
      "showHideFields": "Visa/dölj fält",
      "openBelowUrl": "Öppna nedanstående URL",
      "showCustomMessage": "Visa anpassat meddelande",
      "alertConditionDelete": "Är du säker på att du vill ta bort det här villkoret?",
      "deleteCondition": "Ta bort villkor",
      "conditionExecutedMessage": "Villkoren kommer att exekveras uppifrån och ned.",
      "allOperationOptions": {
        "isEqualTo": "Är lika med",
        "isNotEqualTo": "Är inte lika med",
        "contains": "Innehåller",
        "startsWith": "Börjar med",
        "endsWith": "Slutar med",
        "isEmpty": "Är tom",
        "isFilled": "Är fylld",
        "before": "Före",
        "after": "Efter",
        "greaterThan": "Större än",
        "lessThan": "Mindre än"
      },
      "logic": {
        "if": "Om",
        "then": "Gör därefter",
        "and": "Och",
        "or": "Eller"
      },
      "phoneAlertMessage": "För att söka efter telefonnummer med landskod måste du inkludera symbolen ”+” i din sökfråga.",
      "deleteAlertMessage": "Är du säker på att du vill ta bort det här fältet? Villkoret för fältet kommer också att tas bort.",
      "deleteField": "Ta bort fält"
    },
    "minimizedTitlePlaceholder": "Minimerad titel",
    "dragYourElementFromLeft": "Dra dina element hit från vänster",
    "formNotification": "Formuläravisering",
    "style": {
      "formPadding": "Formutfyllnad"
    }
  },
  "quiz": {
    "quizElement": "Quizelement",
    "quizNotification": "Quizavisering",
    "viewAndCustomise": "Visa och anpassa",
    "categoryAndScore": "Kategori och poäng",
    "provideFeedback": "Ge feedback",
    "addQuizElement": "Lägg till quizelement",
    "typeOfBuilder": "Quiz",
    "catgory": {
      "manageCategories": "Hantera kategorier"
    },
    "quizSettings": "Quizinställningar",
    "onQuizSubmission": "Vid quizinlämning",
    "settings": "Inställningar",
    "deletePage": "Ta bort sida",
    "quizResult": {
      "resultTemplate": "Resultatmall",
      "quizResultAlertMessage": "Vald resultatsida kommer att visas när quizet är klart"
    },
    "autoResponderSubheader": "Skicka ett automatiskt e-postmeddelande till personen som skickar in quizet (e-postmeddelandet innehåller en kopia av den information som de angav i quizet)",
    "integrateHeader": "Integrera quiz",
    "integrateSubHeader": "Använd följande alternativ för att integrera ditt anpassade quiz på en webbplats",
    "integrateCopyLinkMessage": "Kopiera länken nedan och dela den enkelt var du vill.",
    "pageName": "Sidnamn",
    "pagePosition": "Sidposition"
  }
}