{
  "common": {
    "save": "Sauvegarder",
    "saving": "Sauvegarde en cours",
    "integrate": "Intégrer",
    "preview": "Aperçu",
    "back": "Retour",
    "alert": "Alerte",
    "proceed": "Poursuivre",
    "cancel": "Annuler",
    "delete": "Supprimer",
    "embed": "Intégrer",
    "link": "Lien",
    "iframeEmbed": "Intégration Iframe",
    "styles": "Styles",
    "options": "Options",
    "quickAdd": "Ajout rapide",
    "customFields": "Champs personnalisés",
    "personalInfo": "Information personnelle",
    "contactInfo": "Coordonnées",
    "submit": "Soumettre",
    "address": "Adresse",
    "customized": "Sur mesure",
    "layout": "Mise en page",
    "showLabel": "Afficher l’étiquette",
    "background": "Arrière-plan",
    "border": "Bordure",
    "borderType": "Type de bordure",
    "cornerRadius": "Rayon de l'angle",
    "width": "Largeur",
    "padding": "Remplissage",
    "top": "Haut",
    "bottom": "Bas",
    "left": "Gauche",
    "right": "Droite",
    "shadow": "Ombre",
    "color": "Couleur",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Flou",
    "spread": "Diffusion",
    "fieldStyle": "Style de champ",
    "fontColor": "Couleur de la police",
    "label": "Étiquette",
    "fontFamily": "Famille de polices",
    "size": "Taille",
    "weight": "Poids",
    "placeholder": "Espace réservé",
    "customCSS": "CSS personnalisé",
    "backgroundImage": "Image d’arrière-plan",
    "headerImage": "Image d'en-tête",
    "miscellaneous": "Divers",
    "agencyBranding": "Image de marque de l’agence",
    "imageUploadMessage": "Cliquez ou faites glisser un fichier vers cette zone pour le téléverser.",
    "backgroundImageTooltipMessage": "L'image d'arrière-plan est destinée à couvrir toute la page, offrant ainsi une toile de fond visuellement attrayante pour le formulaire. Pour afficher l’image d’arrière-plan, cliquez sur l’option Aperçu.",
    "headerImageTooltipMessage": "L’image d’en-tête est conçue pour couvrir toute la largeur du formulaire et reste positionnée en haut du formulaire. Pour afficher l'image d'en-tête, cliquez sur l'option Aperçu.",
    "onSubmit": "Lors de la soumission",
    "facebookPixelID": "Identifiant de pixel Facebook",
    "facebookPixelIDMessage": "Ignorez ce champ si vous prévoyez d'utiliser ce formulaire/cette enquête dans un entonnoir.",
    "facebookPixelEvents": "Événements du pixel Facebook",
    "onpageView": "Affichage sur la page",
    "stickyContact": "Contact collant",
    "stickyContactMessage": "Le contact collant remplira automatiquement les informations de contact précédemment saisies afin que vous n'ayez pas à les saisir deux fois.",
    "fieldDescription": "Description du champ",
    "fieldTitle": "Titre du champ",
    "queryKey": "Clé de requête",
    "querykeyTooltipMessage": "La clé de requête peut être utilisée comme paramètre URL pour remplir ce champ.",
    "required": "Obligatoire",
    "hidden": "Caché",
    "hiddenValue": "Valeur cachée",
    "enableCountryPicker": "Activer le sélecteur de pays",
    "altText": "Texte de remplacement",
    "alignment": "Alignement",
    "imageWidth": "Largeur de l’image",
    "imageHeight": "Hauteur de l'image",
    "logic": "Logique",
    "pleaseInput": "Veuillez saisir",
    "editHTML": "Modifier l'HTML",
    "textStyle": "Style du texte",
    "subText": "Sous-texte",
    "text": "Texte",
    "buttonStyle": "Style du bouton",
    "fullWidth": "Pleine largeur",
    "htmlMessage": "Placez le code HTML personnalisé ici.",
    "htmlEditorHeader": "JAVASCRIPT / HTML PERSONNALISÉ",
    "htmlEditorBodyMessage": "Ajoutez votre code ici.",
    "yesSave": "Oui, sauvegarder",
    "cancelKeep": "Annuler, conserver",
    "done": "Terminé",
    "addElement": "Ajouter un élément",
    "addUrlLike": "Ajoutez des URL valides comme",
    "doNotAddRelativePath": "N'ajoutez pas de chemins relatifs comme",
    "or": "ou",
    "addCustomFields": "Ajouter des champs personnalisés",
    "customFieldSearch": "Recherche par nom",
    "enterSourceValue": "Saisis la valeur source",
    "shortLabel": "Étiquette courte",
    "customCSSTooltip": "La feuille de style personnalisée a préséance sur le style de forme et les thèmes et peut avoir un impact sur le style du thème.",
    "fontFamilyTooltip": "Le générateur prend en charge tous les types de polices si vous ne les voyez pas dans la liste. Veuillez la rechercher dans le menu déroulant.",
    "useGDPRCompliantFont": "Utilisez une police conforme au RGPD.",
    "enableTimezone": "Activer le fuseau horaire",
    "inputStyle": "Style d’entrée",
    "conditionalLogicAlertOnSubmit": "La logique conditionnelle aura préséance sur le message / rediriger ici.",
    "conditionalLogicAlertOnShowHide": "La logique conditionnelle aura préséance sur les paramètres cachés ici.",
    "placeholderFacebookPixelIdInput": "Saisir l'identifiant de pixel Facebook",
    "doNotShowMessage": "Ne plus afficher",
    "themes": {
      "themes": "Thèmes",
      "alertThemeMessage": "Le changement de thème entraîne la perte de toutes les modifications de style en cours.",
      "useTheme": "Utiliser le thème",
      "removeTheme": "Supprimer le thème"
    },
    "box": "Case",
    "line": "Ligne",
    "message": "Message",
    "openUrl": "Ouvrir l'URL",
    "borderTypeList": {
      "dotted": "Pointillés",
      "dashed": "Tiretés",
      "solid": "Ligne continue",
      "none": "Aucun"
    },
    "button": "Bouton",
    "emailNotification": "Notification par courriel",
    "emailNotificationSubheader": "Recevoir un courriel lorsque quelqu'un remplit votre formulaire",
    "subject": "Objet",
    "emailTo": "Courriel (À)",
    "senderName": "Nom de l’expéditeur",
    "senderNameWarning": "Votre (nom de sous-compte) sera ajouté si ce champ est laissé vide.",
    "emailError": "Au moins une adresse courriel est obligatoire",
    "emailTooltip": "La première adresse courriel est désignée pour le champ « À ». Toutes les adresses courriel ultérieures seront incluses dans le champ « Cc ».",
    "clear": "Effacer",
    "showNotificationsSettings": "Afficher les paramètres des notifications",
    "emailServicePaid": "Cette fonctionnalité est un service payant et entraînera des frais en fonction de votre utilisation.",
    "integration": "Intégration",
    "payment": {
      "payments": "Paiements",
      "connected": "Connecté",
      "connect": "Se connecter",
      "liveModeOn": "Mode en direct",
      "status": "Statut",
      "test": "Essai",
      "live": "En direct",
      "paymentFields": "Champs de paiement",
      "suggestAnAmount": "Proposer un montant",
      "selectCurrency": "Sélectionner la devise",
      "tagActiveBGColor": "Couleur de l'étiquette active",
      "includeOtherAmount": "Inclure l'autre montant",
      "footerInfo": "Informations de pied de page",
      "paymentType": "Type de paiement",
      "sellProducts": "Vendre des produits",
      "customAmount": "Montant personnalisé",
      "collectPayment": "Percevoir le paiement",
      "generalSettings": "Champs généraux",
      "product": {
        "addProduct": "Ajouter un produit",
        "productSettings": "Paramètres du produit",
        "selectProduct": "Sélectionner un produit",
        "editThisProduct": "Pour modifier ce produit",
        "clickHere": "Cliquez ici",
        "show": "Afficher",
        "description": "Description",
        "image": "Image",
        "layoutSettings": "Paramètres de mise en page",
        "singleColumn": "Colonne unique",
        "twoColumn": "Deux colonnes",
        "threeColumn": "Trois colonnes",
        "maxProductError": "Nombre maximal de 20 produits autorisés dans un formulaire",
        "productSelectPlaceholder": "Veuillez sélectionner un produit.",
        "orderConfirmation": "Confirmation de commande",
        "orderConfirmationWarning": "Une page de confirmation de commande s'affichera une fois le paiement effectué.",
        "quantity": "Quantité",
        "createProduct": "Pour créer un produit"
      },
      "coupons": {
        "coupons": "Coupons",
        "enableCoupon": "Activer le coupon",
        "couponAlignment": "Alignement des coupons",
        "left": "Gauche",
        "right": "Droite",
        "fullWidth": "Pleine largeur",
        "tooltipMessage": "Définir la position de l'entrée du coupon à partir des types ci-dessous"
      }
    },
    "addressSettings": {
      "autoComplete": "Remplissage automatique de l'adresse",
      "addressfields": "Champs d'adresse",
      "placeholderSearch": "Rechercher une adresse",
      "autoCompleteTooltip": "Permet de rechercher et de renseigner automatiquement l'adresse. Cette fonctionnalité est facturable."
    },
    "autoResponder": "Répondeur automatique",
    "autoResponderSubheader": "Envoyez un courriel automatique à la personne qui soumet le formulaire (le courriel comprend une copie des renseignements saisis dans le formulaire).",
    "replyToEmail": "Réponse au courriel",
    "open": "Ouvert",
    "won": "Réussi",
    "lost": "Perdu",
    "abandon": "Abandonner",
    "selectAPipeline": "Sélectionner un pipeline",
    "stage": "Étape",
    "status": "Statut",
    "opportunityValue": "Valeur de l’opportunité",
    "createNewPipeline": "Créer un nouveau pipeline",
    "editOpportunitySettings": "Modifier les paramètres de l'opportunité",
    "contact": "Contact",
    "opportunity": "Opportunité",
    "add": "Ajouter",
    "more": "Plus",
    "opportunityHeading": "Où voulez-vous que votre opportunité soit mise à jour?",
    "date": {
      "dateSettings": "Paramètres de date",
      "dateFormat": "Format de la date",
      "dateSeparator": "Séparateur de date",
      "dateFormatTooltipMessage": "Sélectionnez un format de date. D représente le jour, M les mois et Y l'année",
      "dateSeparatorTooltipMessage": "Sélectionnez un séparateur qui séparera le jour, le mois et l'année dans le format sélectionné dans Format de la date.",
      "disablePicker": "Désactiver le sélecteur"
    },
    "restoreVersion": "Restaurer la version",
    "versionHistory": "Historique de la version",
    "errorMessage": "Erreur, réessayez",
    "version": "Version :",
    "currentVersion": "Version actuelle",
    "messageStyling": "Style du message",
    "validateEmailHeader": "Valider le courriel",
    "validateEmailDescription": "Le bouton sera visible une fois que l'utilisateur aura saisi son courriel. Cette fonctionnalité est conçue pour vous aider à générer des prospects de qualité.",
    "validatePhoneHeader": "Valider le numéro de téléphone",
    "validatePhoneDescription": "Le bouton sera visible une fois que l'utilisateur aura saisi son numéro de téléphone. Cette fonctionnalité est conçue pour vous aider à générer des prospects de qualité.",
    "validatePhoneWarning": "Pour activer la vérification par mot de passe unique reçu par SMS, assurez-vous que votre numéro de téléphone est configuré dans tes paramètres de localisation.",
    "style": {
      "formLayoutType": "Type de formulaire",
      "oneColumn": "Une colonne",
      "twoColumn": "Deux colonnes",
      "singleLine": "Ligne unique",
      "fieldWidth": "Largeur du champ",
      "labelAlignment": {
        "labelAlignment": "Alignement des étiquettes",
        "labelWidth": "Largeur de l'étiquette"
      },
      "fieldSpacing": "Espacement des champs",
      "color": "couleur",
      "fontColor": "Couleur de la police",
      "inputBackground": "Arrière-plan de l'entrée",
      "layout": "Mise en page",
      "colorsAndBackground": "Couleurs et arrière-plan",
      "advanceSettings": "Paramètres avancés",
      "inputField": "Champ de saisie",
      "borderWidth": "Largeur de la bordure",
      "borderColor": "Couleur de bordure",
      "borderStyle": "Style de bordure",
      "fontSize": "Taille de la police",
      "fontWeight": "Poids de la police",
      "labelColor": "Couleur de l’étiquette",
      "placeholderColor": "Couleur de l'espace réservé",
      "shortLabelColor": "Couleur de l’étiquette courte"
    },
    "replyToTooltip": "Personnalisez l'adresse courriel de réponse par défaut en utilisant {'{'}{'{'}contact.email{'}'}{'}'} pour permettre de répondre au contact qui soumet le formulaire.",
    "content": "Contenu",
    "calculations": "Calculs",
    "uploadOptions": "Options de téléversement",
    "opportunitySettingsWarning": "Veuillez configurer les paramètres d’opportunité en cliquant sur le bouton Modifier les paramètres d’opportunité.",
    "question": "Question",
    "createContactOnPartialSubmission": "Créer un contact lors de la soumission partielle",
    "howItWorks": "Comment ça fonctionne?",
    "createContactOnPartialSubmissionDescription": "Terminez la diapositive et cliquez sur « Suivant ». Un nouveau contact sera automatiquement créé et enregistré.",
    "swapWarningMessageSurvey": "La modification ou le changement d'options aura un impact sur ce champ personnalisé partout où le champ est utilisé. Cela peut également réinitialiser la logique conditionnelle. ",
    "swapWarningMessageForm": "La modification ou le changement d'options aura un impact sur ce champ personnalisé partout où le champ est utilisé. ",
    "optionLabel": "Étiquette d'option",
    "calculationValue": "Valeur de calcul",
    "addOption": "Ajouter une option",
    "enableCalculations": "Activer les calculs",
    "productSelectionSubtext": "Autoriser la sélection d'un ou de plusieurs produits",
    "multi": "Multi",
    "single": "Célibataire",
    "productSelectionHeading": "Sélection de produits"
  },
  "survey": {
    "typeOfBuilder": "Enquête",
    "survyeStyle": "Style d'enquête",
    "surveyName": "Nom de l'enquête",
    "surveyElement": "Élément de l'enquête",
    "addSurveyElement": "Ajouter un élément d'enquête",
    "unsavedAlertMessage": "L'enquête comporte un travail non enregistré. Voulez-vous vraiment quitter et perdre tout travail non enregistré?",
    "thirdPartyScriptAlertMessage": "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Par conséquent, nous vous recommandons de vérifier le contenu de ce script avant de l'enregistrer dans l'enquête.",
    "integrateHeader": "Intégrer le sondage",
    "integrateSubHeader": "Utilisez les options suivantes pour intégrer votre enquête personnalisée dans votre site Web.",
    "integrateCopyLinkMessage": "Copiez le lien ci-dessous et partagez-la facilement à l'endroit souhaité.",
    "onSurveySubmission": "Lors de la soumission de l'enquête",
    "surveySettings": "Paramètres de l'enquête",
    "oneQuestionAtTime": "Une question à la fois",
    "backButton": "Bouton Retour",
    "backButtonTooltipMessage": "Le bouton Retour aidera l'utilisateur à revenir à la diapositive précédente.",
    "disableAutoNavigation": "Désactiver la navigation automatique",
    "disableAutoNavigationTooltipMessage": "Si cette option est activée, l'enquête ne passera pas automatiquement à la diapositive suivante.",
    "progressBar": "Barre de progression",
    "progressBarTooltipMessage": "Si vous désactivez cette étape, la barre de progression sera supprimée de l'enquête.",
    "disableAnimation": "Désactiver l'animation",
    "disableAnimationTooltipMessage": "Si vous activez cette option, l'animation de transition entre diapositives sera désactivée.",
    "scrollToTop": "Faire défiler jusqu'en haut",
    "scrollToTopTooltipMessage": "Si vous activez cette fonction, l'enquête défilera vers le haut de la diapositive suivante.",
    "footerHTML": "HTML de pied de page",
    "footerHTMLMessage": "Vous pouvez écrire des feuilles de style personnalisées (en utilisant la balise de style) et du HTML dans la même case.",
    "editFooter": "Modifier le pied de page",
    "disqualifyImmediately": "Disqualifier immédiatement",
    "disqualifyAfterSubmit": "Disqualifier après soumission",
    "slideConflictAlertMessage": "Cette diapositive contient plusieurs questions qui ont une logique définie. Cela peut entraîner des comportements non intentionnels.",
    "addSlide": "Ajouter une diapositive",
    "settings": "Paramètres",
    "delete": "Supprimer",
    "slideDeleteAlertMessage": "Voulez-vous vraiment supprimer cette diapositive?",
    "slideDeleteBetweenAlertMessage": "Cela réorganisera les autres diapositives. Voulez-vous vraiment supprimer cette diapositive?",
    "slideName": "Nom de la diapositive",
    "slidePosition": "Position de la diapositive",
    "jumpTo": "Aller à",
    "enableTimezoneSurvey": "Si vous activez cette option, l'information de fuseau horaire de la personne qui soumet l'enquête sera capturée.",
    "footer": {
      "footer": "Pied de page",
      "enableNewFooter": "Activer le nouveau pied de page",
      "stickToSurvey": " Joindre à l'enquête",
      "backgroundFill": "Remplissage d’arrière-plan",
      "footerHeight": "Hauteur du pied de page",
      "enableProgressBar": "Activer la barre de progression",
      "buttons": "Boutons",
      "buttonType": "Type de bouton",
      "fillNext": "Remplir (suivant)",
      "fillPrevious": "Remplir (précédent)",
      "fillSubmit": "Remplissage (Soumettre)",
      "textColor": "Texte du bouton",
      "position": "Position",
      "previousText": "Précédent (texte)",
      "nextText": "Suivant (texte)",
      "submitText": "Soumettre (texte)",
      "fillComplete": "Remplissage terminé",
      "fillInactive": "Remplissage inactif",
      "footerType": "Type de pied de page",
      "stickToCard": "Joindre à la carte",
      "stickToPage": "Joindre à la page"
    },
    "surveyNotfication": "Notification d’enquête",
    "style": {
      "surveyPadding": "Remplissage de l'enquête"
    },
    "autoResponderSubheader": "Envoyez un courriel automatisé à la personne qui soumet l'enquête (le courriel comprend une copie des renseignements saisis dans l'enquête)."
  },
  "form": {
    "typeOfBuilder": "Formulaire",
    "formStyle": "Style de formulaire",
    "formElement": "Élément de formulaire",
    "addFormElement": "Ajouter un élément de formulaire",
    "addConditionalLogic": "Ajouter une logique conditionnelle",
    "inlineForm": "Formulaire en ligne",
    "formName": "Nom du formulaire",
    "fullScreenMode": "Mode plein écran",
    "fullScreenModetooltip": "Si vous activez cette fonction, le formulaire s'étendra sur toute la largeur de son conteneur, s'étirant jusqu'au bord de la zone d'intégration tout en adhérant à la largeur du formulaire.",
    "enableTimezoneForm": "Si vous activez cette option, l'information de fuseau horaire de la personne qui soumet le formulaire sera capturée.",
    "formSettings": "Paramètres du formulaire",
    "unsavedAlertMessage": "Le formulaire comporte un travail non enregistré. Voulez-vous vraiment quitter et perdre tout travail non enregistré?",
    "thirdPartyScriptAlertMessage": "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Par conséquent, nous vous recommandons de vérifier le contenu de ce script avant de l'enregistrer dans le formulaire.",
    "onFormSubmission": "Lors de la soumission du formulaire",
    "integrateForm": "Intégrer le formulaire",
    "embedlayoutType": "Intégrer le type de mise en page",
    "embedlayoutTypeSubMessage": "Sélectionnez une mise en page à intégrer dans un entonnoir ou un site Web externe.",
    "triggerType": "Type de déclencheur",
    "showOnScrolling": "Afficher lors du défilement",
    "showAfter": "Afficher après",
    "seconds": "secondes",
    "alwaysShow": "Toujours afficher",
    "activationOptions": "Options d'activation",
    "activateOn": "Activer lors de",
    "visit": "visite",
    "alwaysActivated": "Toujours activé",
    "deactivationOptions": "Options de désactivation",
    "deactivateAfterShowing": "Désactiver après affichage",
    "times": "fois",
    "deactiveOnceLeadCollected": "Désactiver une fois le client potentiel collecté",
    "neverDeactivate": "Ne jamais désactiver",
    "copyEmbedCode": "Copier le code d'intégration",
    "copyFormLink": "Copier le lien du formulaire",
    "openFormLink": "Ouvrir le lien du formulaire",
    "inline": "Intégré",
    "inlineDescription": "Un formulaire intégré apparaît avec le contenu de la page en tant qu'élément d'origine. Il ne se superpose pas et ne peut pas être fermé ou réduit au minimum.",
    "inlineHoverDescription": "Un formulaire intégré apparaît avec le contenu de la page en tant qu'élément d'origine. Il ne se superpose pas et ne peut pas être fermé ou réduit au minimum.",
    "popup": "Fenêtre contextuelle",
    "popupDescription": "Une fenêtre contextuelle affiche le formulaire sous la forme d'une superposition à l'intérieur d'une case lumineuse. Cette dernière ne peut pas être réduite, mais peut être fermée.",
    "popupHoverDescription": "Une fenêtre contextuelle affiche le formulaire sous la forme d'une superposition à l'intérieur d'une case lumineuse. Cette dernière ne peut pas être réduite, mais peut être fermée.",
    "politeSlideIn": "Formulaire coulissant",
    "politeSlideInDescription": "Un formulaire coulissant apparaît en bas de l'écran et disparaît une fois fermé.",
    "politeSlideInHoverDescription": "Apparaît en bas de l'écran et disparaît une fois fermé.",
    "stickySidebar": "Barre latérale épinglée",
    "stickySidebarDescription": "Une barre latérale épinglée apparaît sur le côté de l'écran et peut être réduite à un onglet épinglé sur le côté de la fenêtre.",
    "stickySidebarHoverDescription": "Apparaît sur le côté de l'écran et peut être réduite à un onglet épinglé sur le côté de la fenêtre.",
    "allowMinimize": "Permettre de réduire",
    "conditonalLogic": {
      "conditions": "Conditions",
      "noExistingCondition": "Aucune condition existante pour le moment",
      "freeRoamMessage": "Vous êtes libre de vous déplacer sans règles, du moins pour l'instant.",
      "addNewCondition": "Ajouter une nouvelle condition",
      "redirectToUrl": "Rediriger vers une URL",
      "displayCustomMessage": "Afficher un message personnalisé",
      "disqualifyLead": "Disqualifier le prospect",
      "showHideFields": "Afficher/Masquer les champs",
      "openBelowUrl": "Ouvrir l'URL ci-dessous",
      "showCustomMessage": "Afficher un message personnalisé",
      "alertConditionDelete": "Voulez-vous vraiment supprimer cette condition?",
      "deleteCondition": "Supprimer la condition",
      "conditionExecutedMessage": "Les conditions seront exécutées de haut en bas.",
      "allOperationOptions": {
        "isEqualTo": "Est égal à",
        "isNotEqualTo": "N'est pas égal à",
        "contains": "Contient",
        "startsWith": "Commence par",
        "endsWith": "Se termine par",
        "isEmpty": "Est vide",
        "isFilled": "Est rempli",
        "before": "Avant",
        "after": "Après",
        "greaterThan": "Supérieur à",
        "lessThan": "Inférieur à"
      },
      "logic": {
        "if": "Si",
        "then": "Puis",
        "and": "Et",
        "or": "Ou"
      },
      "phoneAlertMessage": "Pour rechercher des numéros de téléphone avec un code de pays, vous devez inclure explicitement le symbole « + » dans votre requête de recherche.",
      "deleteAlertMessage": "Voulez-vous vraiment supprimer ce champ? La condition associée à ce champ sera également supprimée.",
      "deleteField": "Supprimer le champ"
    },
    "minimizedTitlePlaceholder": "Titre réduit",
    "dragYourElementFromLeft": "Faites glisser vos éléments ici à partir de la gauche.",
    "formNotification": "Notification de formulaire",
    "style": {
      "formPadding": "Remplissage de formulaire"
    }
  },
  "quiz": {
    "quizElement": "Élément de quiz",
    "quizNotification": "Notification de questionnaire",
    "viewAndCustomise": "Voir et personnaliser",
    "categoryAndScore": "Catégorie et score",
    "provideFeedback": "Fournir des commentaires",
    "addQuizElement": "Ajouter un élément de quiz",
    "typeOfBuilder": "Questionnaire",
    "catgory": {
      "manageCategories": "Gérer les catégories"
    },
    "quizSettings": "Paramètres du quiz",
    "onQuizSubmission": "Lors de la soumission du questionnaire",
    "settings": "Paramètres",
    "deletePage": "Supprimer la page",
    "quizResult": {
      "resultTemplate": "Modèle de résultat",
      "quizResultAlertMessage": "La page de résultats choisie s'affichera une fois le questionnaire terminé."
    },
    "autoResponderSubheader": "Envoyez un courriel automatisé à la personne qui soumet le questionnaire (le courriel comprend une copie des renseignements qu’elle a saisis dans le questionnaire).",
    "integrateHeader": "Intégrer le quiz",
    "integrateSubHeader": "Utilisez les options suivantes pour intégrer votre questionnaire personnalisé dans votre site Web.",
    "integrateCopyLinkMessage": "Copiez le lien ci-dessous et partagez-le facilement à l'endroit souhaité.",
    "pageName": "Nom de la page",
    "pagePosition": "Position de la page"
  }
}