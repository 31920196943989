{
  "common": {
    "save": "Opslaan",
    "saving": "Opslaan",
    "integrate": "Integreren",
    "preview": "Voorbeeldweergave",
    "back": "Terug",
    "alert": "Melding",
    "proceed": "Doorgaan",
    "cancel": "Annuleren",
    "delete": "Verwijderen",
    "embed": "Insluiten",
    "link": "Link",
    "iframeEmbed": "Iframe insluiten",
    "styles": "Stijlen",
    "options": "Opties",
    "quickAdd": "Snel toevoegen",
    "customFields": "Aangepaste velden",
    "personalInfo": "Persoonlijke gegevens",
    "contactInfo": "Contactgegevens",
    "submit": "Verzenden",
    "address": "Adres",
    "customized": "Aangepast",
    "layout": "Lay-out",
    "showLabel": "Toon label",
    "background": "Achtergrond",
    "border": "Rand",
    "borderType": "Randtype",
    "cornerRadius": "Hoekstraal",
    "width": "Breedte",
    "padding": "Opvulling",
    "top": "Boven",
    "bottom": "Onder",
    "left": "Links",
    "right": "Rechts",
    "shadow": "Schaduw",
    "color": "Kleur",
    "horizontal": "Horizontaal",
    "vertical": "Verticaal",
    "blur": "Onscherp",
    "spread": "Verspreiden",
    "fieldStyle": "Veldstijl",
    "fontColor": "Kleur lettertype",
    "label": "Label",
    "fontFamily": "Lettertypefamilie",
    "size": "Formaat",
    "weight": "Dikte",
    "placeholder": "Plaatshouder",
    "customCSS": "Aangepaste CSS",
    "backgroundImage": "Achtergrondafbeelding",
    "headerImage": "Kopafbeelding",
    "miscellaneous": "Diversen",
    "agencyBranding": "Branding van het agentschap",
    "imageUploadMessage": "Klik of sleep een afbeeldingsbestand naar dit gebied om te uploaden",
    "backgroundImageTooltipMessage": "De achtergrondafbeelding is bedoeld om de hele pagina te bedekken, om het formulier te voorzien van een visueel aantrekkelijke achtergrond. Klik op de optie Voorbeeld om de achtergrondafbeelding te bekijken.",
    "headerImageTooltipMessage": "De headerafbeelding is ontworpen om de volledige breedte van het formulier te bedekken en blijft bovenaan het formulier staan. Klik op de optie Voorbeeld om de headerafbeelding te bekijken.",
    "onSubmit": "Bij verzenden",
    "facebookPixelID": "Facebook Pixel-ID",
    "facebookPixelIDMessage": "Negeer dit veld als je dit formulier/deze enquête in een trechter wilt gebruiken",
    "facebookPixelEvents": "Gebeurtenissen Meta-pixel",
    "onpageView": "Op paginaweergave",
    "stickyContact": "Automatisch aangevuld contact",
    "stickyContactMessage": "Voor een automatisch aangevuld contact worden eerder ingevoerde contactgegevens automatisch aangevuld, zodat je ze geen twee keer hoeft in te voeren.",
    "fieldDescription": "Beschrijving veld",
    "fieldTitle": "Titel veld",
    "queryKey": "Query-ID",
    "querykeyTooltipMessage": "De querysleutel die als URL-parameter kan worden gebruikt om dit veld aan te vullen",
    "required": "Vereist",
    "hidden": "Verborgen",
    "hiddenValue": "Verborgen waarde",
    "enableCountryPicker": "Landkeuzelijst inschakelen",
    "altText": "Alternatieve tekst",
    "alignment": "Uitlijning",
    "imageWidth": "Breedte afbeelding",
    "imageHeight": "Afbeeldingshoogte",
    "logic": "Logica",
    "pleaseInput": "Invoeren",
    "editHTML": "HTML bewerken",
    "textStyle": "Tekstopmaak",
    "subText": "Subtekst",
    "text": "Tekst",
    "buttonStyle": "Knopstijl",
    "fullWidth": "Volledige breedte",
    "htmlMessage": "De aangepaste HTML komt hier te staan",
    "htmlEditorHeader": "AANGEPAST JAVASCRIPT / HTML",
    "htmlEditorBodyMessage": "Voeg hier je code toe",
    "yesSave": "Ja, opslaan",
    "cancelKeep": "Annuleren, Behouden",
    "done": "Voltooid",
    "addElement": "Element toevoegen",
    "addUrlLike": "Voeg geldige url's toe zoals",
    "doNotAddRelativePath": "Voeg geen relatieve paden toe zoals",
    "or": "of",
    "addCustomFields": "Aangepaste velden toevoegen",
    "customFieldSearch": "Zoeken op naam",
    "enterSourceValue": "Voer bronwaarde in",
    "shortLabel": "Kort label",
    "customCSSTooltip": "Aangepaste CSS heeft voorrang op formulieropmaak en thema's en kan een impact hebben op de vormgeving van het thema.",
    "fontFamilyTooltip": "Builder ondersteunt alle soorten lettertypes als je deze niet in de lijst kunt zien. Zoek in de vervolgkeuzelijst",
    "useGDPRCompliantFont": "Gebruik AVG-compatibel lettertype",
    "enableTimezone": "Tijdzone inschakelen",
    "inputStyle": "Invoerstijl",
    "conditionalLogicAlertOnSubmit": "Voorwaardelijke logica heeft hier voorrang op het bericht of de doorverwijzing",
    "conditionalLogicAlertOnShowHide": "Voorwaardelijke logica heeft hier voorrang op de verborgen instellingen",
    "placeholderFacebookPixelIdInput": "Voer de pixel-ID van Meta in",
    "doNotShowMessage": "Niet opnieuw tonen",
    "themes": {
      "themes": "Thema's",
      "alertThemeMessage": "Door het thema te wijzigen, gaan alle huidige vormgevingsaanpassingen verloren.",
      "useTheme": "Gebruik thema",
      "removeTheme": "Thema verwijderen"
    },
    "box": "Box",
    "line": "Lijn",
    "message": "Bericht",
    "openUrl": "URL openen",
    "borderTypeList": {
      "dotted": "Gestippeld",
      "dashed": "Onderbroken",
      "solid": "Ononderbroken",
      "none": "Geen"
    },
    "button": "Knop",
    "emailNotification": "E-mailmelding",
    "emailNotificationSubheader": "Ontvang een e-mail wanneer iemand je formulier invult",
    "subject": "Onderwerp",
    "emailTo": "E-mail (Aan)",
    "senderName": "Naam afzender",
    "senderNameWarning": "Je (subaccountnaam) wordt toegevoegd als dit leeg blijft",
    "emailError": "Er is minstens één e-mailadres vereist",
    "emailTooltip": "Het eerste e-mailadres is bedoeld voor het veld 'Aan'. Alle volgende e-mailadressen worden opgenomen in het veld 'Cc'.",
    "clear": "Wissen",
    "showNotificationsSettings": "Meldingsinstellingen tonen",
    "emailServicePaid": "Deze functie is een betaalde dienst met kosten op basis van je gebruik.",
    "integration": "Integratie",
    "payment": {
      "payments": "Betalingen",
      "connected": "Verbonden",
      "connect": "Verbinden",
      "liveModeOn": "Live weergave",
      "status": "Status",
      "test": "Test",
      "live": "Live",
      "paymentFields": "Betalingsvelden",
      "suggestAnAmount": "Stel een bedrag voor",
      "selectCurrency": "Valuta selecteren",
      "tagActiveBGColor": "Actieve tagkleur",
      "includeOtherAmount": "Voeg een ander bedrag toe",
      "footerInfo": "Info voettekst",
      "paymentType": "Betalingstype",
      "sellProducts": "Producten verkopen",
      "customAmount": "Aangepast bedrag",
      "collectPayment": "Betaling innen",
      "generalSettings": "Algemene velden",
      "product": {
        "addProduct": "Product toevoegen",
        "productSettings": "Productinstellingen",
        "selectProduct": "Selecteer product",
        "editThisProduct": "Dit product bewerken",
        "clickHere": "Klik hier",
        "show": "Tonen",
        "description": "Beschrijving",
        "image": "Afbeelding",
        "layoutSettings": "Lay-outinstellingen",
        "singleColumn": "Enkele kolom",
        "twoColumn": "Twee kolommen",
        "threeColumn": "Drie kolommen",
        "maxProductError": "Maximaal 20 producten toegestaan in een formulier",
        "productSelectPlaceholder": "Selecteer een product",
        "orderConfirmation": "Bestelbevestiging",
        "orderConfirmationWarning": "Zodra de betaling is geslaagd, wordt een pagina met de bestelbevestiging getoond",
        "quantity": "Aantal",
        "createProduct": "Om een product te maken"
      },
      "coupons": {
        "coupons": "Coupons",
        "enableCoupon": "Coupon inschakelen",
        "couponAlignment": "Uitlijning coupons",
        "left": "Links",
        "right": "Rechts",
        "fullWidth": "Volledige breedte",
        "tooltipMessage": "Stel de positie van de couponinvoer in met de onderstaande types"
      }
    },
    "addressSettings": {
      "autoComplete": "Adres automatisch aanvullen",
      "addressfields": "Adresvelden",
      "placeholderSearch": "Zoek op adres",
      "autoCompleteTooltip": "Biedt de mogelijkheid om op adressen te zoeken en deze automatisch aan te vullen. Deze functie is betalend."
    },
    "autoResponder": "Automatische beantwoorder",
    "autoResponderSubheader": "Verzend een automatische e-mail naar de persoon die het formulier heeft ingediend (de e-mail bevat een kopie van de informatie die op het formulier werd ingevuld)",
    "replyToEmail": "Antwoord op e-mail",
    "open": "Open",
    "won": "Gewonnen",
    "lost": "Verloren",
    "abandon": "Verlaten",
    "selectAPipeline": "Selecteer een pijplijn",
    "stage": "Stadium",
    "status": "Status",
    "opportunityValue": "Waarde opportuniteit",
    "createNewPipeline": "Maak een pijplijn aan",
    "editOpportunitySettings": "Instellingen opportuniteit bewerken",
    "contact": "Contactpersoon",
    "opportunity": "Opportuniteit",
    "add": "Toevoegen",
    "more": "Meer",
    "opportunityHeading": "Waar wilt u uw opportuniteit bijwerken?",
    "date": {
      "dateSettings": "Datuminstellingen",
      "dateFormat": "Datumformaat",
      "dateSeparator": "Scheidingsteken datum",
      "dateFormatTooltipMessage": "Selecteer een datumnotatie. D staat voor dag, M voor maand en J voor jaar",
      "dateSeparatorTooltipMessage": "Selecteer een scheidingsteken dat de dag, maand en het jaar scheidt in de indeling die je hebt geselecteerd voor Datumnotatie",
      "disablePicker": "Picker uitschakelen"
    },
    "restoreVersion": "Herstel versie",
    "versionHistory": "Versiegeschiedenis",
    "errorMessage": "Fout, probeer het opnieuw",
    "version": "Versie:",
    "currentVersion": "Huidige versie",
    "messageStyling": "Berichtopmaak",
    "validateEmailHeader": "E-mail valideren",
    "validateEmailDescription": "De knop wordt getoond zodra de gebruiker een e-mailadres invoert. Deze functie is ontworpen om kwalitatieve leads te genereren.",
    "validatePhoneHeader": "Telefoonnummer valideren",
    "validatePhoneDescription": "De knop wordt getoond zodra de gebruiker een telefoonnummer invoert. Deze functie is ontworpen om kwalitatieve leads te genereren.",
    "validatePhoneWarning": "Om SMS OTP-verificatie in te schakelen, moet je telefoonnummer zijn ingesteld in je locatie-instellingen",
    "style": {
      "formLayoutType": "Formuliertype",
      "oneColumn": "Eén kolom",
      "twoColumn": "Twee kolommen",
      "singleLine": "Enkele lijn",
      "fieldWidth": "Breedte veld",
      "labelAlignment": {
        "labelAlignment": "Uitlijning label",
        "labelWidth": "Breedte label"
      },
      "fieldSpacing": "Afstand tussen velden",
      "color": "kleur",
      "fontColor": "Kleur lettertype",
      "inputBackground": "Achtergrond invoer",
      "layout": "Layout",
      "colorsAndBackground": "Kleuren en achtergrond",
      "advanceSettings": "Geavanceerde instellingen",
      "inputField": "Invoerveld",
      "borderWidth": "Randbreedte",
      "borderColor": "Randkleur",
      "borderStyle": "Randstijl",
      "fontSize": "Lettergrootte",
      "fontWeight": "Lettergewicht",
      "labelColor": "Labelkleur",
      "placeholderColor": "Kleur placeholder",
      "shortLabelColor": "Korte labelkleur"
    },
    "replyToTooltip": "Pas het standaard e-mailadres voor antwoorden aan door {'{'}{'{'}contact.email{'}'}{'}'} te gebruiken om antwoorden te activeren voor de contactpersoon die het formulier indient.",
    "content": "Content",
    "calculations": "Berekeningen",
    "uploadOptions": "Uploadopties",
    "opportunitySettingsWarning": "Stel de opportuniteitsinstellingen in door op de knop Opportuniteitsinstellingen bewerken te klikken.",
    "question": "Vraag",
    "createContactOnPartialSubmission": "Contact aanmaken bij gedeeltelijke indiening",
    "howItWorks": "Hoe werkt het?",
    "createContactOnPartialSubmissionDescription": "Rond de slide af en klik op 'Volgende'. Er wordt automatisch een nieuw contact aangemaakt en opgeslagen.",
    "swapWarningMessageSurvey": "Het bewerken of verwisselen van opties heeft invloed op dit aangepaste veld op alle plaatsen waar het wordt gebruikt. Het kan ook de voorwaardelijke logica resetten. ",
    "swapWarningMessageForm": "Het bewerken of verwisselen van opties heeft invloed op dit aangepaste veld op alle plaatsen waar het wordt gebruikt. ",
    "optionLabel": "Optielabel",
    "calculationValue": "Waarde berekening",
    "addOption": "Optie toevoegen",
    "enableCalculations": "Berekeningen inschakelen",
    "productSelectionSubtext": "Sta selectie van enkele of meerdere producten toe",
    "multi": "Multi",
    "single": "Enkel",
    "productSelectionHeading": "Productselectie"
  },
  "survey": {
    "typeOfBuilder": "Enquête",
    "survyeStyle": "Enquetestijl",
    "surveyName": "Enquêtenaam",
    "surveyElement": "Enquête-element",
    "addSurveyElement": "Enquête-element toevoegen",
    "unsavedAlertMessage": "De enquête bevat niet-opgeslagen werk. Weet je zeker dat je al het niet-opgeslagen werk wilt wissen?",
    "thirdPartyScriptAlertMessage": "In het HTML-blok kunnen scripts van derden krachtige functionaliteit bieden, maar brengen ook risico's met zich mee op het gebied van privacy, beveiliging, prestaties en paginagedrag. Daarom raden we je aan de inhoud van dit script te bekijken voordat je het opslaat in de enquête.",
    "integrateHeader": "Enquête integreren",
    "integrateSubHeader": "Gebruik de volgende opties om je aangepaste enquête in je website te integreren",
    "integrateCopyLinkMessage": "Kopieer onderstaande link om deze te delen.",
    "onSurveySubmission": "Bij het indienen van een enquête",
    "surveySettings": "Instellingen voor enquêtes",
    "oneQuestionAtTime": "Eén vraag tegelijk",
    "backButton": "Terugknop",
    "backButtonTooltipMessage": "Met de terugknop kan de gebruiker de vorige slide verplaatsen",
    "disableAutoNavigation": "Automatische navigatie uitschakelen",
    "disableAutoNavigationTooltipMessage": "Indien ingeschakeld, gaat de enquête niet automatisch over naar de volgende slide",
    "progressBar": "Voortgangsbalk",
    "progressBarTooltipMessage": "Door dit uit te schakelen, wordt de voortgangsbalk uit de enquête verwijderd",
    "disableAnimation": "Animatie uitschakelen",
    "disableAnimationTooltipMessage": "Door dit in te schakelen, wordt de animatie bij de slideovergang uitgeschakeld",
    "scrollToTop": "Scroll naar boven",
    "scrollToTopTooltipMessage": "Door deze optie in te schakelen, wordt de enquête op de volgende slide naar boven gescrold",
    "footerHTML": "HTML-voettekst",
    "footerHTMLMessage": "Je kunt aangepaste CSS (met behulp van style tag) en HTML in hetzelfde vak schrijven",
    "editFooter": "Voettekst bewerken",
    "disqualifyImmediately": "Onmiddellijk diskwalificeren",
    "disqualifyAfterSubmit": "Diskwalificeren na indiening",
    "slideConflictAlertMessage": "Deze dia bevat meerdere vragen waarvoor een logica is ingesteld. Dit kan onbedoeld gedrag veroorzaken",
    "addSlide": "Slide toevoegen",
    "settings": "Instellingen",
    "delete": "Verwijderen",
    "slideDeleteAlertMessage": "Weet je zeker dat je deze dia wilt verwijderen?",
    "slideDeleteBetweenAlertMessage": "Hiermee worden de andere slides opnieuw gerangschikt. Weet je zeker dat je deze slide wilt verwijderen?",
    "slideName": "Naam slide",
    "slidePosition": "Positie slide",
    "jumpTo": "Ga naar",
    "enableTimezoneSurvey": "Door deze optie te activeren, worden de gegevens van de tijdzone van de persoon die de enquête indient geregistreerd.",
    "footer": {
      "footer": "Voettekst",
      "enableNewFooter": "Nieuwe voettekst inschakelen",
      "stickToSurvey": " Blijf bij de enquête",
      "backgroundFill": "Achtergrondvulling",
      "footerHeight": "Hoogte voettekst",
      "enableProgressBar": "Voortgangsbalk inschakelen",
      "buttons": "Knop",
      "buttonType": "Type knop",
      "fillNext": "Invullen (volgende)",
      "fillPrevious": "Invullen (vorige)",
      "fillSubmit": "Invullen (indienen)",
      "textColor": "Knoptekst",
      "position": "Positie",
      "previousText": "Vorige (tekst)",
      "nextText": "Volgende (tekst)",
      "submitText": "Indienen (tekst)",
      "fillComplete": "Invullen voltooid",
      "fillInactive": "Invullen inactief",
      "footerType": "Voetteksttype",
      "stickToCard": "Op kaart plakken",
      "stickToPage": "Op pagina plakken"
    },
    "surveyNotfication": "Enquêtemelding",
    "style": {
      "surveyPadding": "Enquête-opvulling"
    },
    "autoResponderSubheader": "Verzend een automatische e-mail naar de persoon die de enquête indient (de e-mail bevat een kopie van de informatie die in de enquête werd ingevuld)"
  },
  "form": {
    "typeOfBuilder": "Formulier",
    "formStyle": "Stijl formulier",
    "formElement": "Formulierelement",
    "addFormElement": "Formulierelement toevoegen",
    "addConditionalLogic": "Voorwaardelijke logica toevoegen",
    "inlineForm": "Inline-formulier",
    "formName": "Naam formulier",
    "fullScreenMode": "Modus volledig scherm",
    "fullScreenModetooltip": "Door dit in te schakelen, breidt het formulier zich uit over de gehele breedte van de container, tot aan de rand van het inbeddingsgebied terwijl de breedte van het formulier blijft behouden.",
    "enableTimezoneForm": "Door deze optie te activeren, worden de gegevens van de tijdzone van de persoon die het formulier indient geregistreerd.",
    "formSettings": "Formulierinstellingen",
    "unsavedAlertMessage": "Het formulier heeft niet-opgeslagen werk. Weet je zeker dat je al het niet-opgeslagen werk wilt wissen?",
    "thirdPartyScriptAlertMessage": "In het HTML-blok kunnen scripts van derden krachtige functionaliteit bieden, maar brengen ook risico's met zich mee op het gebied van privacy, beveiliging, prestaties en paginagedrag. Daarom raden we je aan om de inhoud van dit script te bekijken voordat je het opslaat in het formulier.",
    "onFormSubmission": "Bij het indienen van een formulier",
    "integrateForm": "Formulier integreren",
    "embedlayoutType": "Ingesloten lay-outtype",
    "embedlayoutTypeSubMessage": "Selecteer een lay-out om in te sluiten in een trechter of een externe website",
    "triggerType": "Triggertype",
    "showOnScrolling": "Weergeven tijdens het scrollen",
    "showAfter": "Weergeven na",
    "seconds": "seconden",
    "alwaysShow": "Altijd weergeven",
    "activationOptions": "Activatieopties",
    "activateOn": "Activeren op",
    "visit": "bezoeken",
    "alwaysActivated": "Altijd ingeschakeld",
    "deactivationOptions": "Deactivatieopties",
    "deactivateAfterShowing": "Uitschakelen na weergave",
    "times": "keer",
    "deactiveOnceLeadCollected": "Deactiveer zodra de lead is verzameld",
    "neverDeactivate": "Nooit uitschakelen",
    "copyEmbedCode": "Insluitcode kopiëren",
    "copyFormLink": "Link naar formulier kopiëren",
    "openFormLink": "Link naar formulier openen",
    "inline": "Inline",
    "inlineDescription": "Een inlineformulier wordt samen met de inhoud van de pagina als een native element getoond. Het is geen overlay en kan niet worden gesloten of geminimaliseerd.",
    "inlineHoverDescription": "Een inlineformulier wordt samen met de inhoud van de pagina als een native element getoond. Het is geen overlay en kan niet worden gesloten of geminimaliseerd.",
    "popup": "Pop-up",
    "popupDescription": "Een pop-up toont het formulier als een overlay in een lichtbak. Het kan niet geminimaliseerd maar wel gesloten worden.",
    "popupHoverDescription": "Een pop-up toont het formulier als een overlay in een lichtbak. Het kan niet geminimaliseerd maar wel gesloten worden.",
    "politeSlideIn": "Discrete slide-in",
    "politeSlideInDescription": "Onderaan het scherm verschijnt een discrete slide-in die verdwijnt wanneer het scherm wordt gesloten",
    "politeSlideInHoverDescription": "Verschijnt onderaan het scherm en verdwijnt wanneer het scherm wordt gesloten",
    "stickySidebar": "Vastgezette zijbalk",
    "stickySidebarDescription": "Aan de zijkant van het scherm verschijnt een vastgezette sidebar die kan worden geminimaliseerd tot een tabblad dat aan de zijkant van het venster blijft plakken.",
    "stickySidebarHoverDescription": "Verschijnt aan de zijkant van het scherm en kan worden geminimaliseerd tot een tabblad dat aan de zijkant van het venster blijft plakken",
    "allowMinimize": "Minimaliseren toestaan",
    "conditonalLogic": {
      "conditions": "Voorwaarden",
      "noExistingCondition": "Nog geen bestaande voorwaarden",
      "freeRoamMessage": "Je hebt de volledige vrijheid zonder regels - althans voorlopig",
      "addNewCondition": "Nieuwe conditie toevoegen",
      "redirectToUrl": "Doorverwijzen naar url",
      "displayCustomMessage": "Aangepast bericht weergeven",
      "disqualifyLead": "Lead diskwalificeren",
      "showHideFields": "Velden tonen/verbergen",
      "openBelowUrl": "Open de onderstaande URL",
      "showCustomMessage": "Aangepast bericht weergeven",
      "alertConditionDelete": "Weet je zeker dat je deze voorwaarde wilt verwijderen?",
      "deleteCondition": "Voorwaarde verwijderen",
      "conditionExecutedMessage": "Voorwaarden worden van boven naar beneden uitgevoerd.",
      "allOperationOptions": {
        "isEqualTo": "Is gelijk aan",
        "isNotEqualTo": "Is niet gelijk aan",
        "contains": "Bevat",
        "startsWith": "Begint met",
        "endsWith": "Eindigt met",
        "isEmpty": "Is leeg",
        "isFilled": "Is ingevuld",
        "before": "Voor",
        "after": "Na",
        "greaterThan": "Groter dan",
        "lessThan": "Lager dan"
      },
      "logic": {
        "if": "Als",
        "then": "Dan",
        "and": "En",
        "or": "Of"
      },
      "phoneAlertMessage": "Om te zoeken op telefoonnummers met een landcode, moet je expliciet het '+'-symbool opnemen in je zoekopdracht.",
      "deleteAlertMessage": "Weet je zeker dat je dit veld wilt verwijderen? De voorwaarde die aan dit veld is gekoppeld, wordt dan ook verwijderd.",
      "deleteField": "Veld verwijderen"
    },
    "minimizedTitlePlaceholder": "Geminimaliseerde titel",
    "dragYourElementFromLeft": "Sleep je elementen naar hier vanaf links",
    "formNotification": "Formuliermelding",
    "style": {
      "formPadding": "Formulieropvulling"
    }
  },
  "quiz": {
    "quizElement": "Quiz-element",
    "quizNotification": "Quizmelding",
    "viewAndCustomise": "Bekijken en aanpassen",
    "categoryAndScore": "Categorie en score",
    "provideFeedback": "Feedback geven",
    "addQuizElement": "Quiz-element toevoegen",
    "typeOfBuilder": "Quiz",
    "catgory": {
      "manageCategories": "Categorieën beheren"
    },
    "quizSettings": "Quiz-instellingen",
    "onQuizSubmission": "Over het indienen van de quiz",
    "settings": "Instellingen",
    "deletePage": "Pagina verwijderen",
    "quizResult": {
      "resultTemplate": "Sjabloon voor resultaten",
      "quizResultAlertMessage": "De gekozen resultatenpagina wordt weergegeven zodra de quiz is voltooid"
    },
    "autoResponderSubheader": "Verzend een automatische e-mail naar de persoon die de quiz heeft ingediend (de e-mail bevat een kopie van de informatie die bij de quiz werd ingevuld)",
    "integrateHeader": "Quiz integreren",
    "integrateSubHeader": "Gebruik de volgende opties om je aangepaste quiz in je website te integreren",
    "integrateCopyLinkMessage": "Kopieer onderstaande link om deze te delen.",
    "pageName": "Paginanaam",
    "pagePosition": "Paginapositie"
  }
}