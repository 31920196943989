<script setup lang="ts">
import { LocationService } from "@/service/LocationServices";
import { useAppStore } from "@/store/app";
import { defaultAddressSettings, defaultPaymentSettings } from "@/util/default";
import {
  addressFields,
  getAllFontsImport,
  getConstructedQueryKey,
  standardFields,
} from "@/util/methods";
import {
  AlertCircleIcon,
  DotsHorizontalIcon,
  PlusCircleIcon,
  Settings02Icon,
  Trash04Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { UIDropdown, UITooltip, renderIcon } from "@gohighlevel/ghl-ui";
import { cloneDeep } from "lodash";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import AlertModal from "../common/AlertModal.vue";
import BuilderBranding from "../common/BuilderBranding.vue";
import CustomFieldsPreview from "../common/CustomFieldsPreview.vue";
import Footer from "../common/Footer.vue";
import HLImage from "../common/HLImage.vue";
import StandardFieldPreview from "../common/StandardFieldPreview.vue";

const { t } = useI18n();
const store = useAppStore();
const { elements } = storeToRefs(store);
const currentElement = ref({ label: "" });
const globalScriptCSSStyle = ref({ textContent: null });
const globalScriptCSSLabelHolder = ref({ textContent: null });
const globalScriptCSSPlaceHolder = ref({ textContent: null });
const globalScriptCSSFieldBGColor = ref({ textContent: null });
const globalScriptCSSFieldBorder = ref({ textContent: null });
const globalScriptCSSFieldShadow = ref({ textContent: null });
const globalScriptCSSFieldWidth = ref({ textContent: null });
const globalScriptCSSFieldPadding = ref({ textContent: null });
const globalScriptCSSFontHolder = ref({ textContent: null });

const globalScriptCSSShortLabelHolder = ref({ textContent: null });
const fullWidthFields = [
  "img",
  "submit",
  "h1",
  "html",
  "captcha",
  "large_text",
  "source",
  "file_upload",
];
const openAlertModal = ref(false);
const slideDeleteMessage = ref("Are you sure you want to delete store slide?");
const slideToBeRemoved = ref(undefined);

const formStyle = computed(() => {
  const style: any = store.formStyle;
  const borderRadius = style?.border?.radius + "px";
  return {
    padding:
      style?.padding?.top +
      "px " +
      style?.padding?.right +
      "px " +
      style?.padding?.bottom +
      "px " +
      style?.padding?.left +
      "px",
    backgroundColor: "#" + style?.background,
    border: style?.border?.border + "px " + style?.border?.style + " #" + style?.border?.color,
    borderRadius: store.footerStyle?.stickyFooter
      ? `${borderRadius} ${borderRadius} 0 0`
      : borderRadius,
    boxShadow:
      style?.shadow?.horizontal +
      "px " +
      style?.shadow?.vertical +
      "px " +
      style?.shadow?.blur +
      "px " +
      style?.shadow?.spread +
      "px #" +
      style?.shadow?.color,
    width: (style?.width || 548) + "px",
  };
});

const clickedOnElement = (e, element, index, slideIndex) => {
  e.stopPropagation();
  store.sidebarOverlaySettings = { index, slideIndex, formIndex: undefined };
  currentElement.value = element;
  store.isAnyElementActive = true;
  store.currentActiveIndex = [slideIndex, index];
  store.currentSlideIndex = slideIndex;
};

const fieldStyle = computed(() => {
  const fieldStyleStore = store.fieldStyle;
  const style = {
    padding: `${fieldStyleStore?.padding?.top}px ${fieldStyleStore?.padding?.right}px ${fieldStyleStore?.padding?.bottom}px ${fieldStyleStore?.padding?.left}px`,
    backgroundColor: "#" + fieldStyleStore?.bgColor,
    border: `${fieldStyleStore?.border?.border}px ${
      fieldStyleStore?.border?.type === "none" ? "" : fieldStyleStore?.border?.type
    } #${fieldStyleStore?.border?.color}`,
    borderRadius: fieldStyleStore?.border?.radius + "px",
    boxShadow: `${fieldStyleStore?.shadow?.horizontal}px ${fieldStyleStore?.shadow?.vertical}px ${fieldStyleStore?.shadow?.blur}px ${fieldStyleStore?.shadow?.spread}px #${fieldStyleStore?.shadow?.color}`,
    width: fieldStyleStore?.width + "px",
    color: "#" + fieldStyleStore?.labelColor,
    font: `${fieldStyleStore.labelFontSize}px  ${fieldStyleStore.labelFontWeight} ${fieldStyleStore.labelFontFamily}`,
  };
  return style;
});

onMounted(() => {
  const globalScriptCSS = document.createElement("style");
  globalScriptCSS.type = "text/css";

  // Google and bunny font css script
  const globalScriptFontFamily = document.createElement("style");
  globalScriptFontFamily.type = "text/css";
  globalScriptFontFamily.appendChild(document.createTextNode(""));
  globalScriptCSSFontHolder.value = globalScriptFontFamily.childNodes[0];
  document.head.appendChild(globalScriptFontFamily);

  for (let i = 0; i < 9; i++) {
    globalScriptCSS.appendChild(document.createTextNode(""));
  }
  globalScriptCSSStyle.value = globalScriptCSS.childNodes[0];
  //For Label CSS Change
  globalScriptCSSLabelHolder.value = globalScriptCSS.childNodes[1];
  //For Placeholder CSS Change
  globalScriptCSSPlaceHolder.value = globalScriptCSS.childNodes[2];
  globalScriptCSSFieldBGColor.value = globalScriptCSS.childNodes[3];
  globalScriptCSSFieldBorder.value = globalScriptCSS.childNodes[4];
  globalScriptCSSFieldShadow.value = globalScriptCSS.childNodes[5];
  globalScriptCSSFieldWidth.value = globalScriptCSS.childNodes[6];
  globalScriptCSSFieldPadding.value = globalScriptCSS.childNodes[7];
  globalScriptCSSShortLabelHolder.value = globalScriptCSS.childNodes[8];

  document.head.appendChild(globalScriptCSS);
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
  setGlobalStyleInHTMl();
});

// const standardSidebarEnabled = (e) => {
//   const modals = document.getElementsByClassName("n-modal-container");
//   const colorPickers = document.getElementsByClassName("v-binder-follower-container");
//   const borderTypes = document.getElementsByClassName("v-binder-follower-content");
//   const modalByTiny = document.getElementsByClassName("tox-dialog");
//   let isClickedOnModal = false;
//   Array.prototype.forEach.call(modals, (modal) => {
//     if (modal.contains(e.target)) {
//       isClickedOnModal = true;
//     }
//   });
//   let isClickedOnColorPicker = false;
//   Array.prototype.forEach.call(colorPickers, (colorPicker) => {
//     if (colorPicker?.contains(e.target)) {
//       isClickedOnColorPicker = true;
//     }
//   });
//   let isClickedOnBorderType = false;
//   Array.prototype.forEach.call(borderTypes, (borderType) => {
//     if (borderType?.contains(e.target)) {
//       isClickedOnBorderType = true;
//     }
//   });
//   let tinyPopUp = false;
//   Array.prototype.forEach.call(modalByTiny, (tiny) => {
//     if (tiny?.contains(e.target)) {
//       tinyPopUp = true;
//     }
//   });
//   console.log(store.formSidebarElement?.contains(e.target), "FOmr");
//   if (
//     !isClickedOnModal &&
//     !isClickedOnColorPicker &&
//     !isClickedOnBorderType &&
//     !tinyPopUp &&
//     !store.formSidebarElement?.contains(e.target)
//   ) {
//     store.currentCustomSidebarPreview = undefined;
//     store.sidebarOverlaySettings = undefined;
//     store.isAnyElementActive = false;
//     allSlideInactive();
//   }
// };

const setGlobalStyleInHTMl = () => {
  const fieldStyle = store.fieldStyle;
  const formStyle = store.formStyle;
  globalScriptCSSLabelHolder.value.textContent = `#_builder-form label {
        color: #${fieldStyle?.labelColor};
        font-size: ${fieldStyle.labelFontSize}px;
        font-weight: ${fieldStyle.labelFontWeight};
        font-family: '${fieldStyle.labelFontFamily}';
        line-height: 1.8;
      }
      #_builder-form .tnc {
        font-family: '${fieldStyle.labelFontFamily}';
      }
      `;

  globalScriptCSSPlaceHolder.value.textContent = `#_builder-form ::placeholder, #_builder-form .form-dropdown a,
      #_builder-form .form-dropdown .dropdown-toggle {
        color : #${fieldStyle.placeholderColor};
        font-family:${fieldStyle.placeholderFontFamily};
        font-size:${fieldStyle.placeholderFontSize}px;
        font-weight: '${fieldStyle.placeholderFontWeight}';
      }`;
  globalScriptCSSFieldBGColor.value.textContent = `.form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown{ background-color : #${fieldStyle.bgColor};}`;
  globalScriptCSSFieldBorder.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown { border:" +
    fieldStyle.border.border +
    "px " +
    fieldStyle.border.type +
    " #" +
    fieldStyle.border.color +
    ";border-radius:" +
    fieldStyle.border.radius +
    "px ; }" +
    ` .line-form-style { border-bottom: ${fieldStyle.border.border}px ${fieldStyle.border.type} #${fieldStyle.border.color} !important; background-color: #${fieldStyle.bgColor} !important;}`;
  globalScriptCSSFieldPadding.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown { padding:" +
    fieldStyle.padding.top +
    "px " +
    fieldStyle.padding.right +
    "px " +
    fieldStyle.padding.bottom +
    "px " +
    fieldStyle.padding.left +
    "px}";
  globalScriptCSSFieldShadow.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown { box-shadow:" +
    fieldStyle.shadow.horizontal +
    "px " +
    fieldStyle.shadow.vertical +
    "px " +
    fieldStyle.shadow.blur +
    "px " +
    fieldStyle.shadow.spread +
    "px #" +
    fieldStyle.shadow.color +
    "}";
  globalScriptCSSFieldWidth.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown {max-width:" +
    fieldStyle.width +
    "px}";
  globalScriptCSSShortLabelHolder.value.textContent = `#_builder-form .short-label {
    color: #${fieldStyle.shortLabel?.color} !important;
    font-size: ${fieldStyle.shortLabel?.fontSize}px !important;
    font-weight: ${fieldStyle.shortLabel?.fontWeight};
    font-family: '${fieldStyle.shortLabel?.fontFamily}';
  }`;
  const styleStore = formStyle.customStyle.split("}");
  let styleString = "";
  styleStore.map(function (value: string) {
    if (value.includes("footer")) {
      styleString += `${value}}`;
    } else if (value !== "" && value.length > 0) {
      styleString += "#_builder-form " + value + "} ";
    }
  });
  globalScriptCSSStyle.value.textContent = styleString;
};

//Store Subscription to watch on store value change using patch
store.$subscribe(() => {
  setGlobalStyleInHTMl();
});

watch([store.fontCollection, () => store.builderConfig.isGDPRCompliant], () => {
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
});

const isActiveElement = (index, slideIndex) => {
  return store.currentActiveIndex[0] === slideIndex && store.currentActiveIndex[1] === index;
};

const changeLog = (e, slideIndex) => {
  const item = e.added?.element || e.moved?.element || e.removed?.element;
  const elementDrop = {
    ...cloneDeep(item),
    hiddenFieldQueryKey: item.id ? item.hiddenFieldQueryKey : item.tag,
    active: false,
  };
  if (e.added) {
    setTimeout(() => {
      if (
        store.slides[slideIndex].slideData.filter((element) => element.model === "opportunity")
          ?.length === 1
      ) {
        store.showOpportunitiesModal = true;
      }
    }, 1000);
    setTimeout(async () => {
      let isAlreadyPresent = false;
      let customElementCount = 0;
      store.slides.forEach((slide) => {
        slide.slideData.forEach((element) => {
          const isSameId = elementDrop?.id && element?.id === elementDrop?.id;
          const isSameTag = !elementDrop?.id && element.tag === elementDrop.tag;
          if (
            element.tag === "group_address" &&
            store.addressSettings.children.find((ele) => ele.tag === elementDrop.tag)
          ) {
            isAlreadyPresent = true;
          }
          const isCustomOrDuplicateAllowed =
            elementDrop?.custom || store.duplicateAllowed.includes(elementDrop.tag);
          if ((isSameId || isSameTag) && !isCustomOrDuplicateAllowed) {
            isAlreadyPresent = true;
          }
          if (element.custom) {
            customElementCount += 1;
          }
        });
      });

      if (!isAlreadyPresent && e.added) {
        if (elementDrop?.custom) {
          if (!customElementCount && !store.parentFolderId) {
            store.parentFolderName = `Survey | ${store.builder.name}`;
            const response = await LocationService.createCustomField(store.locationId, {
              name: store.parentFolderName,
              documentType: "folder",
              model: "contact",
            });
            store.parentFolderId = response.data?.customFieldFolder?.id;
          }
          const existingElementsCount = [
            ...store.customFields,
            ...store.slides[slideIndex].slideData,
          ].filter((element) => element.dataType === elementDrop.dataType)?.length;
          elementDrop.label = `${elementDrop.label} ${existingElementsCount + 1}${Math.random()
            .toString(36)
            .substr(2, 3)}`;
          elementDrop.hiddenFieldQueryKey = getConstructedQueryKey(elementDrop.label);
          elementDrop.parentId = store.parentFolderId;
        }
        delete elementDrop.icon;
        delete elementDrop.category;

        if (
          store.slides[slideIndex].slideData.find((element) => element.tag === "group_address") &&
          addressFields.find((element) => element.tag === elementDrop.tag)
        ) {
          store.addressSettings.children.push(elementDrop);
        } else {
          store.slides[slideIndex].slideData.splice(e.added.newIndex, 0, elementDrop);
        }

        const termsAndConditionsQuery = (element) => element.tag === "terms_and_conditions";
        const privacyElementQuery = (element) => element.label === privacyElement.label;
        const textElement = standardFields.customized.group[0];
        const privacyElement = {
          ...textElement,
          fontSize: 14,
          padding: { ...textElement.padding, top: 15 },
          label:
            '<p style="text-align: center"><a target="_blank" rel="noopener noreferrer nofollow" href="https://www.example.com">Privacy Policy</a> | <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.example.com">Terms of Service</a></p>',
        };
        const termsAndConditionstPhoneLabel =
          "I Consent to Receive SMS Notifications, Alerts & Occasional Marketing Communication from company. Message frequency varies. Message & data rates may apply. Text HELP to (XXX) XXX-XXXX for assistance. You can reply STOP to unsubscribe at any time.";
        if (
          elementDrop.tag === "phone" &&
          !store.slides.some((slide) => slide.slideData.find(termsAndConditionsQuery))
        ) {
          store.slides[slideIndex].slideData.push({
            ...standardFields.customized.group.find(termsAndConditionsQuery),
            placeholder: termsAndConditionstPhoneLabel,
            preview: termsAndConditionstPhoneLabel,
          });
          if (!store.slides.some((slide) => slide.slideData.find(privacyElementQuery))) {
            store.slides[slideIndex].slideData.push(privacyElement);
          }
        }
        if (elementDrop.tag === "payment") {
          store.formAction.actionType = "3";
          store.payment = {
            ...defaultPaymentSettings,
            paymentType: elementDrop.subType === "product" ? "product" : "donation",
          };
          store.builderConfig.enablePartialContactCreation = true;
        }
        if (elementDrop.tag === "group_address") {
          store.addressSettings = defaultAddressSettings;
          store.addressSettings.children = addressFields.map((childElement) => {
            const currentChildElementIndex = store.slides[slideIndex].slideData.findIndex(
              (element) => element.tag === childElement.tag
            );
            const currentChildElement =
              currentChildElementIndex === -1
                ? {}
                : store.slides[slideIndex].slideData[currentChildElementIndex];
            // delete currentChildElement from elements
            if (currentChildElementIndex !== -1) {
              store.slides[slideIndex].slideData.splice(currentChildElementIndex, 1);
            }
            return {
              ...childElement,
              ...currentChildElement,
            };
          });
          console.log(store.addressSettings);
        }
        if (
          elementDrop.model === "opportunity" &&
          store.opportunitySettings.payload.customFields.length
        ) {
          store.opportunitySettings.payload.customFields.push({
            id: elementDrop.Id,
            key: elementDrop.fieldKey.replace("opportunity.", ""),
          });
        }
      }
      store.addToHistory();
    }, 200);
  }

  if (e.removed) {
    store.slides[slideIndex].slideData.splice(e.removed.oldIndex, 1);
    store.addToHistory();
  }
  if (e.moved) {
    const oldElement = store.slides[slideIndex].slideData[e.moved.newIndex];
    const draggedElement = store.slides[slideIndex].slideData[e.moved.oldIndex];
    store.slides[slideIndex].slideData[e.moved.newIndex] = draggedElement;
    store.slides[slideIndex].slideData[e.moved.oldIndex] = oldElement;
    store.addToHistory();
  }
  store.anyUnsavedChanges = true;
};

const addSlideBelow = (index) => {
  const newSlide = {
    slideName: getSuccessorIndex(),
    slideData: [],
    id: new Date().getTime() + "-" + store.slides.length,
  };
  store.slides.splice(index + 1, 0, newSlide);
  store.formAction.fieldPerPage = store.slides.length;
  store.anyUnsavedChanges = true;
};

const slideChange = (e) => {
  allSlideInactive();
  store.isAnyElementActive = false;
  store.anyUnsavedChanges = true;
};

const getSuccessorIndex = () => {
  return "Slide " + (store.slides.length + 1);
};

const allSlideInactive = () => {
  store.currentSlideIndex = undefined;
  store.currentActiveIndex = [undefined, undefined];
};

const removeSlide = (slideIndex) => {
  openAlertModal.value = true;
  slideToBeRemoved.value = slideIndex;
  if (slideIndex < store.slides.length - 1) {
    slideDeleteMessage.value = `${t("survey.slideDeleteBetweenAlertMessage")}`;
  } else {
    slideDeleteMessage.value = `${t("survey.slideDeleteAlertMessage")}`;
  }
};

const openSlideSettings = (slideIndex) => {
  store.sidebarOverlaySettings = {
    index: undefined,
    slideIndex: slideIndex,
    formIndex: undefined,
  };
  store.isAnyElementActive = true;
};

const feedback = (modalName, result) => {
  if (result) {
    store.slides.splice(slideToBeRemoved.value, 1);
    store.anyUnsavedChanges = true;
  }
  slideToBeRemoved.value = undefined;
  openAlertModal.value = false;
};
const popModalChange = () => {
  openAlertModal.value = false;
};

const options = computed(() => {
  return [
    {
      label: `${t("survey.settings")}`,
      key: "settings",
      icon: renderIcon(Settings02Icon),
    },
    {
      label: `${t("survey.delete")}`,
      key: "delete",
      disabled: store.slides.length === 1,
      icon: renderIcon(Trash04Icon),
    },
  ];
});

const actionON = (key, slideIndex) => {
  if (key === "settings") {
    openSlideSettings(slideIndex);
  }
  if (key === "delete") {
    removeSlide(slideIndex);
  }
};

const clickedOutSideSlide = (e) => {
  store.currentCustomSidebarPreview = undefined;
  store.sidebarOverlaySettings = undefined;
  store.isAnyElementActive = false;
  allSlideInactive();
};

const hasLogicConflict = (slideIndex) => {
  if (store.slides[slideIndex].logic && Object.keys(store.slides[slideIndex].logic).length > 1) {
    return true;
  }
  return false;
};

const applyInlineStyle = () => {
  return {
    marginBottom: store.formStyle.fieldSpacing + "px",
  };
};
</script>

<template>
  <section class="hl_wrapper d-flex survey-wrap-container" @click.prevent="clickedOutSideSlide">
    <section
      id="form-builder"
      class="hl_wrapper--inner form-builder"
      :style="{ backgroundImage: `url(${store.formStyle?.bgImage})`, backgroundSize: 'cover' }"
    >
      <section class="hl_form-builder--main">
        <form id="_builder-form" name="builder-form" class="builder-preview">
          <div class="form-child-wrapper">
            <div class="smooth-dnd-container vertical">
              <VueDraggableNext
                id="form-builder-container"
                :list="store.slides"
                :sort="true"
                group="slide"
                @change="slideChange"
              >
                <div
                  v-for="(itemSlide, indexSlide) in store.slides"
                  :key="indexSlide"
                  class="smooth-dnd-draggable-wrapper"
                >
                  <div
                    class="survey-form-container"
                    :style="{
                      paddingBottom: indexSlide === store.slides.length - 1 ? '200px' : 0,
                    }"
                  >
                    <div>
                      <div
                        class="builder-form-name mb-1 flex h-10 items-center justify-between justify-items-center text-center"
                        :style="{ maxWidth: store.formStyle.width + 'px' }"
                      >
                        <div class="flex items-center text-sm font-semibold">
                          {{ itemSlide?.slideName }}
                          <UITooltip
                            v-if="hasLogicConflict(indexSlide)"
                            :style="{ maxWidth: '300px' }"
                            trigger="hover"
                            placement="top"
                          >
                            <template #trigger>
                              <AlertCircleIcon class="ml-1 h-4 w-4 text-red-400"></AlertCircleIcon>
                            </template>

                            {{ t("survey.slideConflictAlertMessage") }}
                          </UITooltip>
                        </div>
                        <div>
                          <UIDropdown
                            :id="'dropdown-' + indexSlide"
                            :options="options"
                            trigger="hover"
                            size="small"
                            @select="(key) => actionON(key, indexSlide)"
                          >
                            <DotsHorizontalIcon
                              class="focus_outline_none h-4 w-4 cursor-pointer"
                            ></DotsHorizontalIcon>
                          </UIDropdown>
                        </div>
                      </div>
                      <div v-if="store.formAction?.headerImageSrc?.trim().length > 0">
                        <div
                          class="ghl-header-banner w-full max-w-full"
                          :style="{
                            maxWidth: store.formStyle.width + 'px',
                          }"
                        >
                          <HLImage
                            :url="store.formAction?.headerImageSrc"
                            :width="store.formStyle.width"
                            alt="banner"
                          ></HLImage>
                        </div>
                      </div>
                      <div
                        id="the-form-builder"
                        class="form-element form-builder--wrap"
                        :style="formStyle"
                      >
                        <div class="drop form-builder-drop builder-padding-remove">
                          <VueDraggableNext
                            :model-value="itemSlide.slideData"
                            class="builder_sub_container"
                            :class="
                              store.builderConfig.inlineForm
                                ? 'smooth-dnd-container vertical row'
                                : 'smooth-dnd-container vertical'
                            "
                            group="elements"
                            :sort="true"
                            @change="(e) => changeLog(e, indexSlide)"
                          >
                            <div
                              v-for="(element, index) in itemSlide.slideData"
                              :key="index"
                              class="smooth-dnd-draggable-wrapper element-box menu-field-wrap col-12 form-field-wrapper"
                              :style="applyInlineStyle()"
                              @click="(e) => clickedOnElement(e, element, index, indexSlide)"
                            >
                              <div :class="{ active: isActiveElement(index, indexSlide) }">
                                <StandardFieldPreview
                                  v-if="!(element.id !== undefined || element?.custom)"
                                  :element="element"
                                  :index="index"
                                  :slide-index="indexSlide"
                                  :current-element="currentElement"
                                  :field-style="fieldStyle"
                                  :form-label-visible="store.builderConfig.formLabelVisible"
                                ></StandardFieldPreview>
                                <CustomFieldsPreview
                                  v-if="element.id !== undefined || element?.custom"
                                  :element="element"
                                  :index="index"
                                  :slide-index="indexSlide"
                                  :current-element="currentElement"
                                  :field-style="fieldStyle"
                                  :form-label-visible="store.builderConfig.formLabelVisible"
                                  :is-form="false"
                                ></CustomFieldsPreview>
                              </div>
                            </div>
                          </VueDraggableNext>
                          <BuilderBranding
                            :is-branding-active="store.formStyle.acBranding"
                            :company="store.company"
                          />
                        </div>
                      </div>
                      <div class="flex justify-center">
                        <Footer
                          v-if="store.footerStyle.stickyFooter"
                          :current-slide-index="indexSlide"
                          :is-last-slide-index="indexSlide === store.slides.length - 1"
                          :style="{ maxWidth: store.formStyle.width + 'px' }"
                        />
                      </div>
                      <UITooltip
                        v-if="!store.formAction.fieldSettingEnable"
                        trigger="hover"
                        placement="top"
                      >
                        <template #trigger>
                          <PlusCircleIcon
                            class="focus_outline_none mx-auto mt-3 block h-4 w-4 cursor-pointer"
                            @click="addSlideBelow(indexSlide)"
                          ></PlusCircleIcon>
                        </template>
                        {{ t("survey.addSlide") }}
                      </UITooltip>
                    </div>
                  </div>
                </div>
              </VueDraggableNext>
            </div>
          </div>
        </form>
      </section>
    </section>
    <Footer v-if="!store.footerStyle.stickyFooter" />
  </section>
  <AlertModal
    v-if="openAlertModal"
    title="Alert"
    :description="slideDeleteMessage"
    modal-name="slideDelete"
    :show-modal-popup="openAlertModal"
    positive-text="Ok"
    negative-text="Cancel"
    header-type="error"
    footer-type="primary"
    @pop:change="popModalChange"
    @pop:positive="feedback"
    @pop:negative="feedback"
  ></AlertModal>
</template>

<style scoped>
.settings {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  z-index: 100;
  position: absolute;
  margin-bottom: 10px;
}

.form-control {
  border-radius: 0.3125rem;
  padding: 15px 20px;
  font-size: 0.875rem;
}
.form-control:disabled,
.form-control [readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

#settings {
  font-size: 10px;
  height: 30px;
}

.builder-form-name {
  margin: 0 auto;
}

.icon-settings {
  display: flex;
  align-items: center;
}

.icon-settings svg {
  padding-right: 5px;
}

.builder-preview {
  min-height: 450px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.form-child-wrapper {
  margin: 0 auto;
}

#form-builder {
  background-color: #f3f4f6;
  margin-bottom: 0px;
  overflow: auto;
  margin: 0 auto;
  height: calc(100vh - 52px);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  width: 100%;
  /* Firefox */
}

#form-builder::-webkit-scrollbar {
  display: none;
}

.add_new_slides {
  text-align: center;
  margin-bottom: 20px;
}

.builder-item {
  cursor: pointer;
  width: 100%;
  background: grey;
  margin: 0.5rem 0;
  min-height: 3rem;
}

.builder_sub_container {
  min-height: 400px;
}

#_builder-form .active {
  outline: 1px solid #ff9900;
}

#_builder-form .main_active {
  border: 1px solid #168bf5;
}

.form-builder--wrap {
  min-height: 450px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px dashed #cde0ec;
  padding: 30px 15px;
  background-color: #fff;
}

.survey-wrap-container .survey-form-container {
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.form-builder--item {
  margin-bottom: 15px;
  border-radius: 4px;
  border: 2px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-even .form-builder--item {
  padding-left: 4px !important;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-odd .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-12 .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}

.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}

.form-builder--item label {
  font-size: 0.875rem;
  color: #2a3135;
}

.captcha-wrap-img {
  width: 335px;
  display: block;
}

.button-element {
  min-width: 85px;
}

.menu-item {
  position: relative;
}

.menu-field-wrap {
  position: relative;
}

#the-form-builder {
  margin: 0 auto;
}

.form-builder-drop {
  min-height: 400px;
  padding: 30px 15px;
}

.builder-padding-remove {
  padding: 30px 0;
}

.add-dropzone-builder {
  background: transparent;
  width: 100%;
  height: 86px;
  border-width: 1;
  border-style: solid;
  border-color: #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d !important;
}

.add-dropzone-builder p {
  margin-left: 5px !important;
}

#form-builder-container {
  height: 100%;
  min-height: 340px;
}

.slide_settings {
  position: absolute;
  right: 130px;
  top: 10px;
}

.survey-form-container a.close-icon-survey {
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  font-size: 10px;
  /* padding: 5px 7px; */
  background: #f5f5f5;
  color: #2a3135;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.focus_outline_none:focus {
  outline: none;
}
.ghl-header-banner {
  margin: 0 auto;
}

.element-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}
</style>
