<script setup lang="ts">
import { useNotify } from "@/composition/notification";
import { useMethods } from "@/composition/useMethods";
import { FormServices } from "@/service/FormServices";
import { SurveyServices } from "@/service/SurveyServices";
import { useAppStore } from "@/store/app";
import { generateFooterStyle, getAllFontsImport, getAllUniqueFonts } from "@/util/methods";
import { ArrowLeftIcon, Edit01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIToolbar, UIToolbarGroup } from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";

import { LocationService } from "@/service/LocationServices";
import { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import IntegrateFormModal from "../forms/IntegrateFormModal.vue";
import IntegrateQuizModal from "../quizzes/IntegrateQuizModal.vue";
import IntegrateSurveyModal from "../surveys/IntegrateSurveyModal.vue";
import { createFieldCSS, defaultButtonStyle } from "./../../util/default";
import AlertModal from "./AlertModal.vue";

const { t } = useI18n();
const { sendNotification } = useNotify();
const { sleep } = useMethods();

const store = useAppStore();
const router = useRouter();

const integrateModel = ref(false);
const scriptModalHiddenCheckbox = ref(false);
const title = ref("Alert");
const description = ref("Description");
const positiveText = ref("Ok");
const negativeText = ref("Cancel");
const openAlertModal = ref(false);
const modalName = ref("");
const isSaving = ref(false);
const editHeader = ref(false);
const formNameInput = ref<any>(null);
const containHTMLWhilePreview = ref(false);

watch(
  () => store.autoSaveFormOrSurvey,
  () => {
    formSaveAndUpdate();
  }
);

const fieldsMapping = async (formBuilderElements) => {
  const elements = await Promise.all(
    await formBuilderElements.map(async (element) => {
      const currentElement = element;
      let customFieldParams: any = {
        name: element?.customFieldLabel || element?.label,
        fieldKey: element?.fieldKey,
        dataType: element?.dataType,
        documentType: "field",
        showInForms: true,
        model: "contact",
        parentId: element?.parentId,
        description: "",
      };

      if (element.dataType === "TEXTBOX_LIST") {
        customFieldParams = {
          ...customFieldParams,
          textBoxListOptions: element?.picklistOptions.map(({ value, ...rest }) => rest),
        };
      }
      if (["SINGLE_OPTIONS", "MULTIPLE_OPTIONS", "CHECKBOX"].includes(element.dataType)) {
        customFieldParams = {
          ...customFieldParams,
          options: element?.picklistOptions,
        };
      }
      if (element.dataType === "RADIO") {
        customFieldParams = {
          ...customFieldParams,
          options: element?.picklistOptions,
        };
      }

      if (element.dataType === "FILE_UPLOAD") {
        customFieldParams = {
          ...customFieldParams,
          acceptedFormat:
            element?.picklistOptions?.length === 0 ? ["all"] : element?.picklistOptions,
          isMultipleFile: element?.isMultipleFile,
          maxNumberOfFiles: element?.isMultipleFile ? parseInt(element?.maxFileSize) : undefined,
        };
      }
      if (element.dataType === "SCORE") {
        customFieldParams = {
          ...customFieldParams,
          dataType: "NUMERICAL",
        };
      }
      try {
        let response;
        if (element.custom && !element.customEdited) {
          response = await LocationService.createCustomField(store.locationId, customFieldParams);
        }
        if (element.customfieldUpdated) {
          delete customFieldParams.fieldKey;
          response = await LocationService.updateCustomField(
            store.locationId,
            element?.Id || element?.id,
            customFieldParams
          );
        }
        if (response?.status === 201 || response?.status === 200) {
          return {
            ...currentElement,
            ...response.data.customField,
            customEdited: true,
            tag: response.data.customField.id,
            hiddenFieldQueryKey: response.data.customField.fieldKey.split(".")[1],
            multipleFilesAllowed: currentElement?.isMultipleFile,
            multiFileLimit: currentElement?.isMultipleFile
              ? parseInt(currentElement?.maxFileSize)
              : undefined,
            placeholder: currentElement.placeholder,
            dataType: element.dataType === "SCORE" ? "SCORE" : response.data.customField.dataType,
          };
        }
      } catch (error) {
        console.error("Hello", error);
        throw new Error(error?.response?.data?.message || error.message);
      }

      if (element.id !== undefined) {
        return currentElement;
      }
      delete currentElement.icon;
      delete currentElement.category;
      return currentElement;
    })
  );
  return elements;
};

const formSurveyBeforeSaveMapping = async () => {
  const fieldsOrSlides = store.formId ? store.elements : store.slides;
  const getRequiredFonts = getAllUniqueFonts(
    store.fieldStyle,
    store.submitMessageStyle,
    fieldsOrSlides,
    store.formId,
    store.footerStyle?.fontFamily
  );
  if (store.formId) {
    return formDataToBeSubmitted(getRequiredFonts);
  }
  if (store.surveyId) {
    return surveyDataToBeSubmitted(getRequiredFonts);
  }
  return quizDataToBeSubmitted(getRequiredFonts);
};

const quizDataToBeSubmitted = async (getRequiredFonts) => {
  store.slides = await Promise.all(
    store.slides.map(async (slide) => ({
      ...slide,
      slideData: await fieldsMapping(slide.slideData),
    }))
  );
  store.footerStyle.computedStyles = generateFooterStyle(store);
  const dataToBeSubmitted = {
    name: store.builder.name,
    formData: {
      fieldCSS:
        getAllFontsImport(getRequiredFonts, store.builderConfig.isGDPRCompliant) +
          createFieldCSS(store.fieldStyle, store.builderConfig.inputStyleType) || "",
      form: {
        company: {
          domain: store.company.domain,
          logoURL: store.company.logoURL,
          name: store.company.name,
        },
        customStyle: store.formStyle.customStyle || "",
        disableAutoNavigation: store.builderConfig.disableAutoNavigation,
        isBackButtonEnable: store.builderConfig.isBackButtonEnable,
        fbPixelId: store.facebook.fbPixelId || "",
        fieldStyle: store.fieldStyle,
        footerStyle: store.footerStyle,
        formAction: {
          actionType: store.formAction.actionType,
          redirect_url: store.formAction.redirectUrl,
          thankyouText: store.formAction.thankyouText,
          footerHtml: store.formAction.footerHtml,
          headerFullWidthEnable: false,
          headerImageSrc: store.formAction.headerImageSrc,
        },
        formLabelVisible: store.builderConfig.formLabelVisible || false,
        formSubmissionEvent: store.facebook.formSubmissionEvent,
        inputStyleType: store.builderConfig.inputStyleType,
        pageViewEvent: store.facebook.pageViewEvent,
        stickyContact: store.builderConfig.stickyContact,
        isGDPRCompliant: store.builderConfig.isGDPRCompliant,
        enableTimezone: store.builderConfig.enableTimezone,
        currentThemeId: store.builderConfig.currentThemeId,
        style: {
          fieldSpacing: store.formStyle.fieldSpacing,
          ac_branding: store.formStyle.acBranding,
          background: store.formStyle.background,
          bgImage: store.formStyle.bgImage,
          border: store.formStyle.border,
          padding: store.formStyle.padding,
          shadow: store.formStyle.shadow,
        },
        submitMessageStyle: store.submitMessageStyle,
        width: store.formStyle.width,
        emailNotificationsConfig: store.emailNotificationsConfig,
        opportunitySettings: store.opportunitySettings,
        autoResponderConfig: store.autoResponderConfig,
      },
      logic: store.logic,
      slides: store.slides,
      category: store.category,
      emailNotifications: store.builderConfig.emailNotifications,
      autoResponder: store.builderConfig.autoResponder,
      resultTemplate: store.resultTemplate,
      parentFolderId: store.parentFolderId,
      parentFolderName: store.parentFolderName,
    },
  };
  return dataToBeSubmitted;
};

const surveyDataToBeSubmitted = async (getRequiredFonts) => {
  store.slides = await Promise.all(
    store.slides.map(async (slide) => ({
      ...slide,
      slideData: await fieldsMapping(slide.slideData),
    }))
  );
  let clonedSlides = cloneDeep(store.slides);
  clonedSlides = await Promise.all(
    clonedSlides.map(async (slide) => {
      const groupAddressIndex = slide.slideData.findIndex(
        (element) => element.tag === "group_address"
      );

      // if group address is present , add store.addressSettings.children after group_address
      if (groupAddressIndex !== -1) {
        slide.slideData.splice(groupAddressIndex + 1, 0, ...store.addressSettings.children);
      }
      return {
        ...slide,
        slideData: await fieldsMapping(slide.slideData),
      };
    })
  );
  store.sidebarOverlaySettings = { ...store.sidebarOverlaySettings };
  clonedSlides.map((value: any, key: any) => {
    value.button = {
      background: defaultButtonStyle.bgColor,
      color: defaultButtonStyle.color,
      border: {
        border: defaultButtonStyle.border,
        radius: defaultButtonStyle.radius,
        padding: defaultButtonStyle.padding,
      },
    };
  });
  if (store.builderConfig.newFooter) {
    store.footerStyle.computedStyles = generateFooterStyle(store);
  }

  const dataToBeSubmitted = {
    name: store.builder.name,
    formData: {
      fieldCSS:
        getAllFontsImport(getRequiredFonts, store.builderConfig.isGDPRCompliant) +
          createFieldCSS(store.fieldStyle, store.builderConfig.inputStyleType) || "",
      form: {
        company: {
          domain: store.company.domain,
          logoURL: store.company.logoURL,
          name: store.company.name,
        },
        customStyle: store.formStyle.customStyle || "",
        disableAutoNavigation: store.builderConfig.disableAutoNavigation,
        fbPixelId: store.facebook.fbPixelId || "",
        fieldStyle: store.fieldStyle,
        footerStyle: store.footerStyle,
        address: store.addressSettings,
        formAction: {
          actionType: store.formAction.actionType,
          disqualifiedText: store.formAction.disqualifiedText,
          disqualifiedType: store.formAction.disqualifiedType.toString(),
          disqualifiedUrl: store.formAction.disqualifiedUrl,
          endsurveyText: store.formAction.endsurveyText,
          endsurveyType: store.formAction.endsurveyType.toString(),
          endsurveyUrl: store.formAction.endsurveyUrl,
          fieldPerPage: store.formAction.fieldPerPage,
          fieldSettingEnable: store.formAction.fieldSettingEnable,
          footerHtml: store.formAction.footerHtml,
          headerFullWidthEnable: false,
          headerImageSrc: store.formAction.headerImageSrc,
          redirect_url: store.formAction.redirectUrl,
          thankyouText: store.formAction.thankyouText,
        },
        formLabelVisible: store.builderConfig.formLabelVisible || false,
        formSubmissionEvent: store.facebook.formSubmissionEvent,
        isAnimationDisabled: store.builderConfig.isAnimationDisabled,
        isBackButtonEnable: store.builderConfig.isBackButtonEnable,
        isProgressBarEnabled: store.builderConfig.isProgressBarEnabled,
        isSurveyScrollEnabled: store.builderConfig.isSurveyScrollEnabled,
        inputStyleType: store.builderConfig.inputStyleType,
        pageViewEvent: store.facebook.pageViewEvent,
        stickyContact: store.builderConfig.stickyContact,
        isGDPRCompliant: store.builderConfig.isGDPRCompliant,
        enableTimezone: store.builderConfig.enableTimezone,
        currentThemeId: store.builderConfig.currentThemeId,
        style: {
          fieldSpacing: store.formStyle.fieldSpacing,
          ac_branding: store.formStyle.acBranding,
          background: store.formStyle.background,
          bgImage: store.formStyle.bgImage,
          border: store.formStyle.border,
          padding: store.formStyle.padding,
          shadow: store.formStyle.shadow,
        },
        submitMessageStyle: store.submitMessageStyle,
        width: store.formStyle.width,
        emailNotificationsConfig: store.emailNotificationsConfig,
        opportunitySettings: store.opportunitySettings,
        autoResponderConfig: store.autoResponderConfig,
        payment: clonedSlides.filter(
          (slide) => slide?.slideData?.filter((element) => element.tag === "payment").length
        ).length
          ? paymentAndProductDetailsMap()
          : null,
      },
      slides: clonedSlides,
      surveyLogicLinkById: store.builderConfig.surveyLogicLinkById,
      newFooter: store.builderConfig.newFooter,
      enablePartialContactCreation: store.builderConfig.enablePartialContactCreation,
      emailNotifications: store.builderConfig.emailNotifications,
      autoResponder: store.builderConfig.autoResponder,
      parentFolderId: store.parentFolderId,
      parentFolderName: store.parentFolderName,
    },
  };
  return dataToBeSubmitted;
};

const formDataToBeSubmitted = async (getRequiredFonts) => {
  const height = document?.getElementById("form-builder-container")?.offsetHeight;
  store.formStyle.height = height;
  store.elements = await fieldsMapping(store.elements);
  // adding to support the places where these address fields are used from this fields array
  let clonedFields = cloneDeep(store.elements);
  const groupAddressIndex = clonedFields.findIndex((element) => element.tag === "group_address");
  if (groupAddressIndex !== -1) {
    clonedFields.splice(groupAddressIndex + 1, 0, ...store.addressSettings.children);
  }
  clonedFields = await fieldsMapping(clonedFields);
  const dataToBeSubmitted = {
    name: store.builder.name,
    formData: {
      form: {
        button: store.builder.formData?.form?.button ? null : undefined,
        company: {
          domain: store.company.domain,
          logoURL: store.company.logoURL,
          name: store.company.name,
        },
        customStyle: store.formStyle.customStyle || "",
        fieldCSS:
          getAllFontsImport(getRequiredFonts, store.builderConfig.isGDPRCompliant) +
            createFieldCSS(store.fieldStyle, store.builderConfig.inputStyleType) || "",
        fbPixelId: store.facebook.fbPixelId || "",
        fieldStyle: store.fieldStyle,
        fields: clonedFields,
        payment: clonedFields.filter((element) => element.tag === "payment").length
          ? paymentAndProductDetailsMap()
          : null,
        address: store.addressSettings,
        formAction: {
          actionType: store.formAction.actionType,
          redirect_url: store.formAction.redirectUrl,
          thankyouText: store.formAction.thankyouText,
          headerImageSrc: store.formAction.headerImageSrc,
        },
        formLabelVisible: store.builderConfig.formLabelVisible || false,
        formSubmissionEvent: store.facebook.formSubmissionEvent,
        height: store.formStyle.height,
        layout: store.builderConfig.layout,
        inputStyleType: store.builderConfig.inputStyleType,
        pageViewEvent: store.facebook.pageViewEvent,
        stickyContact: store.builderConfig.stickyContact,
        isGDPRCompliant: store.builderConfig.isGDPRCompliant,
        enableTimezone: store.builderConfig.enableTimezone,
        conditionalLogic: store.builderConfig.conditionalLogic,
        fullScreenMode: store.builderConfig.fullScreenMode,
        currentThemeId: store.builderConfig.currentThemeId,
        style: {
          fieldSpacing: store.formStyle.fieldSpacing,
          ac_branding: store.formStyle.acBranding,
          bgImage: store.formStyle.bgImage,
          background: store.formStyle.background,
          border: store.formStyle.border,
          padding: store.formStyle.padding,
          shadow: store.formStyle.shadow,
        },
        submitMessageStyle: store.submitMessageStyle,
        width: store.formStyle.width,
        emailNotificationsConfig: store.emailNotificationsConfig,
        opportunitySettings: store.opportunitySettings,
        autoResponderConfig: store.autoResponderConfig,
      },
      emailNotifications: store.builderConfig.emailNotifications,
      autoResponder: store.builderConfig.autoResponder,
      parentFolderId: store.parentFolderId,
      parentFolderName: store.parentFolderName,
    },
  };
  return dataToBeSubmitted;
};
const formSaveAndUpdate = async () => {
  try {
    isSaving.value = true;
    const dataToBeSubmitted = await formSurveyBeforeSaveMapping();
    store.anyUnsavedChanges = false;
    if (store.formId || store.quizId) {
      const builderId = store.formId || store.quizId;
      const { data: formData } = await FormServices.updateFormById(builderId, dataToBeSubmitted);
      store.builder.versionHistory = formData.data.versionHistory;
    } else {
      const { data: surveyData } = await SurveyServices.updateFormById(
        store.surveyId,
        dataToBeSubmitted
      );
      store.builder.versionHistory = surveyData.data.versionHistory;
    }
    await sleep(2000);
    isSaving.value = false;
    await store.getCustomFieldFolders();
    await store.getCustomFields();
  } catch (err) {
    store.anyUnsavedChanges = true;
    isSaving.value = false;
    sendNotification({ title: "Error, Retry again", description: err.message, type: "error" });
  }
};

const back = () => {
  store.anyUnsavedChanges ? setupAlertModalForBack() : router.go(-1);
};

const openPreview = async () => {
  if (containHTML() && !JSON.parse(localStorage.getItem("script_modal_hidden"))) {
    containHTMLWhilePreview.value = true;
    setupAlertModalForScript();
  } else if (store.anyUnsavedChanges) {
    await formSaveAndUpdate();
    redirectToPreview();
  } else {
    redirectToPreview();
  }
};

const redirectToPreview = () => {
  if (store.formId) {
    window
      .open(`${store.previewDomain}/widget/form/${store.formId}?notrack=true`, "_blank")
      .focus();
  } else if (store.surveyId) {
    window
      .open(`${store.previewDomain}/widget/survey/${store.surveyId}?notrack=true`, "_blank")
      .focus();
  } else {
    window
      .open(`${store.previewDomain}/widget/quiz/${store.quizId}?notrack=true`, "_blank")
      .focus();
  }
};

const goBackToMain = () => {
  router.go(-1);
};

const openIntegrateFormAndSave = async () => {
  integrateModel.value = true;
  if (containHTML()) {
    setupAlertModalForScript();
  } else if (store.anyUnsavedChanges) {
    await formSaveAndUpdate();
  }
};

const updateCheckBox = (isChecked: boolean) => {
  scriptModalHiddenCheckbox.value = isChecked;
};

const feedback = async (modalName, result) => {
  localStorage.setItem("script_modal_hidden", scriptModalHiddenCheckbox.value.toString());
  if (modalName === "BACK" && result) {
    goBackToMain();
  }
  if (modalName === "SCRIPT" && result) {
    await formSaveAndUpdate();
    if (containHTMLWhilePreview.value) {
      containHTMLWhilePreview.value = false;
      redirectToPreview();
    }
  }
  openAlertModal.value = false;
};

const containHTML = () => {
  if (store.formId) {
    return store.elements.some((element) => element.tag === "html");
  }
  let containAnyHTMLInSurvey = false;
  store.slides.forEach((slide) => {
    if (slide.slideData.some((element) => element.tag === "html")) {
      containAnyHTMLInSurvey = true;
    }
  });
  return containAnyHTMLInSurvey;
};

const integrationAction = (result) => {
  integrateModel.value = result;
};

const popModalChange = () => {
  openAlertModal.value = false;
};

const saveFormOrUpdate = async () => {
  if (containHTML()) {
    setupAlertModalForScript();
  } else if (!containHTML()) {
    await formSaveAndUpdate();
  }
};

const setupAlertModalForScript = () => {
  title.value = `${t("common.alert")}!`;
  description.value = store.formId
    ? `${t("form.thirdPartyScriptAlertMessage")}`
    : `${t("survey.thirdPartyScriptAlertMessage")}`;
  positiveText.value = `${t("common.proceed")}`;
  negativeText.value = `${t("common.cancel")}`;
  modalName.value = "SCRIPT";
  openAlertModal.value = !JSON.parse(localStorage.getItem("script_modal_hidden"));
  if (!openAlertModal.value) {
    formSaveAndUpdate();
  }
};
const setupAlertModalForBack = () => {
  modalName.value = "BACK";
  title.value = `${t("common.alert")}!`;
  description.value = store.formId
    ? `${t("form.unsavedAlertMessage")}`
    : `${t("survey.unsavedAlertMessage")}`;
  positiveText.value = `${t("common.proceed")}`;
  negativeText.value = `${t("common.cancel")}`;
  openAlertModal.value = true;
};
const updateName = (event) => {
  const cursorPosition = window.getSelection().getRangeAt(0).startOffset;
  store.builder.name = event.target.textContent;
  setTimeout(() => {
    const range = new Range();
    const sel = window.getSelection();
    const textNode = event.target.firstChild;
    if (textNode) {
      range.setStart(textNode, Math.min(cursorPosition, textNode.length));
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }, 0);
  store.anyUnsavedChanges = true;
};
const handleEnterPress = (e: KeyboardEvent) => {
  if (e.key === "Enter" && formNameInput.value) {
    formNameInput.value?.blur();
  }
};

const paymentAndProductDetailsMap = () => {
  const products = store.addedProductList.map((product) => {
    return {
      productId: product.productId,
      showDescription: product.showDescription,
      showImage: product.showImage,
      showQuantity: product.showQuantity,
    };
  });
  store.payment.products = products;
  return store.payment;
};
</script>
<template>
  <div id="hl-builder-header">
    <UIToolbar class="header-toolbar p-0">
      <UIToolbarGroup class="min-w-[230px]">
        <div class="flex">
          <div class="border-right p-3">
            <div class="flex pr-3">
              <UIButton id="builder-back" text size="small" class="text-gray-800" @click="back">
                <ArrowLeftIcon class="mr-2 h-4 w-4" />
                <span>{{ t("common.back") }}</span>
              </UIButton>
            </div>
          </div>
        </div>
      </UIToolbarGroup>
      <UIToolbarGroup>
        <div class="builder-form-name flex items-center justify-center text-black">
          <div
            ref="formNameInput"
            :contenteditable="true"
            :class="`min-w-[1rem] max-w-2xl text-lg outline-none ${!editHeader ? 'truncate' : ''}`"
            @input="updateName"
            @click="editHeader = true"
            @focusout="
              editHeader = false;
              store.addToHistory();
            "
            @keydown="handleEnterPress"
          >
            {{ store.builder.name }}
          </div>
          <Edit01Icon
            v-if="!editHeader"
            class="ml-1 h-4 w-4 text-gray-700 hover:text-gray-500"
            @click="
              editHeader = true;
              formNameInput.focus();
            "
          />
        </div>
      </UIToolbarGroup>

      <UIToolbarGroup class="min-w-[230px]">
        <div class="flex items-center justify-end px-3 py-2">
          <!-- <div
            v-if="store.quizId"
            class="preview-border border-1 flex items-center justify-center rounded-lg p-[10px] px-2"
            @click="openPreview"
          >
            <EyeIcon class="h-4 w-4"></EyeIcon>
          </div> -->
          <UIButton
            id="integrate-btn"
            size="small"
            text
            class="mr-4 text-gray-800"
            @click="openPreview"
          >
            {{ t("common.preview") }}
          </UIButton>
          <UIButton
            id="integrate-btn"
            size="small"
            text
            class="mr-4 text-blue-600"
            @click="openIntegrateFormAndSave"
          >
            {{ t("common.integrate") }}
          </UIButton>
          <UIButton
            id="save-action"
            size="small"
            type="primary"
            :disabled="isSaving || !(store?.enableSaveButton ?? true)"
            @click="saveFormOrUpdate"
          >
            <span v-if="!isSaving">{{ t("common.save") }}</span>
            <span v-else>{{ t("common.saving") }}</span>
            <span
              v-if="store.anyUnsavedChanges"
              class="absolute right-0 top-0 z-10 -mr-1 -mt-1 flex h-3 w-3"
            >
              <span
                class="absolute inline-flex h-full w-full animate-ping rounded-full bg-amber-400 opacity-70"
              ></span>
              <span class="relative inline-flex h-3 w-3 rounded-full bg-amber-400"></span>
            </span>
          </UIButton>
        </div>
      </UIToolbarGroup>
    </UIToolbar>
    <AlertModal
      v-if="openAlertModal"
      :title="title"
      :description="description"
      :modal-name="modalName"
      :show-modal-popup="openAlertModal"
      :positive-text="positiveText"
      :negative-text="negativeText"
      :is-checkbox-enabled="true"
      :hide-modal="scriptModalHiddenCheckbox"
      header-type="warning"
      footer-type="primary"
      @pop:change="popModalChange"
      @pop:positive="feedback"
      @pop:negative="feedback"
      @pop:check="updateCheckBox"
    ></AlertModal>
    <IntegrateFormModal
      v-if="integrateModel && store.formId"
      :form-id="store.formId"
      :model-active="integrateModel"
      :form-style="store.formStyle"
      :form-name="store.builder.name"
      :base-url="store.integrate.baseUrl"
      :is-white-labelled="store.integrate.isWhiteLabelled"
      :form-layout-settings="store.integrate.formLayoutSettings"
      @update:show-model="integrationAction"
    >
    </IntegrateFormModal>
    <IntegrateSurveyModal
      v-if="integrateModel && store.surveyId"
      :survey-id="store.surveyId"
      :model-active="integrateModel"
      :survey-name="store.builder.name"
      :base-url="store.integrate.baseUrl"
      :is-white-labelled="store.integrate.isWhiteLabelled"
      @update:show-model="integrationAction"
    ></IntegrateSurveyModal>
    <IntegrateQuizModal
      v-if="integrateModel && store.quizId"
      :quiz-id="store.quizId"
      :model-active="integrateModel"
      :quiz-name="store.builder.name"
      :base-url="store.integrate.baseUrl"
      :is-white-labelled="store.integrate.isWhiteLabelled"
      @update:show-model="integrationAction"
    ></IntegrateQuizModal>
  </div>
</template>

<style scoped>
.header-toolbar {
  background-color: #ffffff !important;
  border-bottom: 0.5px solid #e2e2e2 !important;
}

.builder-form-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

#hl-builder-header .hl-toolbar-group {
  padding: 5px 0px;
}

.form-save-btn {
  width: 100%;
}

#hl-builder-header {
  position: fixed;
  z-index: 21;
  width: 100%;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.hl-toolbar-group {
  padding: 0px !important;
}

.preview-border {
  border: 1 solid #eff4ff;
  background-color: #eff4ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.result-button {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
</style>
