<template>
  <div
    v-if="condition?.outcome"
    class="flex cursor-default select-none items-center justify-between gap-x-4 p-3"
    :class="`${hoverClass} opacity-100`"
  >
    <div class="flex items-center gap-x-4">
      <slot name="handle"></slot>
      <div
        class="flex h-[56px] w-[56px] items-center justify-center rounded-full"
        :class="iconBgClass"
      >
        <slot></slot>
      </div>
      <div class="flex w-[32rem] flex-col">
        <div :class="`text-sm font-semibold leading-5 ${textClass}`">
          {{ showButtonText(condition) }}
        </div>
        <div
          v-for="(conditionItem, index) in condition?.conditions.slice(0, 5)"
          :key="index"
          class="text-xs leading-5 text-gray-600"
        >
          <span :class="`uppercase ${textClass}`">{{
            index === 0 ? "IF" : condition.conditionalOperation
          }}</span>
          {{ getFieldName(conditionItem.selectedField) }}
          <span :class="`uppercase ${textClass} mr-1`">{{
            camelToPascal(conditionItem.selectedOperation)
          }}</span>
          <span v-if="![IS_EMPTY, IS_FILLED].includes(conditionItem.selectedOperation)">{{
            conditionItem.inputValue
          }}</span>
        </div>
        <div v-if="condition?.conditions.length > 5" class="text-xs leading-5 text-gray-600">
          ...and <span :class="textClass">{{ condition.conditions.length - 5 }}</span> other
          <span :class="textClass">IF</span> rules ...
        </div>
        <div class="flex text-xs leading-5 text-gray-600">
          <span :class="`uppercase ${textClass} min-w-fit`">
            {{ outcomeText(condition) }}
            &nbsp;</span
          >
          <span v-html="showOutcomeValue(condition)" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-x-4">
      <UIButton id="edit-condition" text size="small" class="text-gray-800" @click="handleEdit">
        <Edit01Icon class="h-5 w-5" />
      </UIButton>
      <UIButton id="delete-condition" text size="small" class="text-gray-800" @click="handleDelete">
        <Trash01Icon class="h-5 w-5" />
      </UIButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import {
  colorBgHoverVariants,
  colorBgVariants,
  colorTextVariants,
  IS_EMPTY,
  IS_FILLED,
} from "@/util/constants";
import { camelToPascal } from "@/util/methods";
import { Edit01Icon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { ref, watchEffect } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "gray",
  },
  buttonText: {
    type: String,
    default: "Default Text",
  },
  outcomeType: { type: String, default: "Show/Hide Fields" },
  condition: {
    type: Object,
    default: null,
  },
});

const store = useAppStore();
const emit = defineEmits(["onEditButtonClick", "onDeleteButtonClick"]);

const iconBgClass = ref(colorBgVariants[props.color]);
const hoverClass = ref(colorBgHoverVariants[props.color]);
const textClass = ref(colorTextVariants[props.color]);

watchEffect(() => {
  iconBgClass.value = colorBgVariants[props.color];
  hoverClass.value = colorBgHoverVariants[props.color];
  textClass.value = colorTextVariants[props.color];
});

const getFieldName = (hiddenFieldQueryKey) => {
  return (
    store.elements.find((element) => element.hiddenFieldQueryKey === hiddenFieldQueryKey)?.label ||
    store.addressSettings.children.find(
      (element) => element.hiddenFieldQueryKey === hiddenFieldQueryKey
    )?.label
  );
};

const handleEdit = () => emit("onEditButtonClick");

const handleDelete = () => emit("onDeleteButtonClick");

const outcomeText = (condition) => {
  const outcome = condition?.outcome;
  return !outcome
    ? ""
    : outcome.type === "redirectToUrl" ||
      (outcome.type === "disqualifyLead" && outcome.disqualifyAction === "openUrl")
    ? "Open URL"
    : outcome.type === "displayCustomMessage" ||
      (outcome.type === "disqualifyLead" && outcome.disqualifyAction === "showCustomMessage")
    ? "SHOW"
    : outcome.type === "showHideFields"
    ? outcome.hideType
    : "";
};

const showOutcomeValue = (condition) => {
  const { outcome } = condition;
  if (outcome.type === "showHideFields" && outcome?.hideType.includes("Multiple")) {
    const fieldLabels = outcome.value.map((queryKey) => getFieldName(queryKey)).filter(Boolean);

    return fieldLabels.length > 5
      ? `${fieldLabels.slice(0, 5).join(", ")}... and <span class="${textClass.value}">${
          fieldLabels.length - 5
        }</span> other <span class="${textClass.value}">fields</span>..`
      : fieldLabels.join(", ");
  }

  return getFieldName(outcome.value) || outcome.value;
};

const showButtonText = (condition) => {
  const outcome = condition?.outcome;
  if (outcome?.type === "showHideFields") {
    switch (outcome?.hideType) {
      case "Hide Multiple": {
        return "Hide Fields";
      }
      case "Show Multiple": {
        return "Show Fields";
      }
      case "Hide": {
        return "Hide Field";
      }
      case "Show": {
        return "Show Field";
      }
      default: {
        return "";
      }
    }
  } else {
    return props.buttonText;
  }
};
</script>
