{
  "common": {
    "save": "Gem",
    "saving": "Gemmer",
    "integrate": "Integrer",
    "preview": "Forhåndsvisning",
    "back": "Tilbage",
    "alert": "Advarsel",
    "proceed": "Fortsæt",
    "cancel": "Annuller",
    "delete": "Slet",
    "embed": "Indlejr",
    "link": "Forbind",
    "iframeEmbed": "Iframe-indlejring",
    "styles": "Stilarter",
    "options": "Valgmuligheder",
    "quickAdd": "Hurtig tilføjelse",
    "customFields": "Brugerdefinerede felter",
    "personalInfo": "Personlige oplysninger",
    "contactInfo": "Kontaktoplysninger",
    "submit": "Indsend",
    "address": "Adresse",
    "customized": "Tilpasset",
    "layout": "Layout",
    "showLabel": "Vis mærke",
    "background": "Baggrund",
    "border": "Kant",
    "borderType": "Kanttype",
    "cornerRadius": "Hjørneradius",
    "width": "Bredde",
    "padding": "Polstring",
    "top": "Top",
    "bottom": "Bund",
    "left": "Venstre",
    "right": "Højre",
    "shadow": "Skygge",
    "color": "Farve",
    "horizontal": "Vandret",
    "vertical": "Lodret",
    "blur": "Sløring",
    "spread": "Spredning",
    "fieldStyle": "Feltstil",
    "fontColor": "Skriftfarve",
    "label": "Mærke",
    "fontFamily": "Skriftfamilie",
    "size": "Størrelse",
    "weight": "Tykkelse",
    "placeholder": "Pladsholder",
    "customCSS": "Brugerdefineret CSS",
    "backgroundImage": "Baggrundsbillede",
    "headerImage": "Overskriftsbillede",
    "miscellaneous": "Diverse",
    "agencyBranding": "Virksomhedens branding",
    "imageUploadMessage": "Klik eller træk en billedfil til dette område for at uploade",
    "backgroundImageTooltipMessage": "Baggrundsbilledet er beregnet til at dække hele siden og give en visuelt tiltalende baggrund for formularen. Klik på Forhåndsvisning for at se baggrundsbilledet.",
    "headerImageTooltipMessage": "Overskriftsbilledet er designet til at dække hele formularens bredde og forbliver placeret øverst i formularen. Klik på Forhåndsvisning for at se headerbilledet.",
    "onSubmit": "Ved indsendelse",
    "facebookPixelID": "Facebook Pixel-id",
    "facebookPixelIDMessage": "Ignorer dette felt, hvis du planlægger at bruge denne formular/undersøgelse inde i en tragt",
    "facebookPixelEvents": "Facebook Pixel-events",
    "onpageView": "Ved sidevisning",
    "stickyContact": "Fastgjort kontakt",
    "stickyContactMessage": "Fastgjort kontakt udfylder automatisk kontaktoplysninger, der tidligere er indtastet, så du ikke behøver at indtaste dem to gange.",
    "fieldDescription": "Beskrivelse af feltet",
    "fieldTitle": "Titel på felt",
    "queryKey": "Forespørgselsnøgle",
    "querykeyTooltipMessage": "Forespørgselsnøglen, der kan bruges som URL-parameter til at udfylde dette felt",
    "required": "Påkrævet",
    "hidden": "Skjult",
    "hiddenValue": "Skjult værdi",
    "enableCountryPicker": "Aktivér landevælger",
    "altText": "Alt-tekst",
    "alignment": "Justering",
    "imageWidth": "Billedets bredde",
    "imageHeight": "Billedets højde",
    "logic": "Logik",
    "pleaseInput": "Indtast",
    "editHTML": "Rediger HTML",
    "textStyle": "Tekststil",
    "subText": "Undertekst",
    "text": "Tekst",
    "buttonStyle": "Knapstil",
    "fullWidth": "Fuld bredde",
    "htmlMessage": "Den tilpassede HTML skal stå her",
    "htmlEditorHeader": "TILPASSET JAVASCRIPT/HTML",
    "htmlEditorBodyMessage": "Tilføj din kode her",
    "yesSave": "Ja, gem",
    "cancelKeep": "Annuller, behold",
    "done": "Færdig",
    "addElement": "Tilføj element",
    "addUrlLike": "Tilføj gyldige webadresser som",
    "doNotAddRelativePath": "Tilføj ikke relative stier som",
    "or": "eller",
    "addCustomFields": "Tilføj tilpassede felter",
    "customFieldSearch": "Søg efter navn",
    "enterSourceValue": "Indtast kildeværdi",
    "shortLabel": "Kort beskrivelse",
    "customCSSTooltip": "Tilpasset CSS har forrang frem for formularstyling og temaer og kan have indflydelse på temaets styling.",
    "fontFamilyTooltip": "Builder understøtter alle typer skrifttyper. Hvis du ikke kan se den på listen, kan du søge i rullelisten",
    "useGDPRCompliantFont": "Brug en GDPR-kompatibel skrifttype",
    "enableTimezone": "Aktivér tidszone",
    "inputStyle": "Indtastningsstil",
    "conditionalLogicAlertOnSubmit": "Betinget logik har forrang over meddelelsen/omdirigeringen her",
    "conditionalLogicAlertOnShowHide": "Betinget logik har forrang over de skjulte indstillinger her",
    "placeholderFacebookPixelIdInput": "Indtast Facebook Pixel-id",
    "doNotShowMessage": "Vis ikke igen",
    "themes": {
      "themes": "Temaer",
      "alertThemeMessage": "Hvis du skifter tema, vil du miste alle de nuværende stilændringer.",
      "useTheme": "Brug tema",
      "removeTheme": "Fjern tema"
    },
    "box": "Felt",
    "line": "Linje",
    "message": "Besked",
    "openUrl": "Åbn webadresse",
    "borderTypeList": {
      "dotted": "Prikket",
      "dashed": "Stiplet",
      "solid": "Fast",
      "none": "Ingen"
    },
    "button": "Knap",
    "emailNotification": "E-mailnotifikation",
    "emailNotificationSubheader": "Modtag en e-mail, når nogen udfylder din formular",
    "subject": "Emne",
    "emailTo": "E-mail (til)",
    "senderName": "Afsenderens navn",
    "senderNameWarning": "Dit (underkontonavn) vil blive tilføjet, hvis det efterlades tomt",
    "emailError": "Der kræves mindst én e-mailadresse",
    "emailTooltip": "Den første e-mailadresse er angivet til feltet 'Til'. Eventuelle efterfølgende e-mailadresser vil blive inkluderet i 'Cc'-feltet.",
    "clear": "Fjern",
    "showNotificationsSettings": "Vis meddelelsesindstillinger",
    "emailServicePaid": "Denne funktion er en betalt tjeneste og vil medføre gebyrer baseret på dit brug.",
    "integration": "Integration",
    "payment": {
      "payments": "Betalinger",
      "connected": "Tilsluttet",
      "connect": "Tilslut",
      "liveModeOn": "Live-tilstand",
      "status": "Status",
      "test": "Test",
      "live": "Live",
      "paymentFields": "Betalingsfelter",
      "suggestAnAmount": "Foreslå et beløb",
      "selectCurrency": "Vælg valuta",
      "tagActiveBGColor": "Farve på aktivt tag",
      "includeOtherAmount": "Inkluder andet beløb",
      "footerInfo": "Info om sidefod",
      "paymentType": "Betalingstype",
      "sellProducts": "Sælg produkter",
      "customAmount": "Brugerdefineret beløb",
      "collectPayment": "Opkræv betaling",
      "generalSettings": "Generelle felter",
      "product": {
        "addProduct": "Tilføj produkt",
        "productSettings": "Produktindstillinger",
        "selectProduct": "Vælg produkt",
        "editThisProduct": "Sådan redigerer du dette produkt",
        "clickHere": "Klik her",
        "show": "Vis",
        "description": "Beskrivelse",
        "image": "Billede",
        "layoutSettings": "Layout-indstillinger",
        "singleColumn": "Enkelt kolonne",
        "twoColumn": "To kolonner",
        "threeColumn": "Tre kolonner",
        "maxProductError": "Maksimalt 20 produkter er tilladt i en formular",
        "productSelectPlaceholder": "Vælg et produkt",
        "orderConfirmation": "Ordrebekræftelse",
        "orderConfirmationWarning": "Når betalingen er gennemført, vises en side med ordrebekræftelse",
        "quantity": "Mængde",
        "createProduct": "Sådan opretter du et produkt"
      },
      "coupons": {
        "coupons": "Kuponer",
        "enableCoupon": "Aktivér kupon",
        "couponAlignment": "Justering af kuponer",
        "left": "Venstre",
        "right": "Højre",
        "fullWidth": "Fuld bredde",
        "tooltipMessage": "Indstil placeringen af kuponinput fra nedenstående typer"
      }
    },
    "addressSettings": {
      "autoComplete": "Udfyld automatisk adresse",
      "addressfields": "Adressefelter",
      "placeholderSearch": "Søg efter en adresse",
      "autoCompleteTooltip": "Giver mulighed for at søge og automatisk udfylde adressen. Denne funktion er betalingspligtig."
    },
    "productSelectionSubtext": "Tillad et eller flere produktvalg",
    "multi": "Multi",
    "single": "Enkelt",
    "productSelectionHeading": "Produktvalg",
    "autoResponder": "Autosvar",
    "autoResponderSubheader": "Send automatisk en e-mail til den person, der sender formularen (e-mailen indeholder en kopi af de oplysninger, vedkommende har indtastet i formularen)",
    "replyToEmail": "Svar til e-mail",
    "open": "Åben",
    "won": "Vundet",
    "lost": "Tabt",
    "abandon": "Forlad",
    "selectAPipeline": "Vælg en proces",
    "stage": "Trin",
    "status": "Status",
    "opportunityValue": "Værdi af mulighed",
    "createNewPipeline": "Opret ny pipeline",
    "editOpportunitySettings": "Rediger indstillinger for muligheder",
    "contact": "Kontakt",
    "opportunity": "Mulighed",
    "add": "Tilføj",
    "more": "Mere",
    "opportunityHeading": "Hvor ønsker du, at din mulighed skal opdateres?",
    "date": {
      "dateSettings": "Datoindstillinger",
      "dateFormat": "Datoformat",
      "dateSeparator": "Datoseparator",
      "dateFormatTooltipMessage": "Vælg et datoformat. D står for dag, M for måneder og Y for år",
      "dateSeparatorTooltipMessage": "Vælg en separator, der adskiller dag, måned og år i det format, der er valgt under Datoformat.",
      "disablePicker": "Deaktiver vælger"
    },
    "restoreVersion": "Gendan version",
    "versionHistory": "Versionshistorik",
    "errorMessage": "Fejl, prøv igen",
    "version": "Version:",
    "currentVersion": "Aktuel version",
    "messageStyling": "Styling af beskeder",
    "validateEmailHeader": "Bekræft e-mail",
    "validateEmailDescription": "Knappen bliver synlig, når brugeren har indtastet sin e-mailadresse. Denne funktion er designet til at hjælpe dig med at generere kundeemner af høj kvalitet.",
    "validatePhoneHeader": "Valider telefonnummer",
    "validatePhoneDescription": "Knappen bliver synlig, når brugeren indtaster sit telefonnummer. Denne funktion er designet til at hjælpe dig med at generere kundeemner af høj kvalitet.",
    "validatePhoneWarning": "For at aktivere SMS OTP-bekræftelse skal du sørge for, at dit telefonnummer er konfigureret i dine placeringsindstillinger",
    "style": {
      "formLayoutType": "Formulartype",
      "oneColumn": "En kolonne",
      "twoColumn": "To kolonner",
      "singleLine": "Enkelt linje",
      "fieldWidth": "Feltbredde",
      "labelAlignment": {
        "labelAlignment": "Justering af etiket",
        "labelWidth": "Etikettens bredde"
      },
      "fieldSpacing": "Afstand mellem felter",
      "color": "farve",
      "fontColor": "Skrifttypefarve",
      "inputBackground": "Baggrund for indtastning",
      "layout": "Layout",
      "colorsAndBackground": "Farver og baggrund",
      "advanceSettings": "Avancerede indstillinger",
      "inputField": "Indtastningsfelt",
      "borderWidth": "Kantbredde",
      "borderColor": "Kantfarve",
      "borderStyle": "Kantstil",
      "fontSize": "Skriftstørrelse",
      "fontWeight": "Skrifttykkelse",
      "labelColor": "Etikettens farve",
      "placeholderColor": "Farve på pladsholder",
      "shortLabelColor": "Farve på kort beskrivelse"
    },
    "replyToTooltip": "Tilpas standardmailadressen til svar ved at bruge {'{'}{'{'}contact.email{'}'}{'}'} for at aktivere svar til den kontakt, der indsender formularen.",
    "content": "Indhold",
    "calculations": "Beregninger",
    "uploadOptions": "Indstillinger for upload",
    "opportunitySettingsWarning": "Indstil indstillinger for muligheder ved at klikke på knappen Edit Opportunity Settings.",
    "question": "Spørgsmål",
    "createContactOnPartialSubmission": "Opret kontakt ved delvis indsendelse",
    "howItWorks": "Hvordan fungerer det?",
    "createContactOnPartialSubmissionDescription": "Afslut sliden, og klik på \"Next\". En ny kontakt bliver automatisk oprettet og gemt.",
    "swapWarningMessageSurvey": "Redigering eller udskiftning af muligheder vil påvirke dette brugerdefinerede felt alle steder, hvor feltet bruges. Det kan også nulstille betinget logik. ",
    "swapWarningMessageForm": "Redigering eller udskiftning af muligheder vil påvirke dette brugerdefinerede felt alle steder, hvor feltet bruges. ",
    "optionLabel": "Valgmærke",
    "calculationValue": "Beregning af værdi",
    "addOption": "Tilføj mulighed",
    "enableCalculations": "Aktivér beregninger"
  },
  "survey": {
    "typeOfBuilder": "Undersøgelse",
    "survyeStyle": "Undersøgelsens stil",
    "surveyName": "Navn på undersøgelse",
    "surveyElement": "Undersøgelseselement",
    "addSurveyElement": "Tilføj undersøgelseselement",
    "unsavedAlertMessage": "Undersøgelsen har arbejde, der ikke er gemt. Er du sikker på, at du vil forlade og kassere alt arbejde. der ikke er gemt?",
    "thirdPartyScriptAlertMessage": "Tredjepartsscripts inde i HTML-blokken kan give kraftig funktionalitet, men de medfører også risici for privatlivets fred, sikkerhed, ydeevne og sideadfærd. Derfor anbefaler vi, at du gennemgår indholdet af dette script, før du gemmer det i undersøgelsen.",
    "integrateHeader": "Integrer undersøgelse",
    "integrateSubHeader": "Brug følgende muligheder til at integrere din brugerdefinerede spørgeundersøgelse på dit website",
    "integrateCopyLinkMessage": "Kopiér linket nedenfor og del det nemt, hvor som helst du ønsker det.",
    "onSurveySubmission": "Ved indsendelse af undersøgelse",
    "surveySettings": "Indstillinger for undersøgelse",
    "oneQuestionAtTime": "Et spørgsmål ad gangen",
    "backButton": "Tilbage-knap",
    "backButtonTooltipMessage": "Tilbage-knappen hjælper brugeren med at flytte til det forrige slide",
    "disableAutoNavigation": "Deaktiver automatisk navigation",
    "disableAutoNavigationTooltipMessage": "Hvis den er slået til, vil undersøgelsen ikke automatisk gå til næste slide",
    "progressBar": "Statuslinje",
    "progressBarTooltipMessage": "Hvis du slår dette fra, fjernes statuslinjen fra undersøgelsen",
    "disableAnimation": "Deaktiver animation",
    "disableAnimationTooltipMessage": "Hvis du slår dette til, deaktiveres overgangsanimationenen for slides",
    "scrollToTop": "Rul til toppen",
    "scrollToTopTooltipMessage": "Hvis det er slået til, ruller undersøgelsen til toppen på næste slide",
    "footerHTML": "HTML til sidefod",
    "footerHTMLMessage": "Du kan skrive brugerdefineret CSS (ved hjælp af style tag) og HTML inde i samme felt",
    "editFooter": "Rediger sidefod",
    "disqualifyImmediately": "Diskvalificer øjeblikkeligt",
    "disqualifyAfterSubmit": "Diskvalificer efter indsendelse",
    "slideConflictAlertMessage": "Denne slide indeholder flere spørgsmål, der har en logik konfigureret. Dette kan medføre utilsigtet adfærd",
    "addSlide": "Tilføj slide",
    "settings": "Indstillinger",
    "delete": "Slet",
    "slideDeleteAlertMessage": "Er du sikker på, at du vil slette denne slide?",
    "slideDeleteBetweenAlertMessage": "Dette vil omarrangere de andre slides. Er du sikker på, at du vil slette denne dias?",
    "slideName": "Slide-navn",
    "slidePosition": "Slide-position",
    "jumpTo": "Gå til",
    "enableTimezoneSurvey": "Hvis du aktiverer denne mulighed, registreres oplysningerne om tidszonen for den person, der indsender undersøgelsen.",
    "footer": {
      "footer": "Sidefod",
      "enableNewFooter": "Aktivér ny sidefod",
      "stickToSurvey": " Sæt fast på undersøgelse",
      "backgroundFill": "Baggrundsudfyldning",
      "footerHeight": "Højde på sidefod",
      "enableProgressBar": "Aktivér statuslinje",
      "buttons": "Knapper",
      "buttonType": "Knaptype",
      "fillNext": "Udfyld (næste)",
      "fillPrevious": "Udfyld (forrige)",
      "fillSubmit": "Udfyld (indsend)",
      "textColor": "Knaptekst",
      "position": "Position",
      "previousText": "Forrige (tekst)",
      "nextText": "Næste (tekst)",
      "submitText": "Indsend (tekst)",
      "fillComplete": "Helt udfyldt",
      "fillInactive": "Udfyld inaktiv",
      "footerType": "Sidefodstype",
      "stickToCard": "Sæt fast på kortet",
      "stickToPage": "Sæt fast på siden"
    },
    "surveyNotfication": "Meddelelse om undersøgelse",
    "style": {
      "surveyPadding": "Afstand til undersøgelse"
    },
    "autoResponderSubheader": "Send en automatiseret e-mail til den person, der indsender undersøgelsen (e-mailen indeholder en kopi af de oplysninger, de har indtastet i undersøgelsen)."
  },
  "form": {
    "typeOfBuilder": "Formular",
    "formStyle": "Formularstil",
    "formElement": "Formularelement",
    "addFormElement": "Tilføj formularelement",
    "addConditionalLogic": "Tilføj betinget logik",
    "inlineForm": "Indbygget formular",
    "formName": "Formularens navn",
    "fullScreenMode": "Fuldskærmstilstand",
    "fullScreenModetooltip": "Hvis du slår fuldskærmstilstand til, vil formularen udvide sig over hele beholderens bredde og strække sig ud til kanten af indlejringsområdet, mens den holder sig til formularens bredde.",
    "enableTimezoneForm": "Aktivering af denne indstilling registrerer tidszoneoplysningerne for den person, der indsender formularen",
    "formSettings": "Formularindstillinger",
    "unsavedAlertMessage": "Undersøgelsen har arbejde, der ikke er gemt. Er du sikker på, at du vil forlade og kassere alt arbejde. der ikke er gemt?",
    "thirdPartyScriptAlertMessage": "Tredjepartsscripts inde i HTML-blokken kan give kraftig funktionalitet, men de medfører også risici for privatlivets fred, sikkerhed, ydeevne og sideadfærd. Derfor anbefaler vi, at du gennemgår indholdet af dette script, før du gemmer det i formularen.",
    "onFormSubmission": "Ved indsendelse af formular",
    "integrateForm": "Integrer formular",
    "embedlayoutType": "Integrer layouttype",
    "embedlayoutTypeSubMessage": "Vælg et layout, der skal integreres i en tragt eller et eksternt website",
    "triggerType": "Udløsertype",
    "showOnScrolling": "Vis ved rulning",
    "showAfter": "Vis efter",
    "seconds": "sekunder",
    "alwaysShow": "Vis altid",
    "activationOptions": "Indstillinger for aktivering",
    "activateOn": "Aktiver ved",
    "visit": "besøg",
    "alwaysActivated": "Altid aktiveret",
    "deactivationOptions": "Indstillinger for deaktivering",
    "deactivateAfterShowing": "Deaktiver efter visning",
    "times": "gange",
    "deactiveOnceLeadCollected": "Deaktiver, når kundeemnet er indsamlet",
    "neverDeactivate": "Deaktiver aldrig",
    "copyEmbedCode": "Kopiér indlejringskode",
    "copyFormLink": "Kopiér link til formular",
    "openFormLink": "Åbn link til formular",
    "inline": "Indbygget",
    "inlineDescription": "En indbygget formular vises sammen med indholdet af siden som et indbygget element. Det overlejrer ikke og kan ikke lukkes eller minimeres.",
    "inlineHoverDescription": "En indbygget formular vises sammen med indholdet af siden som et indbygget element. Det overlejrer ikke og kan ikke lukkes eller minimeres.",
    "popup": "Pop op",
    "popupDescription": "En pop op viser formularen som en overlejring i en lysboks. Det kan ikke minimeres, men lukkes.",
    "popupHoverDescription": "En pop op viser formularen som en overlejring i en lysboks. Det kan ikke minimeres, men lukkes.",
    "politeSlideIn": "Midlertidig visning",
    "politeSlideInDescription": "En midlertidig visning vises nederst på skærmen og forsvinder, når den lukkes",
    "politeSlideInHoverDescription": "Vises på bunden af skærmen og forsvinder, når den er lukket",
    "stickySidebar": "Fastgjort sidebjælke",
    "stickySidebarDescription": "En fastgjort sidebjælke vises i siden af skærmen og kan minimeres til en fane, der sidder fast i siden af vinduet",
    "stickySidebarHoverDescription": "Vises i siden af skærmen og kan minimeres til en fane, der sidder fast i siden af vinduet",
    "allowMinimize": "Tillad minimering",
    "conditonalLogic": {
      "conditions": "Betingelser",
      "noExistingCondition": "Ingen eksisterende betingelser endnu",
      "freeRoamMessage": "Du kan frit bevæge dig rundt uden regler – i hvert fald indtil videre.",
      "addNewCondition": "Tilføj ny tilstand",
      "redirectToUrl": "Omdiriger til URL",
      "displayCustomMessage": "Vis brugerdefineret meddelelse",
      "disqualifyLead": "Diskvalificer kundeemne",
      "showHideFields": "Vis/skjul felter",
      "openBelowUrl": "Åbn nedenstående webadresse",
      "showCustomMessage": "Vis brugerdefineret besked",
      "alertConditionDelete": "Er du sikker på, at du vil slette denne betingelse?",
      "deleteCondition": "Slet betingelse",
      "conditionExecutedMessage": "Betingelserne vil blive udført i en top-down-rækkefølge.",
      "allOperationOptions": {
        "isEqualTo": "Er lig med",
        "isNotEqualTo": "Er ikke lig med",
        "contains": "Indeholder",
        "startsWith": "Starter med",
        "endsWith": "Slutter med",
        "isEmpty": "Er tom",
        "isFilled": "Er fyldt",
        "before": "Før",
        "after": "Efter",
        "greaterThan": "Større end",
        "lessThan": "Mindre end"
      },
      "logic": {
        "if": "Hvis",
        "then": "Så",
        "and": "Og",
        "or": "Eller"
      },
      "phoneAlertMessage": "Hvis du vil søge efter telefonnumre med en landekode, skal du udtrykkeligt inkludere symbolet '+' i din søgeforespørgsel.",
      "deleteAlertMessage": "Er du sikker på, at du vil slette dette felt? Den betingelse, der er knyttet til dette felt, vil også blive fjernet.",
      "deleteField": "Slet felt"
    },
    "minimizedTitlePlaceholder": "Minimeret titel",
    "dragYourElementFromLeft": "Træk dine elementer hertil fra venstre",
    "formNotification": "Formularnotifikation",
    "style": {
      "formPadding": "Afstand til formular"
    }
  },
  "quiz": {
    "quizElement": "Quizelement",
    "quizNotification": "Quiznotifikation",
    "viewAndCustomise": "Se og tilpas",
    "categoryAndScore": "Kategori og score",
    "provideFeedback": "Giv feedback",
    "addQuizElement": "Tilføj quizelement",
    "typeOfBuilder": "Quiz",
    "catgory": {
      "manageCategories": "Administrer kategorier"
    },
    "quizSettings": "Indstillinger for quiz",
    "onQuizSubmission": "Ved indsendelse af quiz",
    "settings": "Indstillinger",
    "deletePage": "Slet side",
    "quizResult": {
      "resultTemplate": "Resultat skabelon",
      "quizResultAlertMessage": "Den valgte resultatside vil blive vist, når quizzen er afsluttet"
    },
    "autoResponderSubheader": "Send automatisk en e-mail til den person, der sender quizzen (e-mailen indeholder en kopi af de oplysninger, vedkommende har indtastet i quizzen)",
    "integrateHeader": "Integrer quiz",
    "integrateSubHeader": "Brug følgende muligheder for at integrere din brugerdefinerede quiz på dit website",
    "integrateCopyLinkMessage": "Kopiér linket nedenfor og del det nemt, hvor som helst du ønsker det.",
    "pageName": "Sidenavn",
    "pagePosition": "Sideposition"
  }
}