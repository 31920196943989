<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { standardFields } from "@/util/methods";
import { PRODUCT_TYPE } from "@/util/constants";
import { UIDraggableElement, UITextXsMedium } from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { VueDraggableNext } from "vue-draggable-next";

const store = useAppStore();

if (store.product === PRODUCT_TYPE.FORM) {
  delete standardFields.quizQuestions;
}
if (store.product === PRODUCT_TYPE.SURVEY) {
  delete standardFields.submit;
  delete standardFields.quizQuestions;
}
if (store.product === PRODUCT_TYPE.QUIZ) {
  delete standardFields.submit;
  delete standardFields.integration;
  delete standardFields.choiceElements;
  standardFields.other.group.splice(3, 1);
  standardFields.text.group.splice(0, 2);
}
if (!store.isPaymentEnabled) {
  //Removing paymnet from form
  delete standardFields.integration;
}

if (![PRODUCT_TYPE.FORM, PRODUCT_TYPE.SURVEY].includes(store.product)) {
  const index = standardFields.address.group.findIndex((group) => group.tag === "group_address");
  if (index > -1) {
    standardFields.address.group.splice(index, 1);
  }
  const addressIndex = standardFields.address.group.findIndex((group) => group.tag === "address");
  if (addressIndex > -1) {
    standardFields.address.group[addressIndex].label = "Address";
    standardFields.address.group[addressIndex].hideInLeftSideBar = false;
  }
}

const quickAdd = ref(standardFields);
</script>
<template>
  <div id="standard-container" class="standard-list-container">
    <div v-for="(category, index) in quickAdd" :key="index" class="flex flex-col gap-[12px]">
      <UITextXsMedium class="mt-2 px-2">{{ category.label }}</UITextXsMedium>
      <VueDraggableNext
        :list="category.group"
        :sort="false"
        class="standard-draggable-container gap-2"
        ghost-class="sortable-ghost"
        :group="{ name: 'elements', pull: 'clone', put: false }"
      >
        <template v-for="(group, i) in category.group" :key="i">
          <div :class="group.hideInLeftSideBar ? 'hidden' : 'p-1'">
            <UIDraggableElement
              :id="group.label + i"
              :icon="(group.icon as any)"
              :label="group.label"
            />
          </div>
        </template>
      </VueDraggableNext>
    </div>
  </div>
</template>

<style>
.standard-list-container {
  height: 84vh;
  padding-top: 5px;
  padding-bottom: 50px;
  padding-left: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.element-cards .gui__builder-card {
  display: table;
  height: 100%;
}

#standard-container .gui__builder-card--label {
  padding-bottom: 5px;
  text-overflow: clip;
}

#standard-container .gui__builder-card {
  width: 100px;
  height: 100px;
  padding: 12px 0px;
  position: relative;
}
.standard-item {
  background-color: white;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  cursor: all-scroll;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
.standard-draggable-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}
.standard-item {
  box-shadow: 1px 1px 0px 0px #dee2e6;
}
.icon-container {
  width: 15px;
  height: 15px;
  margin: auto 0;
  padding: 0;
  align-items: center;
  display: flex;
}

.gui__builder-card--icon img,
.gui__builder-card--icon svg {
  height: 60px !important;
  width: 60px !important;
}
.gui__builder-card--icon svg {
  height: 35px;
  width: 35px;
}
</style>
