{
  "common": {
    "save": "Guardar",
    "saving": "Guardando",
    "integrate": "Integrar",
    "preview": "Previsualizar",
    "back": "Atrás",
    "alert": "Alerta",
    "proceed": "Proceder",
    "cancel": "Cancelar",
    "delete": "Borrar",
    "embed": "Insertar",
    "link": "Enlazar",
    "iframeEmbed": "Incrustación de iframe",
    "styles": "Estilos",
    "options": "Opciones",
    "quickAdd": "Añadir rápidamente",
    "customFields": "Campos personalizados",
    "personalInfo": "Información personal",
    "contactInfo": "Información de contacto",
    "submit": "Enviar",
    "address": "Dirección",
    "customized": "Personalizado",
    "layout": "Diseño",
    "showLabel": "Mostrar etiqueta",
    "background": "Fondo",
    "border": "Borde",
    "borderType": "Tipo de borde",
    "cornerRadius": "Radio de la esquina",
    "width": "Ancho",
    "padding": "Margen",
    "top": "Superior",
    "bottom": "Inferior",
    "left": "Izquierda",
    "right": "Derecha",
    "shadow": "Sombra",
    "color": "Color",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Difuminar",
    "spread": "Propagar",
    "fieldStyle": "Estilo de campo",
    "fontColor": "Color de la fuente",
    "label": "Etiqueta",
    "fontFamily": "Familia tipográfica",
    "size": "Tamaño",
    "weight": "Peso",
    "placeholder": "Marcador de posición",
    "customCSS": "CSS personalizado",
    "backgroundImage": "Imagen de fondo",
    "headerImage": "Imagen de cabecera",
    "miscellaneous": "Miscelánea",
    "agencyBranding": "Elementos de marca de la agencia",
    "imageUploadMessage": "Haz clic o arrastra un archivo de imagen a esta área para subirlo",
    "backgroundImageTooltipMessage": "La imagen de fondo está pensada para cubrir toda la página, proporcionando un telón de fondo visualmente atractivo para el formulario. Para ver la imagen de fondo, haz clic en la opción de previsualización.",
    "headerImageTooltipMessage": "La imagen del encabezado está diseñada para cubrir todo el ancho del formulario y permanece posicionada en la parte superior del formulario. Para ver la imagen del encabezado, haz clic en la opción de previsualización.",
    "onSubmit": "Al enviar",
    "facebookPixelID": "ID de píxel de Facebook",
    "facebookPixelIDMessage": "Ignora este campo si planeas usar este formulario/encuesta dentro de un embudo",
    "facebookPixelEvents": "Eventos de píxel de Facebook",
    "onpageView": "En vista de página",
    "stickyContact": "Contacto fijo",
    "stickyContactMessage": "Contacto fijo rellenará automáticamente la información de contacto introducida previamente para que no tengas que introducirla dos veces.",
    "fieldDescription": "Descripción del campo",
    "fieldTitle": "Título del campo",
    "queryKey": "Clave de consulta",
    "querykeyTooltipMessage": "La clave de consulta que puede usarse como parámetro de URL para rellenar este campo",
    "required": "Obligatorio",
    "hidden": "Oculto",
    "hiddenValue": "Valor oculto",
    "enableCountryPicker": "Habilitar el selector de países",
    "altText": "Texto alternativo",
    "alignment": "Alineación",
    "imageWidth": "Ancho de la imagen",
    "imageHeight": "Altura de la imagen",
    "logic": "Lógica",
    "pleaseInput": "Introduce",
    "editHTML": "Editar HTML",
    "textStyle": "Estilo de texto",
    "subText": "Subtexto",
    "text": "Texto",
    "buttonStyle": "Estilo de botón",
    "fullWidth": "Ancho completo",
    "htmlMessage": "El HTML personalizado va aquí",
    "htmlEditorHeader": "JAVASCRIPT / HTML PERSONALIZADO",
    "htmlEditorBodyMessage": "Añade tu código aquí",
    "yesSave": "Sí, Guardar",
    "cancelKeep": "Cancelar, mantener",
    "done": "Hecho",
    "addElement": "Añadir elemento",
    "addUrlLike": "Añade URL válidas como",
    "doNotAddRelativePath": "No añadas rutas relativas como",
    "or": "o",
    "addCustomFields": "Añadir campos personalizados",
    "customFieldSearch": "Buscar por nombre",
    "enterSourceValue": "Introduce el valor de origen",
    "shortLabel": "Etiqueta corta",
    "customCSSTooltip": "Los CSS personalizados tienen prioridad sobre el estilo del formulario y los temas y pueden tener un impacto en el estilo del tema.",
    "fontFamilyTooltip": "El creador admite todo tipo de fuentes, aunque no puedas verla en la lista. Búscala en el menú desplegable.",
    "useGDPRCompliantFont": "Usa una fuente compatible con el RGPD",
    "enableTimezone": "Habilitar zona horaria",
    "inputStyle": "Estilo de entrada",
    "conditionalLogicAlertOnSubmit": "La lógica condicional tendrá prioridad sobre el mensaje/redirigirá aquí",
    "conditionalLogicAlertOnShowHide": "La lógica condicional tendrá prioridad sobre la configuración oculta aquí",
    "placeholderFacebookPixelIdInput": "Introduce el ID de píxel de Facebook",
    "doNotShowMessage": "No mostrar de nuevo",
    "themes": {
      "themes": "Temas",
      "alertThemeMessage": "Cambiar de tema hará que se pierdan todas las modificaciones de estilo actuales.",
      "useTheme": "Usar tema",
      "removeTheme": "Eliminar tema"
    },
    "box": "Cuadro",
    "line": "Línea",
    "message": "Mensaje",
    "openUrl": "Abrir URL",
    "borderTypeList": {
      "dotted": "Punteado",
      "dashed": "Discontinuo",
      "solid": "Liso",
      "none": "Ninguno"
    },
    "button": "Botón",
    "emailNotification": "Notificación por correo electrónico",
    "emailNotificationSubheader": "Recibir un correo electrónico cuando alguien rellene tu formulario",
    "subject": "Asunto",
    "emailTo": "Correo electrónico (Para)",
    "senderName": "Nombre del remitente",
    "senderNameWarning": "Tu (nombre de la subcuenta) se añadirá si se deja en blanco",
    "emailError": "Se requiere al menos una dirección de correo electrónico",
    "emailTooltip": "La primera dirección de correo electrónico está destinada al campo «Para». Cualquier dirección de correo electrónico posterior se incluirá en el campo «Cc».",
    "clear": "Borrar",
    "showNotificationsSettings": "Mostrar configuración de notificaciones",
    "emailServicePaid": "Esta función es un servicio de pago y generará cargos en función del uso.",
    "integration": "Integración",
    "payment": {
      "payments": "Pagos",
      "connected": "Conectado",
      "connect": "Conectar",
      "liveModeOn": "Modo en tiempo real",
      "status": "Estado",
      "test": "Prueba",
      "live": "En tiempo real",
      "paymentFields": "Campos de pago",
      "suggestAnAmount": "Sugerir una cantidad",
      "selectCurrency": "Seleccionar moneda",
      "tagActiveBGColor": "Color de la etiqueta activa",
      "includeOtherAmount": "Incluir otra cantidad",
      "footerInfo": "Información de pie de página",
      "paymentType": "Tipo de pago",
      "sellProducts": "Vender productos",
      "customAmount": "Cantidad personalizada",
      "collectPayment": "Cobrar el pago",
      "generalSettings": "Campos generales",
      "product": {
        "addProduct": "Añadir producto",
        "productSettings": "Configuración del producto",
        "selectProduct": "Seleccionar producto",
        "editThisProduct": "Para editar este producto",
        "clickHere": "Haz clic aquí",
        "show": "Mostrar",
        "description": "Descripción",
        "image": "Imagen",
        "layoutSettings": "Configuración del diseño",
        "singleColumn": "Columna única",
        "twoColumn": "Dos columnas",
        "threeColumn": "Tres columnas",
        "maxProductError": "Se permiten un máximo de 20 productos en un formulario",
        "productSelectPlaceholder": "Selecciona un producto",
        "orderConfirmation": "Confirmación del pedido",
        "orderConfirmationWarning": "Se mostrará una página de confirmación del pedido una vez que el pago se haya realizado correctamente",
        "quantity": "Cantidad",
        "createProduct": "Para crear un producto"
      },
      "coupons": {
        "coupons": "Cupones",
        "enableCoupon": "Habilitar cupón",
        "couponAlignment": "Alineación de cupones",
        "left": "Izquierda",
        "right": "Derecha",
        "fullWidth": "Ancho completo",
        "tooltipMessage": "Determina la posición de la introducción de un cupón entre los siguientes tipos"
      }
    },
    "addressSettings": {
      "autoComplete": "Completar automáticamente la dirección",
      "addressfields": "Campos de dirección",
      "placeholderSearch": "Buscar una dirección",
      "autoCompleteTooltip": "Ofrece la capacidad de buscar y completar automáticamente la dirección. Esta función es de pago."
    },
    "autoResponder": "Respuesta automática",
    "autoResponderSubheader": "Enviar un correo electrónico automatizado a la persona que envía el formulario (el correo electrónico incluye una copia de la información que ha introducido en el formulario)",
    "replyToEmail": "Responder al correo electrónico",
    "open": "Abierto",
    "won": "Ganado",
    "lost": "Perdido",
    "abandon": "Abandonar",
    "selectAPipeline": "Selecciona una secuencia",
    "stage": "Paso",
    "status": "Estado",
    "opportunityValue": "Valor del cliente potencial",
    "createNewPipeline": "Crear una nueva secuencia",
    "editOpportunitySettings": "Editar la configuración de clientes potenciales",
    "contact": "Contacto",
    "opportunity": "Cliente potencial",
    "add": "Añadir",
    "more": "Más",
    "opportunityHeading": "¿Dónde quieres que se actualice tu oportunidad?",
    "date": {
      "dateSettings": "Configuración de fecha",
      "dateFormat": "Formato de fecha",
      "dateSeparator": "Separador de fechas",
      "dateFormatTooltipMessage": "Selecciona un formato de fecha. D representa el día, M el mes e Y el año",
      "dateSeparatorTooltipMessage": "Selecciona un separador para separar el día, el mes y el año en el formato seleccionado en el formato de fecha",
      "disablePicker": "Desactivar selector"
    },
    "restoreVersion": "Restaurar versión",
    "versionHistory": "Historial de versiones",
    "errorMessage": "Error, vuelve a intentarlo",
    "version": "Versión:",
    "currentVersion": "Versión actual",
    "messageStyling": "Estilo del mensaje",
    "validateEmailHeader": "Validar el correo electrónico",
    "validateEmailDescription": "El botón se hará visible una vez que el usuario introduzca su correo electrónico. Esta función está diseñada para ayudarte a generar clientes potenciales de calidad.",
    "validatePhoneHeader": "Validar el teléfono",
    "validatePhoneDescription": "El botón se hará visible una vez que el usuario introduzca su teléfono. Esta función está diseñada para ayudarte a generar clientes potenciales de calidad.",
    "validatePhoneWarning": "Para habilitar la verificación OTP por SMS, asegúrate de que tu número de teléfono esté configurado en la configuración de tu ubicación",
    "style": {
      "formLayoutType": "Tipo de formulario",
      "oneColumn": "Una columna",
      "twoColumn": "Dos columnas",
      "singleLine": "Una sola línea",
      "fieldWidth": "Ancho de campo",
      "labelAlignment": {
        "labelAlignment": "Alineación de etiquetas",
        "labelWidth": "Ancho de la etiqueta"
      },
      "fieldSpacing": "Espaciado entre campos",
      "color": "color",
      "fontColor": "Color de la fuente",
      "inputBackground": "Fondo de introducción",
      "layout": "Diseño",
      "colorsAndBackground": "Colores y fondo",
      "advanceSettings": "Configuración avanzada",
      "inputField": "Campo de entrada",
      "borderWidth": "Ancho del borde",
      "borderColor": "Color del borde",
      "borderStyle": "Estilo de borde",
      "fontSize": "Tamaño de la fuente",
      "fontWeight": "Grosor de la fuente",
      "labelColor": "Color de la etiqueta",
      "placeholderColor": "Color del marcador de posición",
      "shortLabelColor": "Color de etiqueta corta"
    },
    "replyToTooltip": "Personaliza la dirección de correo electrónico predeterminada de Responder a mediante {'{'}{'{'}contact.email{'}'}{'}'} para habilitar las respuestas al contacto que envía el formulario.",
    "content": "Contenido",
    "calculations": "Cálculos",
    "uploadOptions": "Opciones de subida",
    "opportunitySettingsWarning": "Ajusta la configuración de oportunidades haciendo clic en el botón Edit Opportunity Settings.",
    "question": "Pregunta",
    "createContactOnPartialSubmission": "Crear contacto en envío parcial",
    "howItWorks": "¿Cómo funciona?",
    "createContactOnPartialSubmissionDescription": "Termina la diapositiva y haz clic en «Next». Se creará y almacenará automáticamente un nuevo contacto.",
    "swapWarningMessageSurvey": "Las opciones de edición o intercambio afectarán a este campo personalizado en todos los lugares donde se use el campo. También puede restablecer la lógica condicional. ",
    "swapWarningMessageForm": "Editar o intercambiar opciones afectará a este campo personalizado en todos los lugares donde se use. ",
    "optionLabel": "Etiqueta de opción",
    "calculationValue": "Valor de cálculo",
    "addOption": "Añadir opción",
    "enableCalculations": "Activar cálculos",
    "productSelectionSubtext": "Permitir la selección de uno o varios productos",
    "multi": "Multi",
    "single": "Soltero",
    "productSelectionHeading": "Selección de productos"
  },
  "survey": {
    "typeOfBuilder": "Encuesta",
    "survyeStyle": "Estilo de encuesta",
    "surveyName": "Nombre de encuesta",
    "surveyElement": "Elemento de encuesta",
    "addSurveyElement": "Añadir elemento de encuesta",
    "unsavedAlertMessage": "Hay trabajo sin guardar en la encuesta. ¿Seguro que quieres abandonar y descartar todo el trabajo que no has guardado?",
    "thirdPartyScriptAlertMessage": "Las secuencias de código de terceros dentro del bloque HTML pueden proporcionar una funcionalidad potente, pero también conllevan riesgos para la privacidad, la seguridad, el rendimiento y el comportamiento de la página. Por lo tanto, te recomendamos que revises el contenido de este script antes de guardarlo en la encuesta.",
    "integrateHeader": "Integrar encuesta",
    "integrateSubHeader": "Usa las siguientes opciones para integrar tu encuesta personalizada en tu sitio web",
    "integrateCopyLinkMessage": "Copia el enlace a continuación y compártelo fácilmente donde quieras.",
    "onSurveySubmission": "En el envío de encuestas",
    "surveySettings": "Configuración de la encuesta",
    "oneQuestionAtTime": "Una pregunta cada vez",
    "backButton": "Botón de retroceso",
    "backButtonTooltipMessage": "El botón de retroceso ayudará al usuario a mover la diapositiva anterior",
    "disableAutoNavigation": "Desactivar la navegación automática",
    "disableAutoNavigationTooltipMessage": "Si esta opción está activada, la encuesta no pasará automáticamente a la siguiente diapositiva",
    "progressBar": "Barra de progreso",
    "progressBarTooltipMessage": "Al desactivar esta opción, se eliminará la barra de progreso de la encuesta",
    "disableAnimation": "Desactivar la animación",
    "disableAnimationTooltipMessage": "Al activar esta opción, se desactivará la animación de transición de la diapositiva",
    "scrollToTop": "Desplazarse hasta arriba",
    "scrollToTopTooltipMessage": "Si esta opción está activada, la encuesta se desplazará hasta la parte superior de la siguiente diapositiva",
    "footerHTML": "Pie de página HTML",
    "footerHTMLMessage": "Puedes escribir CSS personalizado (usando una etiqueta de estilo) y HTML dentro del mismo cuadro",
    "editFooter": "Editar pie de página",
    "disqualifyImmediately": "Descalificar inmediatamente",
    "disqualifyAfterSubmit": "Descalificar después de enviar",
    "slideConflictAlertMessage": "Esta diapositiva contiene varias preguntas que tienen una lógica establecida. Esto puede provocar algún comportamiento no deseado.",
    "addSlide": "Añadir diapositiva",
    "settings": "Configuración",
    "delete": "Eliminar",
    "slideDeleteAlertMessage": "¿Seguro que quieres eliminar esta diapositiva?",
    "slideDeleteBetweenAlertMessage": "Esta acción reorganizará las demás diapositivas. ¿Seguro que quieres eliminar esta diapositiva?",
    "slideName": "Nombre de la diapositiva",
    "slidePosition": "Posición de diapositiva",
    "jumpTo": "Saltar a",
    "enableTimezoneSurvey": "Al activar esta opción, se capturará la información de la zona horaria de la persona que envía la encuesta.",
    "footer": {
      "footer": "Pie de página",
      "enableNewFooter": "Habilitar nuevo pie de página",
      "stickToSurvey": " Cíñete a la encuesta",
      "backgroundFill": "Relleno de fondo",
      "footerHeight": "Altura del pie de página",
      "enableProgressBar": "Habilitar la barra de progreso",
      "buttons": "Botones",
      "buttonType": "Tipo de botón",
      "fillNext": "Rellenar (siguiente)",
      "fillPrevious": "Rellenar (anterior)",
      "fillSubmit": "Rellenar (Enviar)",
      "textColor": "Texto del botón",
      "position": "Posición",
      "previousText": "Anterior (texto)",
      "nextText": "Siguiente (texto)",
      "submitText": "Enviar (texto)",
      "fillComplete": "Rellenar completado",
      "fillInactive": "Rellenar inactivo",
      "footerType": "Tipo de pie de página",
      "stickToCard": "Fijar a la tarjeta",
      "stickToPage": "Fijar a la página"
    },
    "surveyNotfication": "Notificación de encuesta",
    "style": {
      "surveyPadding": "Margen de la encuesta"
    },
    "autoResponderSubheader": "Enviar un correo electrónico automatizado a la persona que envía el formulario (el correo electrónico incluye una copia de la información que ha introducido en el formulario)"
  },
  "form": {
    "typeOfBuilder": "Formulario",
    "formStyle": "Estilo del formulario",
    "formElement": "Elemento de formulario",
    "addFormElement": "Añadir elemento de formulario",
    "addConditionalLogic": "Añadir lógica condicional",
    "inlineForm": "Formulario en línea",
    "formName": "Nombre del formulario",
    "fullScreenMode": "Modo de pantalla completa",
    "fullScreenModetooltip": "Si esta opción está activada, el formulario se expandirá por todo el ancho de su contenedor y se extenderá hasta el borde del área de incrustación sin sobrepasar el ancho del formulario.",
    "enableTimezoneForm": "Al activar esta opción, se capturará la información de la zona horaria de la persona que envía el formulario",
    "formSettings": "Configuración del formulario",
    "unsavedAlertMessage": "Hay trabajo sin guardar en el formulario. ¿Seguro que quieres abandonar y descartar todo el trabajo que no has guardado?",
    "thirdPartyScriptAlertMessage": "Las secuencias de código de terceros dentro del bloque HTML pueden proporcionar una funcionalidad potente, pero también conllevan riesgos para la privacidad, la seguridad, el rendimiento y el comportamiento de la página. Por lo tanto, te recomendamos que revises el contenido de este script antes de guardarlo en la encuesta.",
    "onFormSubmission": "En el envío del formulario",
    "integrateForm": "Integrar formulario",
    "embedlayoutType": "Tipo de diseño incrustado",
    "embedlayoutTypeSubMessage": "Selecciona un diseño para incrustarlo en un embudo o en un sitio web externo",
    "triggerType": "Tipo de activador",
    "showOnScrolling": "Mostrar al desplazarse",
    "showAfter": "Mostrar después",
    "seconds": "segundos",
    "alwaysShow": "Mostrar siempre",
    "activationOptions": "Opciones de activación",
    "activateOn": "Activar el",
    "visit": "visitar",
    "alwaysActivated": "Siempre activado",
    "deactivationOptions": "Opciones de desactivación",
    "deactivateAfterShowing": "Desactivar después de mostrar",
    "times": "veces",
    "deactiveOnceLeadCollected": "Desactivar una vez que se haya recopilado el cliente potencial",
    "neverDeactivate": "No desactivar nunca",
    "copyEmbedCode": "Copiar código de incrustación",
    "copyFormLink": "Copiar enlace del formulario",
    "openFormLink": "Abrir enlace del formulario",
    "inline": "En línea",
    "inlineDescription": "Aparece un formulario en línea junto con el contenido de la página como elemento nativo. No se superpone y no se puede cerrar ni minimizar.",
    "inlineHoverDescription": "Aparece un formulario en línea junto con el contenido de la página como elemento nativo. No se superpone y no se puede cerrar ni minimizar.",
    "popup": "Ventana emergente",
    "popupDescription": "Una ventana emergente muestra el formulario como una superposición dentro de una caja de luz. No se puede minimizar, pero sí cerrar.",
    "popupHoverDescription": "Una ventana emergente muestra el formulario como una superposición dentro de una caja de luz. No se puede minimizar, pero sí cerrar.",
    "politeSlideIn": "Ventana deslizante respetuosa",
    "politeSlideInDescription": "Aparece una ventana deslizante en la parte inferior de la pantalla, que desaparece una vez cerrada.",
    "politeSlideInHoverDescription": "Aparece en la parte inferior de la pantalla y desaparece una vez cerrada",
    "stickySidebar": "Barra lateral fija",
    "stickySidebarDescription": "Aparecerá una barra lateral fija en el lateral de la pantalla que puede minimizarse para convertirse en una pestaña fijada al lateral de la ventana",
    "stickySidebarHoverDescription": "Aparecen en el lateral de la pantalla y pueden minimizarse para convertirse en una pestaña fijada al lateral de la ventana",
    "allowMinimize": "Permitir minimizar",
    "conditonalLogic": {
      "conditions": "Condiciones",
      "noExistingCondition": "Todavía no existen condiciones",
      "freeRoamMessage": "Puedes ir por libre sin reglas… al menos, por ahora.",
      "addNewCondition": "Añadir nueva condición",
      "redirectToUrl": "Redirigir a la URL",
      "displayCustomMessage": "Mostrar un mensaje personalizado",
      "disqualifyLead": "Descalificar al cliente potencial",
      "showHideFields": "Mostrar/Ocultar campos",
      "openBelowUrl": "Abre la URL a continuación",
      "showCustomMessage": "Mostrar mensaje personalizado",
      "alertConditionDelete": "¿Seguro que quieres eliminar esta condición?",
      "deleteCondition": "Eliminar condición",
      "conditionExecutedMessage": "Las condiciones se ejecutarán en orden descendente.",
      "allOperationOptions": {
        "isEqualTo": "Es igual a",
        "isNotEqualTo": "No es igual a",
        "contains": "Contiene",
        "startsWith": "Empieza por",
        "endsWith": "Termina con",
        "isEmpty": "Está vacío",
        "isFilled": "Está rellenado",
        "before": "Antes",
        "after": "Después",
        "greaterThan": "Mayor que",
        "lessThan": "Menor que"
      },
      "logic": {
        "if": "Si",
        "then": "Entonces",
        "and": "Y",
        "or": "O"
      },
      "phoneAlertMessage": "Para buscar números de teléfono con un código de país, debes incluir explícitamente el símbolo «+» en la consulta de búsqueda.",
      "deleteAlertMessage": "¿Seguro que quieres eliminar este campo? También se eliminará la condición asociada a este campo.",
      "deleteField": "Eliminar campo"
    },
    "minimizedTitlePlaceholder": "Título minimizado",
    "dragYourElementFromLeft": "Arrastra tus elementos aquí desde la izquierda",
    "formNotification": "Notificación del formulario",
    "style": {
      "formPadding": "Margen del formulario"
    }
  },
  "quiz": {
    "quizElement": "Elemento del cuestionario",
    "quizNotification": "Notificación del cuestionario",
    "viewAndCustomise": "Ver y personalizar",
    "categoryAndScore": "Categoría y puntuación",
    "provideFeedback": "Proporcionar comentarios",
    "addQuizElement": "Añadir elemento del cuestionario",
    "typeOfBuilder": "Cuestionario",
    "catgory": {
      "manageCategories": "Administrar categorías"
    },
    "quizSettings": "Configuración del cuestionario",
    "onQuizSubmission": "En el envío del cuestionario",
    "settings": "Configuración",
    "deletePage": "Eliminar página",
    "quizResult": {
      "resultTemplate": "Plantilla de resultados",
      "quizResultAlertMessage": "La página de resultados elegida se mostrará una vez que se complete el cuestionario"
    },
    "autoResponderSubheader": "Enviar un correo electrónico automatizado a la persona que envía el cuestionario (el correo electrónico incluye una copia de la información que ha introducido en el cuestionario)",
    "integrateHeader": "Integrar cuestionario",
    "integrateSubHeader": "Usa las siguientes opciones para integrar tu cuestionario personalizado en tu sitio web",
    "integrateCopyLinkMessage": "Copia el enlace a continuación y compártelo fácilmente donde quieras.",
    "pageName": "Nombre de la página",
    "pagePosition": "Posición de la página"
  }
}