<template>
  <div class="mt-2.5">
    <div class="mb-2.5 flex items-center text-sm font-medium text-gray-700">
      <div class="ml-6 flex flex-1">
        <div v-if="!isQuiz" class="w-[47%]">{{ t("common.optionLabel") }}</div>
        <div v-if="enableCalculations">{{ t("common.calculationValue") }}</div>
      </div>
    </div>
    <VueDraggableNext
      v-model="options"
      handle=".handle"
      :class="[store.quizId ? 'space-y-8' : 'space-y-2']"
      @change="(e) => changeInOptions(e)"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        class="flex items-start justify-start space-x-2 self-stretch text-sm"
      >
        <Menu05Icon class="handle mt-2.5 h-4 w-4 cursor-move align-top text-gray-500" />
        <div :class="productBuilderClass">
          <UIInput
            :id="`option-label-${index}`"
            v-model="options[index].label"
            class="w-full"
            placeholder="Label"
            :status="errors[index] ? 'error' : ''"
          />
          <UIInputNumber
            v-if="enableCalculations"
            :id="`option-value-${index}`"
            v-model="options[index].calculatedValue"
            :show-button="false"
            size="large"
            class="w-full"
            placeholder="Value"
            :status="errors[index] ? 'error' : ''"
          />
          <div v-if="isQuiz" class="flex flex-col gap-3">
            <CategoryAndScore
              :index="index"
              :selected-category="getSelectedCategoryAndScore(index)"
              @update:value="categoryAndScoreChange"
            ></CategoryAndScore>
            <Switch
              :value="jumpEnabled[index]"
              :label="'Jump to'"
              @update:value="(value) => updateJumpTo(value, index)"
            />
            <div v-if="jumpEnabled[index]" class="space-y-1">
              <UITextSmMedium>Question</UITextSmMedium>
              <UISelect
                :id="'radio' + index"
                :value="getSelectLogicQuestion(index)"
                :options="questionOptions"
                @update:value="
                  (value) => logicOptionSelection(value, option, index, selectedElement.uuid)
                "
                @change="store.addToHistory()"
              ></UISelect>
            </div>
          </div>
        </div>

        <Trash01Icon
          :disabled="!options[index]"
          class="mt-2 h-5 w-5 cursor-pointer"
          @click="() => prepareToRemoveOption(index)"
        />
      </div>
    </VueDraggableNext>
    <UIButton id="add-option" text class="mt-4" :disabled="isLastOptionEmpty" @click="addOption">
      <PlusIcon class="mr-1.5 h-5 w-5" />
      {{ t("common.addOption") }}
    </UIButton>
  </div>
  <DeleteConfirmationModal
    :show="showDeleteModal"
    @confirm="removeOption"
    @cancel="cancelRemoveOption"
  />
</template>

<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import { Menu05Icon, PlusIcon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIInput, UIInputNumber, UISelect, UITextSmMedium } from "@gohighlevel/ghl-ui";
import { computed, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import Switch from "./../common/Elements/Switch.vue";
import CategoryAndScore from "./../quizzes/Elements/CategoryAndScore.vue";
import DeleteConfirmationModal from "./DeleteConfirmationModal.vue";
const { t } = useI18n();

const store = useAppStore();
const emitter = useEmitter();

interface QuestionOption {
  value: string;
  label: string;
}

const props = defineProps({
  isQuiz: {
    type: [Boolean, String],
    default: false,
  },
  options: {
    type: [Array<String>, Array<any>],
    default: () => {
      return [];
    },
  },
  questionOptions: {
    type: Array<QuestionOption>,
    default: () => {
      return [];
    },
  },
  customFieldCreated: {
    type: Boolean,
    default: false,
  },
  selectedElement: {
    type: Object,
    default: () => {
      return {};
    },
  },
  slideIndex: {
    type: Number,
    default: 0,
  },
  elementIndex: {
    type: Number,
    default: 0,
  },
  enableCalculations: {
    type: Boolean,
    default: false,
  },
});

const jumpEnabled = ref({});

const emit = defineEmits(["update:options", "logic:update"]);

let optionsArray = props.options || [{ label: "", calculatedValue: "" }];

if (Array.isArray(props.options) && typeof props.options[0] === "string") {
  optionsArray = props.options.map((option) => ({ label: option, calculatedValue: "" }));
}

// eslint-disable-next-line vue/no-dupe-keys
const options = ref(optionsArray);

const indexToRemove = ref(null);

const showDeleteModal = ref(false);

const errors = ref<string[]>([]);

const validateOptions = () => {
  errors.value = options.value?.map((option) =>
    option?.label.trim() === "" ? "The option field is required" : ""
  );
};

watch(
  [() => options, () => props.enableCalculations],
  () => {
    validateOptions();
    emit("update:options", options.value);
  },
  { deep: true, immediate: true }
);

const productBuilderClass = computed(() => {
  if (store.quizId) {
    return "flex w-[275px] flex-1 flex-col gap-3 self-stretch";
  }
  return "flex flex-1 space-x-2";
});

const isLastOptionEmpty = computed(() => {
  const lastOption = options.value[options.value.length - 1];
  return lastOption?.label.trim() === "";
});

const addOption = () => {
  options.value.push({ label: "", calculatedValue: "" });
};

const prepareToRemoveOption = (index) => {
  if (options.value.length > 1) {
    if (props.customFieldCreated) {
      indexToRemove.value = index;
      showDeleteModal.value = true;
    } else {
      options.value.splice(index, 1);
    }
  }
};

const removeOption = async () => {
  if (indexToRemove.value !== null) {
    options.value.splice(indexToRemove.value, 1);
    emitter.emit("option:deleted", {
      indexToRemove: indexToRemove,
      current: props.selectedElement.uuid,
      slideIndex: props.slideIndex,
      elementIndex: props.elementIndex,
    });
  }
  showDeleteModal.value = false;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};

const getSelectLogicQuestion = (optionIndex) => {
  if (store.logic[props.selectedElement.uuid]) {
    const jumpToQuestion = store.logic[props.selectedElement.uuid][optionIndex];
    return jumpToQuestion?.jumpTo || "";
  }
  return "";
};

const setJumpToSwitch = () => {
  options.value.forEach((value, index) => {
    if (getSelectLogicQuestion(index)) {
      jumpEnabled.value[index] = true;
    }
  });
};

setJumpToSwitch();

const logicOptionSelection = (value, option, index, current) => {
  emitter.emit("logic:update", { jumpTo: value, option, index, current });
};

const categoryAndScoreChange = (category) => {
  emitter.emit("category:update", {
    ...category,
    elementIndex: props.elementIndex,
    slideIndex: props.slideIndex,
  });
};

const updateJumpTo = (value, index) => {
  jumpEnabled.value[index] = value;
};

const getSelectedCategoryAndScore = (index) => {
  if (props.selectedElement?.scoreByCategory) {
    return props.selectedElement.scoreByCategory[index] || {};
  }
  return {};
};

const changeInOptions = (e) => {
  emitter.emit("category:sort", {
    slideIndex: props.slideIndex,
    elementIndex: props.elementIndex,
    newIndex: e.moved.newIndex,
    oldIndex: e.moved.oldIndex,
  });
  emitter.emit("logic:sort", {
    newIndex: e.moved.newIndex,
    oldIndex: e.moved.oldIndex,
    current: props.selectedElement.uuid,
  });
};
</script>
